import { compose } from 'recompose'
import { withFetchList, withFilter, withRedirect } from 'components/HOCs'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import Fermer from 'modules/fermer/components/fermer'
import { DEFAULT_PICK_PARAMS } from 'utils/isEquals'
import { getListParams } from 'utils/get'
import { connect } from 'react-redux'
import { prop } from 'ramda'
import { contractListAction } from '../../modules/fermer/actions'

const tablePickParams = [...DEFAULT_PICK_PARAMS, 'typeWeight', 'gateway']
export default compose(
  connect((state) => {
    const gatewayList = prop('gatewayList', state)
    return {
      gatewayList
    }
  }),
  withFetchList({
    stateName: STATES.CONTRACT_LIST,
    action: contractListAction,
    withInterval: true,
    pickParams: tablePickParams
  }),
  withFilter({ fields: ['date'] }),
  withRedirect({
    key: 'goRegion',
    redirectUrl: ROUTES.FERMER_REGION_URL,
    withCurrentParams: true,
    mapper: (props, key) => {
      const defaultListParams = getListParams(props, key)
      return {
        ...defaultListParams,
        typeWeight: 'weight_cond'
      }
    }
  })
)(Fermer)
