import { prop, path } from 'ramda'
import React from 'react'
import mapIndexed from 'utils/mapIndexed'
import numberFormat from 'utils/numberFormat'
import {
  Table,
  TableContainer,
  TableHeader,
  TableBody,
  TableRow,
  TableCol,
  TableFooterRow
} from 'components/Table'
import sumBy from '../../../../utils/sumBy'
import PropTypes from 'prop-types'
import dateFormat from '../../../../utils/dateFormat'
import excelCellWidth from 'utils/excelCellWidth'

const FermerProvisionTable = props => {
  const { listData } = props

  const totalWeightGross = sumBy(listData, ['weightGross'])
  const totalWeightTare = sumBy(listData, ['weightTare'])
  const totalWeightNet = sumBy(listData, ['weightNet'])
  const totalWeightCond = sumBy(listData, ['weightCond'])

  const colSizeWidth = excelCellWidth(10)
  return (
    <TableContainer>
      <Table id="withExcel" data-cols-width={colSizeWidth}>
        <TableHeader tableArray={listData}>
          <TableRow tableArray={listData}>
            <TableCol align={'center'} excelTitle={true} rowSpan={2}>№</TableCol>
            <TableCol align={'center'} excelTitle={true} colSpan={2}>юк хати</TableCol>
            <TableCol align={'center'} excelTitle={true} colSpan={3}>физик вазни, кг</TableCol>
            <TableCol align={'center'} excelTitle={true} rowSpan={2}>кондицион вазни, кг</TableCol>
            <TableCol align={'center'} excelTitle={true} colSpan={3}>пахтани қабул қилиш</TableCol>
          </TableRow>
          <TableRow tableArray={listData}>
            <TableCol align={'center'} excelTitle={true}>санаси</TableCol>
            <TableCol align={'center'} excelTitle={true}>рақами</TableCol>

            <TableCol align={'center'} excelTitle={true}>брутто</TableCol>
            <TableCol align={'center'} excelTitle={true}>тара</TableCol>
            <TableCol align={'center'} excelTitle={true}>нетто</TableCol>

            <TableCol align={'center'} excelTitle={true}>қайд №</TableCol>
            <TableCol align={'center'} excelTitle={true}>қабул вақти</TableCol>
            <TableCol align={'center'} excelTitle={true}>қабул маскани</TableCol>
          </TableRow>
        </TableHeader>
        <TableBody>
          {mapIndexed((item, index) => {
            const key = index + 1
            const id = prop('id', item)
            const appTm = prop('appTm', item)
            const invoiceNumber = prop('invoiceNumber', item)
            const weightGross = prop('weightGross', item)
            const weightTare = prop('weightTare', item)
            const weightNet = prop('weightNet', item)
            const weightCond = prop('weightCond', item)
            const weighingTimeGross = prop('weighingTimeGross', item)
            const station = path(['station', 'name'], item)

            return (
              <TableRow key={id} tableArray={listData}>
                <TableCol align={'center'}>{key}</TableCol>
                <TableCol align={'left'}>{dateFormat(appTm)}</TableCol>
                <TableCol align={'center'}>{numberFormat(invoiceNumber)}</TableCol>

                <TableCol align={'center'}>{numberFormat(weightGross)}</TableCol>
                <TableCol align={'center'}>{numberFormat(weightTare)}</TableCol>
                <TableCol align={'center'}>{numberFormat(weightNet)}</TableCol>

                <TableCol align={'center'}>{numberFormat(weightCond)}</TableCol>

                <TableCol align={'center'}>{id}</TableCol>
                <TableCol align={'center'}>{weighingTimeGross}</TableCol>
                <TableCol align={'center'}>{station}</TableCol>
              </TableRow>
            )
          }, listData)}
          <TableFooterRow tableArray={listData}>
            <TableCol align={'left'} excelTitle={true} colSpan={3}>Жами:</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalWeightGross)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalWeightTare)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalWeightNet)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalWeightCond)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>-</TableCol>
            <TableCol align={'center'} excelTitle={true}>-</TableCol>
            <TableCol align={'center'} excelTitle={true}>-</TableCol>

          </TableFooterRow>
        </TableBody>

      </Table>
    </TableContainer>
  )
}

FermerProvisionTable.propTypes = {
  listData: PropTypes.array.isRequired
}

export default FermerProvisionTable
