import { is, flatten } from 'ramda'
import { mediaQueries } from '../constants/mediaQueries'

export default (media, array) => {
  if (media === mediaQueries.desktop) {
    return '40px'
  }
  if (media === mediaQueries.minLaptopL) {
    if (is(Array, array)) {
      const maxElements = flatten(array).length + 3
      if (maxElements > 6) {
        const calc = 83 / maxElements > 4 ? '4' : 84 / maxElements
        return `${calc}vh`
      }
      return '4vh'
    }
    return '4vh'
  }
  if (media === mediaQueries.laptopL) {
    if (is(Array, array)) {
      const maxElements = flatten(array).length + 3
      if (maxElements > 6) {
        const calc = 80.8 / maxElements > 5 ? '5' : 80.8 / maxElements
        return `${calc}vh`
      }
      return '5vh'
    }
    return '5vh'
  }
  if (media === mediaQueries.laptopS) {
    if (is(Array, array)) {
      const maxElements = flatten(array).length + 3
      if (maxElements > 6) {
        const calc = 106.25 / maxElements > 6.7 ? '6.7' : 106.25 / maxElements
        return `${calc}vh`
      }
      return '6.7vh'
    }
    return '6.7vh'
  }
  if (media === '900px') {
    if (is(Array, array)) {
      const maxElements = flatten(array).length + 3
      if (maxElements > 6) {
        const calc = 129.2 / maxElements > 7 ? '7' : 129.2 / maxElements
        return `${calc}vh`
      }
      return '7vh'
    }
    return '7vh'
  }
  if (media === '400px') {
    if (is(Array, array)) {
      const maxElements = flatten(array).length + 3
      if (maxElements > 6) {
        const calc = 139.2 / maxElements > 6 ? '6' : 139.2 / maxElements
        return `${calc}vh`
      }
      return '5.8vh'
    }
    return '5.8vh'
  }
}
