import * as ROUTES from './routes'

export default (isSuperuser) => {
  const defaultMenu = [
    {
      title: 'Ҳудудлар бўйича',
      url: ROUTES.HUDUDLAR_LIST_PATH,
      perms: ['is_region']
    },
    {
      title: 'Корхоналар бўйича',
      url: ROUTES.KORXONA_LIST_PATH,
      perms: ['is_org']
    },
    {
      title: 'Фермерлар бўйича',
      url: ROUTES.FERMER_LIST_PATH,
      perms: ['is_contract']
    }
    // {
    //   title: 'Теримга чиқиш',
    //   url: '/terim'
    // },
    // {
    //   title: 'Кунлик терим',
    //   url: '/kunlik-terim'
    // },
    // {
    //   title: 'Терим пули',
    //   url: '/terim-puli'
    // }
  ]
  const adminMenu = [
    {
      title: 'Мониторинг',
      url: ROUTES.ADMIN_LIST_PATH,
      perms: ['is_monitoring']
    }
    // {
    //   title: 'Дублирование',
    //   url: ROUTES.DOUBLE_LIST_PATH,
    //   perms: ['disabled']
    // },
    // {
    //   title: 'ПК 17',
    //   url: ROUTES.PK_SEVENTEEN_LIST_PATH,
    //   perms: ['disabled']
    // }
  ]
  return isSuperuser ? [...defaultMenu, ...adminMenu] : [...defaultMenu, ...adminMenu]
}
