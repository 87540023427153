export const AUTH = 'auth'
export const CONTRACT_LIST = 'contractList'
export const PROVISION_LIST = 'provisionList'
export const ORGANISATION_LIST = 'organisationList'
export const ADMIN_LIST = 'adminList'
export const ADMIN_LOGS = 'adminLogs'
export const GATEWAY_LIST = 'gatewayList'
export const CHECK_PROVISION_LIST = 'checkProvisionList'
export const CHECK_PHOTO = 'checkPhoto'
export const DOUBLE_WEIGHT_LIST = 'weightList'
export const PK_SEVENTEEN_CREATE = 'PKSeventeenCreate'
export const PK_SEVENTEEN_LIST = 'pKSeventeenList'
