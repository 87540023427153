import React from 'react'
import styled from 'styled-components'
import hexToRgba from 'utils/hexToRgb'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'
import { RightFilters } from '../../../../components/Filter'
import IconInput from '../../../../components/FormFields/Input/IconInput'
import { Search } from 'react-feather'
import Loader from '../../../../components/Loader/Loader'
import Pagination from '../../../../components/Pagination'
import DoublePKTable from './TableTypes/DoublePKTable'
import CheckPhotoTable from './TableTypes/CheckPhotoTable'
import WeightListTable from './TableTypes/WeightListTable'

const BGMusk = styled('div')`
  background-color: ${hexToRgba('#000000', '0.18')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({ isOpen }) => isOpen ? '1' : '0'};
  transition: all .2s ease-out;
  visibility: ${({ isOpen }) => isOpen ? 'visible' : 'hidden'};
  z-index: 90;
  margin: 0 !important;
`

const ModalContent = styled('div')`
  margin: 0 !important;
  background: white;
  border-radius: 5px;
  padding: 30px 25px;
  box-shadow: ${({ isOpen }) => isOpen ? '-5px 0 15px rgba(0, 0, 0, 0.05)' : 'none'};
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  right: 0;
  transition: all .2s ease-out;
  transform: translateX(-50%) translateY(${({ isOpen }) => isOpen ? '-50%' : '-150vh'});
  max-width: 800px;
  width: 90%;
  z-index: 1000;
      overflow-Y: auto;
      overflow-X: hidden;
      height: 90%;
`

const Title = styled('span')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  h2{
    color: #273d56;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
  }
`

const DoubleModalLogs = ({ modalIsOpen, setModalIsOpen, list, checkPhoto, weightList, enterSearch, pushHandler, params }) => {
  const type = prop('type', params)
  const url = prop('dUrl', params)
  const listData = path(['data', 'results'], list) || []
  const loading = prop('loading', list) || prop('loading', checkPhoto) || prop('loading', weightList)
  const table = type === 'pk' ? <DoublePKTable list={listData} pushHandler={pushHandler} />
    : type === 'image' ? <CheckPhotoTable data={checkPhoto} url={url} />
      : type === 'weight' ? <WeightListTable data={weightList} url={url} />
        : null

  const countForPagination = prop('data', list) || prop('data', checkPhoto)
  return (
    <>
      <BGMusk isOpen={modalIsOpen} onClick={() => { setModalIsOpen(false) }} />
      <ModalContent isOpen={modalIsOpen}>
        <Title>
          <h2>{type === 'weight' ? 'Перерасчет взвешивания' : type === 'image' ? 'Без фото' : type === 'pk' ? 'ПК 17' : null}</h2>
          <Pagination data={countForPagination} newKeys={{ page: 'dPage', pageSize: 'dPageSize' }} />
          <RightFilters>
            <IconInput icon={Search} id="search" onKeyDown={enterSearch} />
          </RightFilters>
        </Title>
        {loading
          ? <Loader />
          : listData
            ? table
            : <div>Проверьте соединение с интернетом</div>}
      </ModalContent>
    </>
  )
}

DoubleModalLogs.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  setModalIsOpen: PropTypes.func.isRequired,
  enterSearch: PropTypes.func.isRequired,
  pushHandler: PropTypes.func.isRequired,
  params: PropTypes.object,
  list: PropTypes.object,
  checkPhoto: PropTypes.object,
  weightList: PropTypes.object
}

export default DoubleModalLogs
