import React from 'react'
import { DatePicker } from 'components/FormFields'
import Filter, { LeftFilters, RightFilters } from 'components/Filter'
import PropTypes from 'prop-types'
import { Printer } from 'react-feather'
import ButtonPrint from 'components/ButtonPrint'
import ModalDownload from '../../../../components/Modals'

const getDate = date => {
  if (date) return new Date(date)
  return new Date()
}

const TableFilter = (props) => {
  const { onChangeFilter, initialValues, excelParam } = props

  return (
    <Filter>
      <LeftFilters>
        <DatePicker
          label={'Сана'}
          value={getDate(initialValues.date)}
          onChange={value => onChangeFilter({ date: value })}
        />
      </LeftFilters>
      <RightFilters>
        <ModalDownload excelParam={excelParam} />
        <ButtonPrint><Printer size={22} color={'#2E384C'} /></ButtonPrint>
      </RightFilters>
    </Filter>
  )
}

TableFilter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  excelParam: PropTypes.object
}

export default TableFilter
