import { compose } from 'recompose'
import { path } from 'ramda'
import { withFetchList, withFilter, withRedirect } from 'components/HOCs'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import { DEFAULT_PICK_PARAMS } from 'utils/isEquals'
import { getListParams } from 'utils/get'
import FermerTerritory from 'modules/fermer/components/fermer-territory'
import { contractListAction } from '../../modules/fermer/actions'

const tablePickParams = [...DEFAULT_PICK_PARAMS, 'date', 'typeWeight', 'gateway']
export default compose(
  withFetchList({
    stateName: STATES.CONTRACT_LIST,
    action: contractListAction,
    pickParams: tablePickParams,
    withInterval: true,
    mapper: (props, key) => {
      const defaultListParams = getListParams(props, key)
      return {
        ...defaultListParams,
        type: 'contract',
        id: path(['match', 'params', 'id'], props)
      }
    }
  }),
  withFilter({ fields: ['date', 'gateway'] }),
  withRedirect({
    key: 'goProvision',
    redirectUrl: ROUTES.FERMER_PROVISION_URL,
    withCurrentParams: true
  })
)(FermerTerritory)
