
export default {
  backgroundColor: props => props.theme.input.backgroundColor,
  border: props => `1px solid ${props.theme.input.borderColor}`,
  borderRadius: props => props.theme.input.borderRadius,
  color: props => props.theme.palette.textColor,
  fontFamily: 'inherit',
  fontSize: '14px',
  height: '38px',
  padding: '10px 12px',
  outline: 'none',
  width: '220px',
  transition: props => props.theme.transition,
  '&::placeholder': {
    color: props => props.theme.input.placeholderColor
  },
  '&::-ms-input-placeholder': {
    color: props => props.theme.input.placeholderColor
  },
  '&:focus': {
    borderColor: props => props.theme.palette.primaryColor
  }
}
