import { prop } from 'ramda'
import React from 'react'
import {
  Table,
  TableContainer,
  TableHeader,
  TableBody,
  TableRow,
  TableCol
} from 'components/Table'
import PropTypes from 'prop-types'
import mapIndexed from '../../../../utils/mapIndexed'
import styled from 'styled-components'
import hexToRgb from '../../../../utils/hexToRgb'
import toBoolean from '../../../../utils/toBoolean'

const LinkButton = styled('a')`
  text-decoration: none;
  cursor: pointer;
  color: #6d73c0;
  &:hover {
    color: ${hexToRgb('#6d73c0', 0.5)}
  }
`

const DoubleTable = props => {
  const { listData, logsHandler, checkPhotoHandler, checkPKHandler, weightListHandler, params } = props
  const withVPN = toBoolean(prop('VPN', params)) || false
  return (
    <TableContainer>
      <Table>
        <TableHeader>
          <TableRow>
            <TableCol rowSpan={2} align={'center'}>№</TableCol>
            <TableCol rowSpan={2} align={'center'}>Шлюз</TableCol>
            <TableCol rowSpan={2} align={'center'}>Дублирование ПК 17</TableCol>
            <TableCol rowSpan={2} align={'center'}>Перенаправление взвешиваний</TableCol>
            <TableCol rowSpan={2} align={'center'}>Дублирование взвешиваний</TableCol>
            <TableCol rowSpan={2} align={'center'}>Без картинки</TableCol>
          </TableRow>
        </TableHeader>
        <TableBody>
          {mapIndexed(item => {
            const id = prop('id', item)
            const gateway = prop('name', item)
            const url = withVPN ? prop('ipAddress', item) : prop('ipExternal', item)
            const ipFileExternal = prop('ipFileExternal', item)
            const token = prop('token', item)
            const fileToken = prop('token', item)

            return (
              <TableRow key={id}>
                <TableCol align={'center'}>{id}</TableCol>
                <TableCol align={'left'}>{gateway}</TableCol>
                <TableCol align={'center'}><LinkButton onClick={() => checkPKHandler(url, token, 'pk')}>Просмотреть</LinkButton></TableCol>
                <TableCol align={'center'}><LinkButton onClick={() => weightListHandler(url, token, 'weight')}>Просмотреть</LinkButton></TableCol>
                <TableCol align={'center'}><LinkButton onClick={() => logsHandler(url, token, '')}>Просмотреть</LinkButton></TableCol>
                <TableCol align={'center'}><LinkButton onClick={() => checkPhotoHandler(ipFileExternal, fileToken, 'image')}>Просмотреть</LinkButton></TableCol>
              </TableRow>
            )
          }, listData)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

DoubleTable.propTypes = {
  listData: PropTypes.array.isRequired,
  logsHandler: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  checkPKHandler: PropTypes.func.isRequired,
  checkPhotoHandler: PropTypes.func.isRequired,
  weightListHandler: PropTypes.func.isRequired
}

export default DoubleTable
