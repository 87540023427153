import React from 'react'
import styled from 'styled-components'

const TableContainer = styled('div')`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  @media print {
    zoom: 0.5;
  }
`

// eslint-disable-next-line no-unused-vars
const scrollHorizontal = (event, element) => {
  const delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)))
  element.scrollLeft -= (delta * 35)
  event.preventDefault()
}

class TableWrapper extends React.Component {
  constructor (props) {
    super(props)
    this.containerRef = React.createRef()
    this.onScroll = null
  }

  // componentDidMount () {
  //   const tableContainer = this.containerRef.current
  //   this.onScroll = event => scrollHorizontal(event, tableContainer)
  //   if (tableContainer.addEventListener) {
  //     // IE9, Chrome, Safari, Opera
  //     tableContainer.addEventListener('mousewheel', this.onScroll, false)
  //     // Firefox
  //     tableContainer.addEventListener('DOMMouseScroll', this.onScroll, false)
  //   } else {
  //     // IE 6/7/8
  //     tableContainer.attachEvent('onmousewheel', this.onScroll)
  //   }
  // }

  // componentWillUnmount () {
  //   const tableContainer = this.containerRef.current
  //   // Remove all event listeners
  //   if (tableContainer) {
  //     // IE9, Chrome, Safari, Opera
  //     tableContainer.removeEventListener('mousewheel', this.onScroll, false)
  //     // Firefox
  //     tableContainer.removeEventListener('DOMMouseScroll', this.onScroll, false)
  //     // IE 6/7/8
  //     if (tableContainer.detachEvent) {
  //       tableContainer.detachEvent('onmousewheel', this.onScroll)
  //     }
  //   }
  // }

  render () {
    return (
      <TableContainer
        {...this.props}
        ref={this.containerRef}
      />
    )
  }
}

export default TableWrapper
