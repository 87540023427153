import styled from 'styled-components'

export const LeftFilters = styled('div')`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 0 0 10px 0;
  & > *{
    margin: 0 10px 10px 0;
  }
`

export const RightFilters = styled('div')`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0 0 10px 0;
  & > *{
    margin: 0 10px 10px 0;
  }
  & > *:last-child{
    margin-right: 0 !important;
  }
  @media print {
    display: none !important;
  }
`
