import React from 'react'
import TimeField from 'react-simple-timefield'
import InputLabel from '../InputLabel/InputLabel'
import styled from 'styled-components'
import inputBaseStyle from './inputBaseStyle'
import { Clock } from 'react-feather'
import PropTypes from 'prop-types'

const Wrapper = styled('div')`
  z-index: 2;
`

const InputContainer = styled('div')`
  position: relative;
`

const StyledIcon = styled('span')`
  svg{
    color: #bec4d6;
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 1;
  }
`

const StyledTimePicker = styled(TimeField)({
  ...inputBaseStyle,
  padding: '10px 12px 10px 38px',
  width: '160px !important',
  boxShadow: 'none'
})

const TimePicker = ({ label, ...rest }) => {
  return (
    <Wrapper>
      <InputLabel>{label}</InputLabel>
      <InputContainer>
        <StyledIcon>
          <Clock size={18} />
        </StyledIcon>
        <StyledTimePicker
          showSeconds={true}
          {...rest}
        />
      </InputContainer>
    </Wrapper>
  )
}

TimePicker.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func
}

export default TimePicker
