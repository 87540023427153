import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, omit } from 'ramda'
import { connect } from 'react-redux'
import checkPermissions from 'utils/checkPermissions'
import { storageData } from '../../utils/storage'

const enhance = connect()

const Perms = props => {
  const {
    me,
    perms,
    children,
    withAlert,
    onClick,
    log,
    ...defaultProps
  } = props
  const permissions = storageData('permissions').getValue() || []
  const isAdmin = storageData('isSuperuser').getValue()
  const hasPerms = checkPermissions(perms, permissions)
  const onClickActive = (!hasPerms && !isAdmin) ? () => {} : onClick
  const filteredProps = omit(['dispatch'], defaultProps)
  const finalProps = { ...filteredProps, onClick: onClickActive }
  if (isEmpty(permissions)) {
    return null
  }
  if (!hasPerms && !isAdmin && withAlert) {
    return React.cloneElement(children, finalProps)
  } else if (!hasPerms && !isAdmin) {
    return null
  } else {
    return React.cloneElement(children, finalProps)
  }
}

Perms.propTypes = {
  perms: PropTypes.array
}
Perms.defaultProps = {
  perms: [],
  withAlert: false
}

export default enhance(Perms)
