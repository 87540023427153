import { prop, path } from 'ramda'
import React from 'react'
import numberFormat from 'utils/numberFormat'
import {
  Table,
  TableContainer,
  TableHeader,
  TableBody,
  TableRow,
  TableCol,
  TableFooterRow
} from 'components/Table'
import PropTypes from 'prop-types'
import sumBy from '../../../../utils/sumBy'
import mapIndexed from '../../../../utils/mapIndexed'
import dateFromUnix from 'utils/dateFromUnix'
import excelCellWidth from '../../../../utils/excelCellWidth'

const KorxonaInnTable = props => {
  const { listData } = props
  const totalWeightGross = sumBy(listData, ['weightGross'])
  const totalWeightTare = sumBy(listData, ['weightTare'])
  const totalWeightNet = sumBy(listData, ['weightNet'])

  const totalWeediness = sumBy(listData, ['weediness']) || null
  const totalHumidity = sumBy(listData, ['humidity']) || null
  const totalWeightCond = sumBy(listData, ['weightCond'])

  const totalDiscount = sumBy(listData, ['discount']) || null
  const totalCape = sumBy(listData, ['cape'])

  const colSizeWidth = excelCellWidth(15)
  return (
    <TableContainer>
      <Table id="withExcel" data-cols-width={colSizeWidth}>
        <TableHeader tableArray={listData}>
          <TableRow tableArray={listData}>
            <TableCol align={'center'} excelTitle={true} rowSpan={2}>Қайд рақами </TableCol>
            <TableCol align={'center'} excelTitle={true} colSpan={1}>топширувчи</TableCol>
            <TableCol align={'center'} excelTitle={true} rowSpan={2}>юк хати</TableCol>
            <TableCol align={'center'} excelTitle={true} colSpan={3}>физик вазни, кг</TableCol>
            <TableCol align={'center'} excelTitle={true} colSpan={2}>лабор-я хулосаси</TableCol>
            <TableCol align={'center'} excelTitle={true} rowSpan={2}>кондицион вазни, кг</TableCol>
            <TableCol align={'center'} excelTitle={true} rowSpan={2}>скидка, кг</TableCol>
            <TableCol align={'center'} excelTitle={true} rowSpan={2}>накидка, кг</TableCol>
            <TableCol align={'center'} excelTitle={true} rowSpan={2}>ПК-17 рақами</TableCol>
            <TableCol align={'center'} excelTitle={true} colSpan={2}>қайд этилган вақт</TableCol>
            <TableCol align={'center'} excelTitle={true} colSpan={1}>серверга юборилган</TableCol>
          </TableRow>
          <TableRow tableArray={listData}>
            <TableCol align={'center'} excelTitle={true}>номланиши</TableCol>

            <TableCol align={'center'} excelTitle={true}>брутто</TableCol>
            <TableCol align={'center'} excelTitle={true}>тара</TableCol>
            <TableCol align={'center'} excelTitle={true}>нетто</TableCol>

            <TableCol align={'center'} excelTitle={true}>ифлос.</TableCol>
            <TableCol align={'center'} excelTitle={true}>намлик</TableCol>

            <TableCol align={'center'} excelTitle={true}>кириш</TableCol>
            <TableCol align={'center'} excelTitle={true}>чиқиш</TableCol>

            <TableCol align={'center'} excelTitle={true}>1С га</TableCol>
          </TableRow>
        </TableHeader>
        <TableBody>
          {mapIndexed((item, index) => {
            const id = prop('id', item)

            const territory = path(['territory', 'name'], item)
            const invoiceNumber = prop('invoiceNumber', item)

            const weightGross = prop('weightGross', item)
            const weightTare = prop('weightTare', item)
            const weightNet = prop('weightNet', item)

            const weediness = prop('weediness', item)
            const humidity = prop('humidity', item)

            const weightCond = prop('weightCond', item)

            const discount = prop('discount', item)

            const cape = prop('cape', item)

            const pk17Number = prop('pk17Number', item)

            const weighingTimeGross = prop('gatewayTm', item)
            const weighingTimeTare = prop('gatewayTm', item)

            const gatewayTm = prop('gatewayTm', item) || null
            return (
              <TableRow key={id} tableArray={listData}>
                <TableCol align={'center'}>{id}</TableCol>

                <TableCol align={'center'}>{territory}</TableCol>

                <TableCol align={'center'}>{invoiceNumber}</TableCol>

                <TableCol align={'center'}>{numberFormat(weightGross)}</TableCol>
                <TableCol align={'center'}>{numberFormat(weightTare)}</TableCol>
                <TableCol align={'center'}>{numberFormat(weightNet)}</TableCol>

                <TableCol align={'center'}>{numberFormat(weediness)}</TableCol>
                <TableCol align={'center'}>{numberFormat(humidity)}</TableCol>

                <TableCol align={'center'}>{numberFormat(weightCond)}</TableCol>

                <TableCol align={'center'}>{discount}</TableCol>

                <TableCol align={'center'}>{cape}</TableCol>

                <TableCol align={'center'}>{pk17Number}</TableCol>

                <TableCol align={'center'} whiteSpace={'nowrap'}>{dateFromUnix(weighingTimeGross)}</TableCol>
                <TableCol align={'center'} whiteSpace={'nowrap'}>{dateFromUnix(weighingTimeTare)}</TableCol>

                <TableCol align={'center'} whiteSpace={'nowrap'}>{dateFromUnix(gatewayTm)}</TableCol>
              </TableRow>
            )
          }, listData)}
          <TableFooterRow tableArray={listData}>
            <TableCol align={'left'} excelTitle={true} colSpan={3}>Жами:</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalWeightGross)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalWeightTare)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalWeightNet)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalWeediness)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalHumidity)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalWeightCond)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalDiscount)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalCape)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>-</TableCol>
            <TableCol align={'center'} excelTitle={true}>-</TableCol>

            <TableCol align={'center'} excelTitle={true}>-</TableCol>
            <TableCol align={'center'} excelTitle={true}>-</TableCol>
          </TableFooterRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

KorxonaInnTable.propTypes = {
  listData: PropTypes.array.isRequired
}

export default KorxonaInnTable
