import { compose, mapPropsStream, withHandlers, withState } from 'recompose'
import { withFetchList, withFilter, withRedirect } from 'components/HOCs'
import { distinctUntilChanged } from 'rxjs/operators'
import { path, prop } from 'ramda'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import Admin from 'modules/admin/components/admin'
import { DEFAULT_PICK_PARAMS, isEqualSearch } from 'utils/isEquals'
import { activeListAction, activeLogsAction } from '../../modules/admin/actions'
import { appendParamsToUrl, getPathnameFromUrl, parseParams } from '../../utils/url'
import { connect } from 'react-redux'

const tablePickParams = [...DEFAULT_PICK_PARAMS, 'gateway_id', 'version', 'status', 'check_photo', 'time']
const pickParams = ['dSearch', 'dPage']
export default compose(
  connect((state, props) => {
    const listLogs = prop(['adminLogs'], state)
    const params = parseParams(props.location.search)
    return {
      listLogs,
      params
    }
  }),
  withFetchList({
    stateName: STATES.ADMIN_LIST,
    action: activeListAction,
    pickParams: tablePickParams
  }),
  withFilter({ fields: ['gateway_id', 'date', 'version', 'status', 'time', 'check_photo'] }),
  withRedirect({
    key: 'goRegion',
    redirectUrl: ROUTES.FERMER_REGION_URL,
    withCurrentParams: true
  }),
  withState('modalIsOpen', 'setModalIsOpen', false),
  withState('setParams', 'setParamsHandler', {}),
  mapPropsStream(props$ => {
    props$
      .pipe(
        distinctUntilChanged(
          isEqualSearch(pickParams),
          path(['history', 'location', 'search'])
        )
      )
      .subscribe(props => {
        const { setParams, modalIsOpen, params } = props
        const newToken = prop('token', params)
        const newUrl = prop('dUrl', params)
        modalIsOpen && props.dispatch(activeLogsAction(newUrl, setParams, newToken, params))
      })
    return props$
  }),
  withHandlers({
    enterSearch: props => (event) => {
      const { history } = props
      if (event.key === 'Enter') {
        history.push({ search: appendParamsToUrl({ search: event.target.value }, path(['history', 'location', 'search'], props)) })
      }
    },
    enterSearchModal: props => (event) => {
      const { history } = props
      if (event.key === 'Enter') {
        history.push({ search: appendParamsToUrl({ dSearch: event.target.value }, path(['history', 'location', 'search'], props)) })
      }
    },
    enterTime: props => (event) => {
      const { history } = props
      if (event.key === 'Enter') {
        history.push({ search: appendParamsToUrl({ time: event.target.value }, path(['history', 'location', 'search'], props)) })
      }
    },
    logsHandler: props => (url, token) => {
      const { setModalIsOpen, setParamsHandler } = props
      const newUrl = getPathnameFromUrl(url)
      const newParams = parseParams(url)
      setModalIsOpen(true)
      setParamsHandler(newParams)
      props.history.push({ search: appendParamsToUrl({ dUrl: newUrl, token: token, dPage: 1 }, path(['history', 'location', 'search'], props)) })
      return props.dispatch(activeLogsAction(newUrl, newParams, token))
    }
  })
)(Admin)
