import React from 'react'
import styled from 'styled-components'
import { prop } from 'ramda'
import Box from 'components/Box'
import Title from 'components/Title'
import { DisplayFlex } from 'components/Styled'
import TableFilter from './TableFilter'
import KorxonaClusterTable from './KorxonaClusterTable'
import PropTypes from 'prop-types'
import Loader from 'components/Loader/Loader'
import { mediaQueries } from 'constants/mediaQueries'
import { Breadcrumb, BreadcrumbItem } from '../../../../components/Breadcrumb'
import * as ROUTES from '../../../../constants/routes'
import { storageData } from '../../../../utils/storage'
import { NoPrint, Print } from 'react-easy-print'
import { parseParams } from 'utils/url'
import format from 'date-fns/format'

const Header = styled(DisplayFlex)`
  align-items: center;
  justify-content: space-between;
  @media ${mediaQueries.laptopS}{
    display: flex;
    margin-left: 0px;
    flex-flow: column nowrap;
    margin-bottom: 0px;
    align-items: flex-start;
    > div:not(:last-child){
      margin-bottom: 4px;
    }
  }
`

const StyledTitle = styled(Title)`
  margin-bottom: 5px;
  margin-right: 25px;
`

const KorxonaCluster = props => {
  const { list, filterActions, goOrg, gatewayList, history } = props

  const params = parseParams(props.location.search)
  const datePrint = prop('date', params) ? format(new Date(prop('date', params)), 'MM.dd.yyyy') : format(new Date(), 'MM.dd.yyyy')

  const listData = prop('results', list)
  const listLoading = prop('loading', list)

  const cluster = storageData('cluster').getValue()
  const clusterName = prop('name', cluster)

  return (
    <div>
      <NoPrint>
        <Header>
          <StyledTitle>{clusterName} бўйича {window.renderDate} йил пахта ҳосилини қабул қилиш мониторинги</StyledTitle>
        </Header>
      </NoPrint>
      <Print
        printOnly={true}
      >
        <Header>
          <StyledTitle print={true}>{clusterName} бўйича {datePrint} кунига пахта ҳосилини қабул қилиш мониторинги</StyledTitle>
        </Header>
      </Print>

      <Box>
        {listLoading
          ? <Loader />
          : listData
            ? <KorxonaClusterTable listData={listData} goOrg={goOrg} />
            : <div>Проверьте соединение с интернетом</div>}
        <NoPrint>
          <Breadcrumb>
            <BreadcrumbItem to={ROUTES.KORXONA_LIST_PATH}>Корхоналар</BreadcrumbItem>
            <BreadcrumbItem>{clusterName}</BreadcrumbItem>
          </Breadcrumb>
        </NoPrint>
        <TableFilter {...filterActions} gatewayList={gatewayList} history={history} />
      </Box>
    </div>
  )
}

KorxonaCluster.propTypes = {
  list: PropTypes.object.isRequired,
  filterActions: PropTypes.object.isRequired,
  gatewayList: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  goOrg: PropTypes.func.isRequired
}

export default KorxonaCluster
