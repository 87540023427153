import { compose, mapPropsStream, withHandlers, withState } from 'recompose'
import { withFetchList, withFilter, withRedirect } from 'components/HOCs'
import { distinctUntilChanged } from 'rxjs/operators'
import { path, prop } from 'ramda'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import Double from 'modules/double/components/double'
import { DEFAULT_PICK_PARAMS, isEqualSearch } from 'utils/isEquals'
import { appendParamsToUrl, parseParams } from '../../utils/url'
import { connect } from 'react-redux'
import { getawayListAction } from '../../modules/home/actions'
import { activeLogsAction, checkPhotoAction, doubleWeightListAction } from '../../modules/double/actions'

const tablePickParams = [...DEFAULT_PICK_PARAMS]
const pickParams = ['dSearch', 'dPage']
export default compose(
  connect((state, props) => {
    const listLogs = prop(['doubleLogs'], state)
    const checkPhoto = prop(['checkPhoto'], state)
    const weightList = prop(['weightList'], state)
    const params = parseParams(props.location.search)
    return {
      listLogs,
      params,
      checkPhoto,
      weightList
    }
  }),
  withFetchList({
    stateName: STATES.GATEWAY_LIST,
    action: getawayListAction,
    pickParams: tablePickParams
  }),
  withFilter({ fields: ['VPN'] }),
  withRedirect({
    key: 'goRegion',
    redirectUrl: ROUTES.FERMER_REGION_URL,
    withCurrentParams: true
  }),
  withState('modalIsOpen', 'setModalIsOpen', false),
  mapPropsStream(props$ => {
    props$
      .pipe(
        distinctUntilChanged(
          isEqualSearch(pickParams),
          path(['history', 'location', 'search'])
        )
      )
      .subscribe(props => {
        const { modalIsOpen, params } = props
        const token = prop('token', params)
        const url = prop('dUrl', params)
        const type = prop('type', params)
        modalIsOpen && type === 'pk' && props.dispatch(activeLogsAction(url, token, params))
        modalIsOpen && type === 'image' && props.dispatch(checkPhotoAction(url, token, params))
        modalIsOpen && type === 'weight' && props.dispatch(doubleWeightListAction(url, token, params))
      })
    return props$
  }),
  withHandlers({
    enterSearch: props => (event) => {
      const { history } = props
      if (event.key === 'Enter') {
        history.push({ search: appendParamsToUrl({ search: event.target.value }, path(['history', 'location', 'search'], props)) })
      }
    },
    enterSearchModal: props => (event) => {
      const { history } = props
      if (event.key === 'Enter') {
        history.push({ search: appendParamsToUrl({ dSearch: event.target.value }, path(['history', 'location', 'search'], props)) })
      }
    },
    logsHandler: props => (url, token, type) => {
      const { setModalIsOpen } = props
      setModalIsOpen(true)
      props.history.push({ search: appendParamsToUrl({ dUrl: url, token: token, dPage: 1, type }, path(['history', 'location', 'search'], props)) })
      return props.dispatch(activeLogsAction(url, token))
    },
    checkPKHandler: props => (url, token, type) => {
      const { setModalIsOpen } = props
      setModalIsOpen(true)
      props.history.push({ search: appendParamsToUrl({ dUrl: url, token: token, dPage: 1, type }, path(['history', 'location', 'search'], props)) })
      return props.dispatch(activeLogsAction(url, token))
    },
    weightListHandler: props => (url, token, type) => {
      const { setModalIsOpen } = props
      setModalIsOpen(true)
      props.history.push({ search: appendParamsToUrl({ dUrl: url, token: token, dPage: 1, type }, path(['history', 'location', 'search'], props)) })
      return props.dispatch(doubleWeightListAction(url, token))
    },
    pushHandler: props => (url, token) => {
      const { setModalIsOpen } = props
      // const newUrl = getPathnameFromUrl(url)
      // const newParams = parseParams(url)
      setModalIsOpen(true)
      // setParamsHandler(newParams)
      // props.history.push({ search: appendParamsToUrl({ dUrl: newUrl, token: token, dPage: 1 }, path(['history', 'location', 'search'], props)) })
      return props.dispatch(activeLogsAction(token))
    },
    // CheckPhoto
    checkPhotoHandler: props => (url, token, type) => {
      const { setModalIsOpen } = props
      setModalIsOpen(true)
      props.history.push({ search: appendParamsToUrl({ dUrl: url, token: token, dPage: 1, type }, path(['history', 'location', 'search'], props)) })
      return props.dispatch(checkPhotoAction(url, token))
    }
  })
)(Double)
