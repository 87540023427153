import { showSnack } from 'react-redux-snackbar'

const randomId = Math.random()
const snackbar = ({ label, timeout }) => {
  return (dispatch) => {
    dispatch(showSnack(`${randomId}`, {
      label: `${label}`,
      timeout: timeout || 3000,
      button: timeout && { label: 'Закрыть' }
    }))
  }
}

export default snackbar
