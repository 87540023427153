import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import * as ROUTES from 'constants/routes'
import { storageData } from 'utils/storage'
import Box from 'components/Box'
import Title from 'components/Title'
import { Breadcrumb, BreadcrumbItem } from 'components/Breadcrumb'
import HududlarRegionTable from './HududlarRegionTable'
import TableFilter from 'modules/home/components/hududlar-region/TableFilter'
import Loader from 'components/Loader/Loader'
import styled from 'styled-components'
import { DisplayFlex } from 'components/Styled'
import { mediaQueries } from 'constants/mediaQueries'
import Filter from 'components/Filter'
import GraphFilter from 'modules/home/components/hududlar-region/GraphFilter'
import HududlarRegionGraph from 'modules/home/components/hududlar-region/HududlarRegionGraph'
import { NoPrint, Print } from 'react-easy-print'
import { parseParams } from '../../../../utils/url'
import format from 'date-fns/format'

const Header = styled(DisplayFlex)`
  align-items: center;
  justify-content: space-between;
  @media ${mediaQueries.laptopS}{
    display: flex;
    margin-left: 0px;
    flex-flow: column nowrap;
    margin-bottom: 0px;
    align-items: flex-start;
    > div:not(:last-child){
      margin-bottom: 4px;
    }
  }
`

const StyledTitle = styled(Title)`
  margin-bottom: 5px;
  margin-right: 25px;
`

const initialSwitcher = 'table'

const Home = props => {
  const { list, filterActions, goDistrict, setDailyStats, dailyStats, history, gatewayList } = props

  const params = parseParams(props.location.search)
  const datePrint = prop('date', params) ? format(new Date(prop('date', params)), 'MM.dd.yyyy') : format(new Date(), 'MM.dd.yyyy')
  const region = storageData('region').getValue()
  const regionName = prop('name', region)

  const listData = prop('results', list)
  const listLoading = prop('loading', list)

  const [switcher, setSwitcher] = React.useState(initialSwitcher)

  return (
    <div>
      <NoPrint>
        <Header>
          <StyledTitle>{regionName} бўйича {window.renderDate} йил пахта ҳосилини қабул қилиш мониторинги</StyledTitle>
        </Header>
      </NoPrint>
      <Print
        printOnly={true}
      >
        <Header>
          <Title print={true}>{regionName} бўйича {datePrint} кунига пахта ҳосилини қабул қилиш мониторинги</Title>
        </Header>
      </Print>
      {switcher === 'table' &&
        <Box>
          {listLoading
            ? <Loader />
            : listData
              ? <HududlarRegionTable listData={listData} goDistrict={goDistrict} />
              : <div>Проверьте соединение с интернетом</div>}
          <NoPrint>
            <Breadcrumb>
              <BreadcrumbItem to={ROUTES.HUDUDLAR_LIST_PATH}>Худудлар</BreadcrumbItem>
              <BreadcrumbItem>{regionName}</BreadcrumbItem>
            </Breadcrumb>
          </NoPrint>
          <TableFilter
            excelParam={{
              fileName: `Ҳудудлар бўйича_region_${datePrint}`,
              tableId: 'withExcel',
              excelTableName: `${regionName} бўйича ${datePrint} кунига пахта ҳосилини қабул қилиш мониторинги`
            }}
            history={history}
            {...filterActions}
            gatewayList={gatewayList}
            initialSwitcher={initialSwitcher}
            setSwitcher={setSwitcher}
          />
        </Box>}

      {switcher === 'graph' &&
      <Box>
        {listLoading
          ? <Loader />
          : listData
            ? (
              <HududlarRegionGraph
                listData={listData}
                dailyStats={dailyStats}
              />
            )
            : <div>Проверьте соединение с интернетом</div>}
        <Breadcrumb>
          <BreadcrumbItem to={ROUTES.HUDUDLAR_LIST_PATH}>Худудлар</BreadcrumbItem>
          <BreadcrumbItem>{regionName}</BreadcrumbItem>
        </Breadcrumb>
        <Filter>
          <GraphFilter
            {...filterActions}
            dailyStats={dailyStats}
            setDailyStats={setDailyStats}
            switcher={switcher}
            setSwitcher={setSwitcher}
          />
        </Filter>
      </Box>}
    </div>
  )
}

Home.propTypes = {
  list: PropTypes.object.isRequired,
  gatewayList: PropTypes.object.isRequired,
  filterActions: PropTypes.object.isRequired,
  location: PropTypes.object,
  goDistrict: PropTypes.func.isRequired,
  setDailyStats: PropTypes.func.isRequired,
  dailyStats: PropTypes.bool.isRequired,
  history: PropTypes.object
}

export default Home
