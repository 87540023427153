import { prop, isEmpty, map, uniqBy } from 'ramda'
import React from 'react'
import {
  Table,
  TableContainer,
  TableHeader,
  TableBody,
  TableRow,
  TableCol,
  TableFooterRow
} from 'components/Table'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import numberFormat from '../../../../utils/numberFormat'
import sumBy from '../../../../utils/sumBy'
import CheckBoxArray from '../../../../components/FormFields/Checkbox/CheckBoxArray/CheckBoxArray'
import format from 'date-fns/format'

const StyledSection = styled(({ title, ...props }) => {
  return (
    <TableFooterRow {...props}>
      <TableCol colSpan={17}>
        {title}
      </TableCol>
    </TableFooterRow>
  )
})`
  text-align: center;
`
const ColorCol = styled(TableCol)`
  background: ${props => props.background ? null : '#ff5232'};
`

const PKSeventeenTable = props => {
  const { listData, arrayField, changeArrayField, allSelect, setAllSelect } = props

  const arrGroup = uniqBy(prop('id'), listData)
  const options = map((item) => {
    return {
      value: false,
      label: 'tableContent',
      ...item
    }
  }, arrGroup)

  const tableContent = (option) => {
    const barcode = prop('barcode', option)
    const checkTimeToString = prop('checkTimeToString', option)
    const weightGross = prop('weightGross', option)
    const weightTare = prop('weightTare', option)
    const weightNet = prop('weightNet', option)
    const weightCond = prop('weightCond', option)
    const weediness = prop('weediness', option)
    const agGiven = prop('agGiven', option)
    const humidity = prop('humidity', option)
    const pk17Number = prop('pk17Number', option)
    const weighingTimeGross = prop('weighingTimeGross', option)
    const weighingTimeTare = prop('weighingTimeTare', option)
    const gatewayTm = prop('gatewayTm', option)

    return (
      <React.Fragment>
        <TableCol align={'center'}>{barcode}</TableCol>
        <ColorCol align={'center'} background={agGiven}>{agGiven ? 'Ha' : 'Yo`q'}</ColorCol>
        <TableCol align={'center'}>{format(new Date(checkTimeToString), 'yyyy-MM-dd, HH:mm:ss') || null}</TableCol>

        <TableCol align={'center'}>{numberFormat(weightGross)}</TableCol>
        <TableCol align={'center'}>{numberFormat(weightTare)}</TableCol>
        <TableCol align={'center'}>{numberFormat(weightNet)}</TableCol>

        <TableCol align={'center'}>{numberFormat(weightCond)}</TableCol>
        <TableCol align={'center'}>{numberFormat(weediness)}</TableCol>
        <TableCol align={'center'}>{numberFormat(humidity)}</TableCol>

        <TableCol align={'center'}>{pk17Number}</TableCol>

        <TableCol align={'center'}>{weighingTimeGross}</TableCol>
        <TableCol align={'center'}>{weighingTimeTare}</TableCol>

        <TableCol align={'center'}>{gatewayTm}</TableCol>
      </React.Fragment>
    )
  }

  // Summation
  const weightGrossTotal = sumBy(listData, ['weightGross'])
  const weightTareTotal = sumBy(listData, ['weightTare'])
  const weightNetTotal = sumBy(listData, ['weightNet'])
  const weightCondTotal = sumBy(listData, ['weightCond'])

  return (
    <TableContainer>
      <Table>
        <TableHeader>
          <TableRow>
            <TableCol rowSpan={2} align={'center'}>Выбрать</TableCol>
            <TableCol colSpan={3} align={'center'}>Юк хати</TableCol>
            <TableCol colSpan={3} align={'center'}>Физик вазни, кг</TableCol>
            <TableCol rowSpan={2} align={'center'}>Кондицион вазни</TableCol>
            <TableCol colSpan={2} align={'center'}>Лабор-я хулосаси</TableCol>
            <TableCol rowSpan={2} align={'center'}>ПК-17 рақами</TableCol>
            <TableCol colSpan={2} align={'center'}>Кайд этилган вақт</TableCol>
            <TableCol colSpan={1} align={'center'}>Серверга юборилган</TableCol>
          </TableRow>
          <TableRow>
            <TableCol align={'center'}>штрих код</TableCol>
            <TableCol align={'center'}>пахта уз</TableCol>
            <TableCol align={'center'}>Время получения</TableCol>

            <TableCol align={'center'}>брутто</TableCol>
            <TableCol align={'center'}>тара</TableCol>
            <TableCol align={'center'}>нетто</TableCol>

            <TableCol align={'center'}>ифлос</TableCol>
            <TableCol align={'center'}>намлик</TableCol>

            <TableCol align={'center'}>кириш</TableCol>
            <TableCol align={'center'}>чиқиш</TableCol>

            <TableCol align={'center'}>1С га</TableCol>
          </TableRow>
        </TableHeader>
        <TableBody>
          {isEmpty(tableContent)
            ? <StyledSection title={'Бу фермер хали пунктга пахта топширмаган'} />
            : (
              <CheckBoxArray
                options={options}
                renderItem={tableContent}
                arrayField={arrayField}
                changeArrayField={changeArrayField}
                allSelect={allSelect}
                setAllSelect={setAllSelect}
              />
            )}
          {!isEmpty(tableContent) &&
          <TableFooterRow>
            <TableCol align={'center'} colSpan={1}>Жами:</TableCol>
            <TableCol align={'center'}> </TableCol>
            <TableCol align={'center'}> </TableCol>

            <TableCol align={'center'}> </TableCol>
            <TableCol align={'center'}>{numberFormat(weightGrossTotal)}</TableCol>
            <TableCol align={'center'}>{numberFormat(weightTareTotal)}</TableCol>

            <TableCol align={'center'}>{numberFormat(weightNetTotal)}</TableCol>
            <TableCol align={'center'}>{numberFormat(weightCondTotal)}</TableCol>

            <TableCol align={'center'}> </TableCol>
            <TableCol align={'center'}> </TableCol>

            <TableCol align={'center'}> </TableCol>

            <TableCol align={'center'}> </TableCol>
            <TableCol align={'center'}> </TableCol>
            <TableCol align={'center'}> </TableCol>
          </TableFooterRow>}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

PKSeventeenTable.propTypes = {
  listData: PropTypes.array.isRequired,
  arrayField: PropTypes.array.isRequired,
  allSelect: PropTypes.any,
  changeArrayField: PropTypes.func.isRequired,
  setAllSelect: PropTypes.func.isRequired
}

export default PKSeventeenTable
