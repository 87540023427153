import React from 'react'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'
import { storageData } from 'utils/storage'
import * as ROUTES from 'constants/routes'
import RouteWithSubRoutes from './RouteWithSubRoutes'
import NormalizeCSS from './NormalizeCSS'
import GlobalStyles from './GlobalStyles'
import getRoutes from '../getRoutes'
import store from '../store'
import { Snackbar } from 'react-redux-snackbar'
import moment from "moment";

const theme = {
  palette: {
    backgroundColor: '#FAFBFD',
    primaryColor: '#594CA6',
    textColor: '#2E384C',
    redColor: '#EC6C48',
    purpleColor: '#6D73C0'
  },
  table: {
    backgroundColor: 'rgba(234, 236, 240, 0.72)',
    border: '1px solid #DDE1E9'
  },
  input: {
    backgroundColor: '#E5E8EB',
    borderColor: '#E5E8EB',
    borderRadius: '5px',
    labelColor: '#8290A0',
    placeholderColor: '#898F97'
  },

  boxShadow: '0px 10px 15px rgba(211, 216, 224, 0.5)',
  transition: 'all 300ms ease-out'
}

const App = () => {
  const isSuperuser = storageData('isSuperuser').getValue()
  const routes = getRoutes(isSuperuser)
  const renderDate = moment().add(-6, 'months').format('YYYY')

  window.renderDate = renderDate

  return (
    <Provider store={store()}>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <NormalizeCSS />
          <GlobalStyles />
          <Router>
            <Switch>
              <Redirect
                exact={true}
                from={ROUTES.ROOT_PATH}
                to={ROUTES.HUDUDLAR_LIST_PATH}
              />
              {routes.map((route, key) => (
                <RouteWithSubRoutes key={key} {...route} />
              ))}
            </Switch>
          </Router>
        </React.Fragment>
      </ThemeProvider>
      <Snackbar />
    </Provider>
  )
}

export default App
