import format from 'date-fns/format'
import axios, { getPayloadFromError, getPayloadFromSuccess } from 'utils/axios'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import { prop } from 'ramda'

export const provisionListAction = params => {
  const date = prop('date', params) || format(new Date(), 'yyyy-MM-dd')
  const typeWeight = prop('type_weight', params) || 'weight_net'
  const defaultParams = { ...params, date, type_weight: typeWeight }
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API.PROVISION_LIST, { params: defaultParams })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.PROVISION_LIST,
      payload
    })
  }
}

export const territoryProvisionListAction = params => {
  const date = prop('date', params) || format(new Date(), 'yyyy-MM-dd')
  const defaultParams = { date, ...params }
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API.TERRITORY_PROVISION_LIST, { params: defaultParams })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.PROVISION_LIST,
      payload
    })
  }
}

export const getawayListAction = () => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API.GATEWAY_LIST, { params: { page_size: '1000' } })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.GATEWAY_LIST,
      payload
    })
  }
}
