import createReducer from './createReducer'
import toCamelCase from './toCamelCase'

const defaultState = {
  data: null,
  error: null,
  loading: false,
  failed: false
}

export const actions = (actionName) => ({
  [`${actionName}_PENDING`] (state) {
    return {
      ...state,
      loading: true
    }
  },
  [`${actionName}_FULFILLED`] (state, action) {
    return {
      ...state,
      data: toCamelCase(action.payload),
      error: null,
      loading: false,
      failed: false
    }
  },
  [`${actionName}_REJECTED`] (state, action) {
    return {
      ...state,
      data: null,
      error: toCamelCase(action.payload),
      loading: false,
      failed: true
    }
  },
  [`${actionName}_CLEAR`] () {
    return defaultState
  }
})

const createThunkReducer = (actionName) => {
  return createReducer(defaultState, actions(actionName))
}

export default createThunkReducer
