import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

const RouteWithSubRoutes = route => {
  return (
    <>
      <Route
        exact={true}
        path={route.path}
        render={props => {
          return (
            <route.layout title={route.title}>
              <route.component {...props} />
            </route.layout>
          )
        }}
      />
      {route.routes.map((route, index) => {
        return (
          <RouteWithSubRoutes
            key={index}
            {...route}
          />
        )
      })}
    </>
  )
}

RouteWithSubRoutes.propTypes = {
  path: PropTypes.string.isRequired,
  layout: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  component: PropTypes.any.isRequired,
  routes: PropTypes.array
}

RouteWithSubRoutes.defaultProps = {
  routes: []
}

export default RouteWithSubRoutes
