import React from 'react'
import { DatePicker, Toggle } from 'components/FormFields'
import Filter, { LeftFilters } from 'components/Filter'
import PropTypes from 'prop-types'
import { SwitchButton, Switcher } from '../../../../components/Switcher'
import { AlignLeft, Grid } from 'react-feather'

const getDate = date => {
  if (date) return new Date(date)
  return new Date()
}

const GraphFilter = (props) => {
  const { onChangeFilter, initialValues, dailyStats, setDailyStats, switcher, setSwitcher } = props
  return (
    <Filter>
      <LeftFilters>
        {switcher === 'graph' && (
          <Switcher
            initialValue={'graph'}
            onChange={value => setSwitcher(value)}
            justifyContent={'center'}
          >
            <SwitchButton value={'table'}><Grid /></SwitchButton>
            <SwitchButton value={'graph'}><AlignLeft /></SwitchButton>
          </Switcher>
        )}
        <DatePicker
          label={'Сана'}
          value={getDate(initialValues.date)}
          onChange={value => onChangeFilter({ date: value })}
        />
        <Toggle
          checked={dailyStats}
          label={'Кунлик терим'}
          onChange={() => setDailyStats(!dailyStats)}
        />
      </LeftFilters>
    </Filter>
  )
}

GraphFilter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  switcher: PropTypes.string,
  setSwitcher: PropTypes.func,
  dailyStats: PropTypes.bool,
  setDailyStats: PropTypes.func
}

export default GraphFilter
