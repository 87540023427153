import PropTypes from 'prop-types'
import React from 'react'
import ButtonSecondary from '../ButtonSecondary'
import styled from 'styled-components'
import { mediaQueries } from 'constants/mediaQueries'

const Button = styled(ButtonSecondary)`
  @media ${mediaQueries.tabletL}{
    display: none !important;
  }
`

const printOpen = () => {
  return window.print()
}

const ButtonPrint = (props) => {
  return (
    <Button onClick={() => { printOpen() }}>{props.children}</Button>
  )
}

ButtonPrint.propTypes = {
  children: PropTypes.any
}

export default ButtonPrint
