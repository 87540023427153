import React, { useEffect } from 'react'
import { Download } from 'react-feather'
import Button from 'components/Button/Button'
import styled from 'styled-components'
import hexToRgba from 'utils/hexToRgb'
import { prop } from 'ramda'
// import { DatePicker } from 'components/FormFields'
// import RadioButton from 'components/RadioButton'
import { mediaQueries } from '../../constants/mediaQueries'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
// import { parseParams } from '../../utils/url'
import TableToExcel from '@linways/table-to-excel'
import PropTypes from 'prop-types'
import addChildTitleForTableExcel from 'utils/addChildTitleForTableExcel'
// import ExcelBuilder from '../ExcelBuilder/ExcelBuilderGridList'
// import toNumber from '../../utils/toNumber'

const BGMusk = styled('div')`
  background-color: ${hexToRgba('#000000', '0.18')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({ isOpen }) => isOpen ? '1' : '0'};
  transition: all .2s ease-out;
  visibility: ${({ isOpen }) => isOpen ? 'visible' : 'hidden'};
  z-index: 90;
  margin: 0 !important;
`
const ModalContent = styled('div')`
  margin: 0 !important;
  background: white;
  border-radius: 5px;
  padding: 30px 25px;
  box-shadow: ${({ isOpen }) => isOpen ? '-5px 0 15px rgba(0, 0, 0, 0.05)' : 'none'};
  display: ${({ isOpen }) => isOpen ? 'flex' : 'none'};
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  right: 0;
  transition: all .2s ease-out;
  transform: translateX(-50%) translateY(-50%);
  max-width: 375px;
  width: 90%;
  z-index: 1000;
`
const Title = styled('h2')`
    color: #273d56;
    position: relative;
    font-size: 18px;
    text-align: center;
    line-height: 22px;
    margin: 0;
`
const DateWrap = styled('div')`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-end;
  margin-top: 15px;
  margin-bottom: 30px;
  p {
    margin: 0
  }
  input {
    max-width: 151px;
  }
  @media (max-width: 417px){
    input {
      max-width: 220px;
    }
    & > div{
      margin-bottom: 5px;
    }
  }
`
// const MinusIcon = styled(Minus)`
//   @media (max-width: 417px){
//     display: none;
//   }
// `
const DownloadButton = styled(Button)`
  display: flex;
  justify-content: center;
  margin-top: 18px;
`

const OpenButton = styled(Button)`
    @media ${mediaQueries.mobileXL}{
      margin: 10px auto 0px auto
    }
`

const enhance = compose(
  withRouter
)

// const getDate = date => {
//   if (date) return new Date(date)
//   return new Date()
// }

// const listHeader = [
//   {
//     sorting: false,
//     name: 'market',
//     title: 'Магазин',
//     width: '20%',
//     colSpan: 2,
//     pathExcel: ['market', 'name']
//   },
//   {
//     sorting: false,
//     name: 'type',
//     pathExcel: ['market', 'marketType', 'name'],
//     title: 'Тип',
//     width: '14%'
//   },
//   {
//     sorting: true,
//     name: 'createdDate',
//     title: 'Дата создания',
//     width: '10%',
//     excelFormatterPath: (item) => toNumber(prop('createdDate', item)) || null
//   }
// ]

// const excelTitlesSecondary = [
//   [
//     {
//       sorting: false,
//       name: 'market',
//       title: 'Магазин',
//       width: '20%',
//       colSpan: 2,
//       pathExcel: ['market', 'name']
//     },
//     {
//       sorting: false,
//       name: 'type',
//       pathExcel: ['market', 'marketType', 'name'],
//       title: 'Тип',
//       width: '14%'
//     },
//     {
//       sorting: true,
//       name: 'createdDate',
//       title: 'Дата создания',
//       width: '10%',
//       excelFormatterPath: (item) => toNumber(prop('createdDate', item)) || null
//     }
//   ],
//   [
//     {
//       sorting: false,
//       name: 'market',
//       title: 'hhg',
//       width: '20%',
//       colSpan: 2,
//       pathExcel: ['market', 'name']
//     },
//     {
//       sorting: false,
//       name: 'type',
//       pathExcel: ['market', 'marketType', 'name'],
//       title: 'Тип',
//       width: '14%'
//     },
//     {
//       sorting: true,
//       name: 'createdDate',
//       title: 'Дата создания',
//       width: '10%',
//       excelFormatterPath: (item) => toNumber(prop('createdDate', item)) || null
//     }
//   ]
// ]
//
// eslint-disable-next-line react/prop-types
const FixBugRender = ({ excelParam, modalIsOpen }) => {
  const fileName = prop('fileName', excelParam)
  const excelTableName = prop('excelTableName', excelParam)
  const tableId = prop('tableId', excelParam)
  const [newTable, setNewTable] = React.useState(null)
  useEffect(() => {
    const attrs = {
      'data-a-v': 'middle',
      'data-a-h': 'center',
      'data-f-bold': 'true'
    }
    const table = document.getElementById(tableId) ? document.getElementById(tableId).cloneNode(true) : null
    const nethead = table && table.getElementsByTagName('thead')[0]
    nethead && addChildTitleForTableExcel(document, nethead, [
      { attributes: { colspan: 12, 'data-f-sz': 12, ...attrs }, parent: 'tr', childText: excelTableName, tag: 'td' },
      { attributes: { colspan: 12, 'data-f-sz': 14, ...attrs }, parent: 'tr', childText: 'МАЪЛУМОТ', tag: 'td' },
      { attributes: { colspan: 12 }, parent: 'tr', childText: ' ', tag: 'td' }
    ])
    setNewTable(table)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getExcelFile = (() => {
    if (newTable) {
      const exportHtmlTableToExcel = () => {
        TableToExcel.convert(newTable, {
          name: fileName + '.xlsx',
          sheet: {
            name: fileName
          }
        })
      }
      return {
        tableId: tableId,
        handleDownload: exportHtmlTableToExcel
      }
    }
    return {
      tableId: tableId
    }
  })()
  return (
    <ModalContent isOpen={modalIsOpen}>
      <Title>
        Ҳисоботни кўчириб олиш
      </Title>
      <DateWrap>
        <p>{fileName + '.xlsx'}</p>
        {/* <DatePicker */}
        {/*  value={getDate(date)} */}
        {/*  label={'Дан'} */}
        {/*  navButtons={false} */}
        {/* /> */}
        {/* <MinusIcon style={{ height: '38px' }} color={'#bec4d6'} size={18} /> */}
        {/* <DatePicker */}
        {/*  value={getDate(date)} */}
        {/*  label={'Гача'} */}
        {/*  navButtons={false} */}
        {/* /> */}
      </DateWrap>
      {/* <RadioButton name={'name'} label={'Пахта хом-ашёсининг ассортименти'} /> */}
      {/* <RadioButton name={'name'} label={'Пахта навлари бўйича ҳисобот'} /> */}
      {/* <RadioButton name={'name'} label={'Партиялар бўйича ҳисобот'} /> */}
      {/* <RadioButton name={'name'} label={'Селекция бўйича ҳисобот'} /> */}
      <DownloadButton onClick={getExcelFile.handleDownload}>
        <Download size={16} style={{ marginRight: '7px' }} /> Кўчириб олиш
      </DownloadButton>
      {/* <ExcelBuilder excelData={{ */}
      {/*  excelList: [ */}
      {/*    { */}
      {/*      market: { */}
      {/*        name: 'puk puk', */}
      {/*        marketType: { */}
      {/*          name: 'piki piki' */}
      {/*        } */}
      {/*      }, */}
      {/*      createdDate: '41', */}
      {/*      id: 1 */}
      {/*    } */}
      {/*  ], */}
      {/*  excelTitles: listHeader, */}
      {/*  withTitle: 'Test', */}
      {/*  secondTitle: 'SubTitle', */}
      {/*  excelTitlesSecondary: excelTitlesSecondary, */}
      {/*  fileName: 'Заказы', */}
      {/*  element: ( */}
      {/*    <DownloadButton> */}
      {/*      <Download size={16} style={{ marginRight: '7px' }} /> Кўчириб олиш */}
      {/*    </DownloadButton> */}
      {/*  ) */}
      {/* }} */}
      {/* /> */}
    </ModalContent>
  )
}
const ModalDownload = enhance(({ excelParam, ...props }) => {
  // const search = path(['location', 'search'], props)
  // const queryParams = parseParams(search)
  // const date = prop('date', queryParams)
  const [modalIsOpen, setModalIsOpen] = React.useState(false)

  // (Excel)
  const onModalOpen = () => setModalIsOpen(true)
  const onModalClose = () => setModalIsOpen(false)

  React.useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style = null
    }
  }, [modalIsOpen])
  if (excelParam) {
    return (
      <>
        <OpenButton onClick={onModalOpen} id={'download'}>
          <Download size={16} style={{ marginRight: '7px' }} /> <span>Ҳисоботни кўчириб олиш</span>
        </OpenButton>
        <BGMusk isOpen={modalIsOpen} onClick={onModalClose} />
        {modalIsOpen && (
          <FixBugRender
            excelParam={excelParam}
            modalIsOpen={modalIsOpen}
          />
        )}
      </>
    )
  }
  return null
})

ModalDownload.propTypes = {
  excelParam: PropTypes.object
}

ModalDownload.defaultProps = {
}

export default ModalDownload
