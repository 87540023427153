import React from 'react'
import { DatePicker, Select, TimePicker } from 'components/FormFields'
import Filter, { LeftFilters } from 'components/Filter'
import PropTypes from 'prop-types'
import { Search } from 'react-feather'
import IconInput from 'components/FormFields/Input/IconInput'
import { prop, map, uniqBy } from 'ramda'
import styled from 'styled-components'
import moment from 'moment'

const getDate = date => {
  if (date) return new Date(date)
  return new Date(moment().format('YYYY-MM-DD'))
}

const getTime = time => {
  if (time) return time
  return '00:00:00'
}
const StyledSearch = styled(IconInput)`
  height: 38px;
`

const TableFilter = (props) => {
  const { onChangeFilter, initialValues, enterSearch, enterTime, listData } = props

  // const arrGroup = uniqBy(prop('gatewayId'), listData)
  // const array = map((item) => {
  //   const gatewayId = prop('gatewayId', item)
  //   const gateway = prop('gateway', item)
  //   return {
  //     value: gatewayId,
  //     label: gateway
  //   }
  // }, arrGroup)

  const arrGroupVersion = uniqBy(prop('version'), listData)
  const arrayVersion = map((item) => {
    const version = prop('version', item)
    return {
      value: version,
      label: version
    }
  }, arrGroupVersion)

  const arrGroupStatus = uniqBy(prop('status'), listData)
  const arrayStatus = map((item) => {
    const status = prop('status', item)
    return {
      value: status,
      label: status === false ? 'офлайн' : 'онлайн'
    }
  }, arrGroupStatus)
  const checkPhotos = [{ value: true, label: 'Включено' }, { value: null, label: 'Выключено' }]
  return (
    <Filter>
      <LeftFilters>
        <DatePicker
          label={'Сана'}
          value={getDate(initialValues.date)}
          onChange={value => onChangeFilter({ date: value })}
        />
        <TimePicker
          label={'Время'}
          onChange={() => {}}
          value={getTime(initialValues.time)}
          onKeyDown={enterTime}
        />
        {/* <Select */}
        {/*  label={'Бo\'лим'} */}
        {/*  value={+(initialValues.category)} */}
        {/*  options={array} */}
        {/*  onChange={value => onChangeFilter({ gateway_id: value })} */}
        {/* /> */}
        <Select
          label={'Версии'}
          value={+(initialValues.version)}
          options={arrayVersion}
          onChange={value => onChangeFilter({ version: value })}
        />
        <Select
          label={'Статус'}
          value={initialValues.status}
          options={arrayStatus}
          onChange={value => onChangeFilter({ status: value })}
        />
        <Select
          label={'Последнее фото'}
          value={initialValues.check_photo}
          options={checkPhotos}
          onChange={value => onChangeFilter({ check_photo: value })}
        />
        <StyledSearch icon={Search} id="search" onKeyDown={enterSearch} />
      </LeftFilters>
    </Filter>
  )
}

TableFilter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
  enterSearch: PropTypes.func.isRequired,
  enterTime: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  listData: PropTypes.array
}

export default TableFilter
