import { map, pipe, path, mean } from 'ramda'
import { round } from './round'
import toNumber from './toNumber'

export default (array, keyPath, precision) => {
  const average = pipe(
    map((item) => {
      const value = path(keyPath, item)
      if (precision) {
        return round(toNumber(value), precision)
      }
      return value
    }),
    mean
  )(array)
  return average || 0
}
