import { path } from 'ramda'
import { compose, pure, mapPropsStream } from 'recompose'
import { distinctUntilChanged, map, switchMap, last } from 'rxjs/operators'
import { connect } from 'react-redux'
import {
  getDataFromState,
  getListParams
} from 'utils/get'
import { isEqualSearch, DEFAULT_PICK_PARAMS } from 'utils/isEquals'
import { TABLE_UPDATE_INTERVAL } from 'constants/backend'
import { timer, of } from 'rxjs'

const withFetchList = params => {
  const {
    stateName,
    action,
    key = 'list',
    withInterval = false,
    mapper = getListParams,
    pickParams = DEFAULT_PICK_PARAMS
  } = params

  const mapStateToProps = state => ({
    [key]: getDataFromState(stateName, state)
  })
  const timer$ = timer(0, TABLE_UPDATE_INTERVAL)
  const mapDispatchToProps = { action }
  return compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    mapPropsStream(props$ => {
      const stream$ = props$
        .pipe(
          distinctUntilChanged(
            isEqualSearch(pickParams),
            path(['history', 'location', 'search'])
          ),
          switchMap(props => {
            if (withInterval) {
              return timer$.pipe(
                map(tick => {
                  return {
                    ...props,
                    tick
                  }
                })
              )
            }
            return of(props)
          })
        ).subscribe(props => {
          props.action(mapper(props, pickParams))
        })

      props$.pipe(last()).subscribe(() => stream$.unsubscribe())
      return props$
    }),
    pure
  )
}

export default withFetchList
