import styled from 'styled-components'

const getColAttrs = ({ excelTitle }) => {
  if (excelTitle) {
    return {
      'data-a-v': 'middle',
      'data-f-bold': 'true',
      'data-b-a-s': 'thin',
      'data-b-a-c': '#FFD8D8D8'
    }
  }
  return {
    'data-b-a-s': 'thin',
    'data-b-a-c': '#FFD8D8D8'
  }
}
export default styled('td').attrs(getColAttrs)`
  padding: 0px 10px;
  min-height: 30px;
  max-height: 5.25vh;
  background-color: ${props => props.selected ? '#E5E8EB' : null};
  text-align: ${props => props.align};
  width: ${props => props.width || null};
  white-space: ${props => props.whiteSpace || 'pre-wrap'};
  font-weight: ${props => props.bold ? '600' : '400'};
`
