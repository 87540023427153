import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, prop } from 'ramda'
import numberFormat from 'utils/numberFormat'
import mapIndexed from 'utils/mapIndexed'
import {
  Table,
  TableContainer,
  TableHeader,
  TableBody,
  TableRow,
  TableCol,
  TableFooterRow
} from 'components/Table'
import sumBy from 'utils/sumBy'
import styled from 'styled-components'
import dateFromUnix from 'utils/dateFromUnix'
import dateFormat from 'utils/dateFormat'
import excelCellWidth from '../../../../utils/excelCellWidth'
import averageBy from '../../../../utils/averageBy'

const StyledSection = styled(({ title, ...props }) => {
  return (
    <TableFooterRow {...props}>
      <TableCol colSpan={14} data-a-h={'center'}>
        {title}
      </TableCol>
    </TableFooterRow>
  )
})`
  text-align: center;
`

const HududlarProvisionTable = props => {
  const { listData } = props

  const tableContent = mapIndexed((item, index) => {
    const key = index + 1
    const id = prop('id', item)
    const appTm = prop('appTm', item)
    const invoiceNumber = prop('invoiceNumber', item)
    const weightGross = prop('weightGross', item)
    const weightTare = prop('weightTare', item)
    const weightNet = prop('weightNet', item)
    const weightCond = prop('weightCond', item)
    const weediness = prop('weediness', item)
    const humidity = prop('humidity', item)
    const pk17Number = prop('pk17Number', item)
    const weighingTimeGross = prop('weighingTimeGross', item)
    const weighingTimeTare = prop('weighingTimeTare', item)
    const gatewayTm = prop('gatewayTm', item)
    return (
      // eslint-disable-next-line no-console
      <TableRow key={id} onClick={() => console.warn('No Way ...')} tableArray={listData}>
        <TableCol align={'center'}>{key}</TableCol>

        <TableCol align={'center'}>{dateFormat(appTm)}</TableCol>
        <TableCol align={'center'}>{numberFormat(invoiceNumber)}</TableCol>

        <TableCol align={'center'}>{numberFormat(weightGross)}</TableCol>
        <TableCol align={'center'}>{numberFormat(weightTare)}</TableCol>
        <TableCol align={'center'}>{numberFormat(weightNet)}</TableCol>

        <TableCol align={'center'}>{numberFormat(weightCond)}</TableCol>

        <TableCol align={'center'}>{numberFormat(weediness)}</TableCol>
        <TableCol align={'center'}>{numberFormat(humidity)}</TableCol>

        <TableCol align={'center'}>{pk17Number}</TableCol>

        <TableCol align={'center'}>{weighingTimeGross}</TableCol>
        <TableCol align={'center'}>{weighingTimeTare}</TableCol>

        <TableCol align={'center'}>{dateFromUnix(gatewayTm)}</TableCol>
      </TableRow>
    )
  }, listData)

  // Summation
  const weightGrossTotal = sumBy(listData, ['weightGross'])
  const weightTareTotal = sumBy(listData, ['weightTare'])
  const weightNetTotal = sumBy(listData, ['weightNet'])
  const weightCondTotal = sumBy(listData, ['weightCond'])
  const weedinessTotal = averageBy(listData, ['weediness'])
  const humidityTotal = averageBy(listData, ['humidity'])

  const colSizeWidth = excelCellWidth(13)
  return (
    <TableContainer>
      <Table id="withExcel" data-cols-width={colSizeWidth}>
        <TableHeader tableArray={listData}>
          <TableRow tableArray={listData}>
            <TableCol rowSpan={2} align={'center'} excelTitle={true}>№</TableCol>
            <TableCol colSpan={2} align={'center'} excelTitle={true}>Юк хати</TableCol>
            <TableCol colSpan={3} align={'center'} excelTitle={true}>Физик вазни, кг</TableCol>
            <TableCol rowSpan={2} align={'center'} excelTitle={true}>Кондицион вазни</TableCol>
            <TableCol colSpan={2} align={'center'} excelTitle={true}>Лабор-я хулосаси</TableCol>
            <TableCol rowSpan={2} align={'center'} excelTitle={true}>ПК-17 рақами</TableCol>
            <TableCol colSpan={2} align={'center'} excelTitle={true}>Кайд этилган вақт</TableCol>
            <TableCol colSpan={1} align={'center'} excelTitle={true}>Серверга юборилган</TableCol>
          </TableRow>
          <TableRow tableArray={listData}>
            <TableCol align={'center'} excelTitle={true}>санаси</TableCol>
            <TableCol align={'center'} excelTitle={true}>рақами</TableCol>

            <TableCol align={'center'} excelTitle={true}>брутто</TableCol>
            <TableCol align={'center'} excelTitle={true}>тара</TableCol>
            <TableCol align={'center'} excelTitle={true}>нетто</TableCol>

            <TableCol align={'center'} excelTitle={true}>ифлос</TableCol>
            <TableCol align={'center'} excelTitle={true}>намлик</TableCol>

            <TableCol align={'center'} excelTitle={true}>кириш</TableCol>
            <TableCol align={'center'} excelTitle={true}>чиқиш</TableCol>

            <TableCol align={'center'}>1С га</TableCol>
          </TableRow>
        </TableHeader>
        <TableBody>
          {isEmpty(tableContent)
            ? <StyledSection title={'Бу фермер хали пунктга пахта топширмаган'} tableArray={listData} />
            : tableContent}
          {!isEmpty(tableContent) &&
          <TableFooterRow tableArray={listData}>
            <TableCol align={'center'} excelTitle={true} colSpan={1}>Жами:</TableCol>
            <TableCol align={'center'} excelTitle={true}> </TableCol>

            <TableCol align={'center'} excelTitle={true}> </TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(weightGrossTotal)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(weightTareTotal)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(weightNetTotal)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(weightCondTotal)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(weedinessTotal)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(humidityTotal)}</TableCol>

            <TableCol align={'center'} excelTitle={true}> -</TableCol>

            <TableCol align={'center'} excelTitle={true}>- </TableCol>
            <TableCol align={'center'} excelTitle={true}> -</TableCol>
            <TableCol align={'center'} excelTitle={true}> -</TableCol>
          </TableFooterRow>}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

HududlarProvisionTable.propTypes = {
  listData: PropTypes.array.isRequired
}

export default HududlarProvisionTable
