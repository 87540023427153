import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import path from 'ramda/es/path'
import { Link } from 'react-router-dom'
import Container from '../Layouts/Container'
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'
import menuLogo from '../../assets/menuLogo.png'
import { mediaQueries } from 'constants/mediaQueries'
import { LogOut } from 'react-feather'
import ClockTime from '../Clock/ClockTime'

const StyledContainer = styled(Container)`
  background-color: ${props => props.theme.palette.primaryColor};
  max-width: unset;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 10;
  @media ${mediaQueries.laptopS} {
    position: relative;
  }
`

const RightOptions = styled('div')`
  margin-left: auto;
  display: flex;
`

const Navigation = styled('div')`
  display: flex;
  justify-content: flex-start;
  height: 10vh;
  max-height: 72px;;
  margin: 0 auto;
  padding: 0 25px;
  @media print {
    display: none;
  }
`

const Logo = styled(Link)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: white;
  font-weight: 500;
  font-size: 20px;
  text-decoration: none;
  margin-right: 30px;
  img:first-child{
    height: 40px;
    max-height: 40px;;
    margin-right: 8px;
    @media ${mediaQueries.laptopS} {
      height: 7.5vh;
    }
  }
  h2{
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
    max-width: 150px;
    font-weight: 500;
    text-transform: uppercase;
    @media ${mediaQueries.laptopS} {
      font-size: 12px;
    }
    @media ${mediaQueries.tabletL} {
      font-size: 10px;
      line-height: 9px;
    }
  }
`

const Logout = styled('span')`
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 20px;
  svg{
    margin-right: 2px;
  }
`

const TOGGLE_THRESHOLD = 1100
const Menu = ({ onLogout, location }) => {
  const [bodyWidth, setBodyWidth] = React.useState(window.innerWidth)

  const onResize = (event) => {
    const bodyWidth = path(['srcElement', 'innerWidth'], event)
    setBodyWidth(bodyWidth)
  }

  React.useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    <StyledContainer>
      <Navigation>
        <Logo to={'/'}>
          <img src={menuLogo} alt={'Buxoro Agro Klaster'} />
          <h2>Агролайф Пахта Мониторинг</h2>
        </Logo>
        {bodyWidth <= TOGGLE_THRESHOLD
          ? <MobileMenu onLogout={onLogout} location={location} />
          : (
            <React.Fragment>
              <DesktopMenu location={location} />
              <RightOptions>
                <ClockTime />
                <Logout onClick={onLogout}><LogOut size={15} />Чиқиш</Logout>
              </RightOptions>
            </React.Fragment>
          )}
      </Navigation>
    </StyledContainer>
  )
}

Menu.propTypes = {
  onLogout: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
}

export default Menu
