import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Row } from '../Grid'
import TableContext from './TableContext'

const StyledRow = styled(Row)`
  align-items: center;
  padding: 12px 16px;
`

const FlexTableRow = ({ children }) => {
  return (
    <TableContext.Consumer>
      {contextValues => {
        const { gutter } = contextValues
        return (
          <StyledRow gutter={gutter}>{children}</StyledRow>
        )
      }}
    </TableContext.Consumer>
  )
}

FlexTableRow.propTypes = {
  children: PropTypes.node.isRequired
}

export default FlexTableRow
