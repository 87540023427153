import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import * as ROUTES from 'constants/routes'
import { storageData } from 'utils/storage'
import Box from 'components/Box'
import Title from 'components/Title'
import { Breadcrumb, BreadcrumbItem } from 'components/Breadcrumb'
import HududlarTerritoryTable from 'modules/home/components/hududlar-territory/HududlarTerritoryTable'
import TableFilter from 'modules/home/components/hududlar-territory/TableFilter'
import { sprintf } from 'sprintf-js'
import Loader from 'components/Loader/Loader'
import Filter from 'components/Filter'
import GraphFilter from 'modules/home/components/hududlar-region/GraphFilter'
import styled from 'styled-components'
import { DisplayFlex } from 'components/Styled'
import { mediaQueries } from 'constants/mediaQueries'
import HududlarTerritoryGraph from 'modules/home/components/hududlar-territory/HududlarTerritoryGraph'
import { NoPrint, Print } from 'react-easy-print'
import { parseParams } from '../../../../utils/url'
import format from 'date-fns/format'

const Header = styled(DisplayFlex)`
  align-items: center;
  justify-content: space-between;
  @media ${mediaQueries.laptopS}{
    display: flex;
    margin-left: 0px;
    flex-flow: column nowrap;
    margin-bottom: 0px;
    align-items: flex-start;
    > div:not(:last-child){
      margin-bottom: 4px;
    }
  }
`

const StyledTitle = styled(Title)`
  margin-bottom: 5px;
  margin-right: 25px;
  @media ${mediaQueries.mobileL}{
  }
`

const initialSwitcher = 'table'

const HududlarTerritory = props => {
  const { list, filterActions, setDailyStats, dailyStats, goProvision, history, gatewayList } = props
  const listLoading = prop('loading', list)

  const region = storageData('region').getValue()
  const regionId = prop('id', region)
  const regionName = prop('name', region)

  const district = storageData('district').getValue()
  const districtId = prop('id', district)
  const districtName = prop('name', district)

  const params = parseParams(props.location.search)
  const datePrint = prop('date', params) ? format(new Date(prop('date', params)), 'MM.dd.yyyy') : format(new Date(), 'MM.dd.yyyy')

  const territory = storageData('territory').getValue()
  const territoryName = prop('name', territory)

  const listData = prop('results', list)

  const [switcher, setSwitcher] = React.useState(initialSwitcher)

  return (
    <div>
      <NoPrint>
        <Header>
          <StyledTitle>{territoryName} бўйича {window.renderDate} йил пахта ҳосилини қабул қилиш мониторинги</StyledTitle>
        </Header>
      </NoPrint>

      <Print
        printOnly={true}
      >
        <Header>
          <Title print={true}>{territoryName} бўйича {datePrint} кунига пахта ҳосилини қабул қилиш мониторинги</Title>
        </Header>
      </Print>

      {switcher === 'table' &&
      <Box>
        {listLoading
          ? <Loader />
          : listData
            ? <HududlarTerritoryTable goProvision={goProvision} listData={listData} />
            : <div>Проверьте соединение с интернетом</div>}
        <NoPrint>
          <Breadcrumb>
            <BreadcrumbItem to={ROUTES.HUDUDLAR_LIST_PATH}>Худудлар</BreadcrumbItem>
            <BreadcrumbItem to={sprintf(ROUTES.HUDUDLAR_REGION_URL, regionId)}>{regionName}</BreadcrumbItem>
            <BreadcrumbItem to={sprintf(ROUTES.HUDUDLAR_DISTRICT_URL, districtId)}>{districtName}</BreadcrumbItem>
            <BreadcrumbItem>{territoryName}</BreadcrumbItem>
          </Breadcrumb>
        </NoPrint>
        <TableFilter
          excelParam={{
            fileName: `Ҳудудлар бўйича_territory_${datePrint}`,
            tableId: 'withExcel',
            excelTableName: `${territoryName} бўйича ${datePrint} кунига пахта ҳосилини қабул қилиш мониторинги`
          }}
          history={history}
          {...filterActions}
          gatewayList={gatewayList}
          switcher={switcher}
          setSwitcher={setSwitcher}
        />
      </Box>}

      {switcher === 'graph' &&
      <Box>
        {listLoading
          ? <Loader />
          : listData
            ? (
              <HududlarTerritoryGraph
                listData={listData}
                dailyStats={dailyStats}
              />
            )
            : <div>Проверьте соединение с интернетом</div>}
        <Breadcrumb>
          <BreadcrumbItem to={ROUTES.HUDUDLAR_LIST_PATH}>Худудлар</BreadcrumbItem>
          <BreadcrumbItem to={sprintf(ROUTES.HUDUDLAR_REGION_URL, regionId)}>{regionName}</BreadcrumbItem>
          <BreadcrumbItem to={sprintf(ROUTES.HUDUDLAR_DISTRICT_URL, districtId)}>{districtName}</BreadcrumbItem>
          <BreadcrumbItem>{territoryName}</BreadcrumbItem>
        </Breadcrumb>
        <Filter>
          <GraphFilter
            {...filterActions}
            dailyStats={dailyStats}
            setDailyStats={setDailyStats}
          />
        </Filter>
      </Box>}
    </div>
  )
}

HududlarTerritory.propTypes = {
  list: PropTypes.object.isRequired,
  filterActions: PropTypes.object.isRequired,
  goProvision: PropTypes.func.isRequired,
  setDailyStats: PropTypes.func.isRequired,
  dailyStats: PropTypes.bool.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  gatewayList: PropTypes.object
}

export default HududlarTerritory
