import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import * as ROUTES from 'constants/routes'
import { storageData } from 'utils/storage'
import Box from 'components/Box'
import Title from 'components/Title'
import { Breadcrumb, BreadcrumbItem } from 'components/Breadcrumb'
import HududlarProvisionTable from 'modules/home/components/hududlar-provision/HududlarProvisionTable'
import TableFilter from 'modules/home/components/hududlar-provision/TableFilter'
import { sprintf } from 'sprintf-js'
import styled from 'styled-components'
import Loader from 'components/Loader/Loader'
import { NoPrint, Print } from 'react-easy-print'
import { DisplayFlex } from '../../../../components/Styled'
import { mediaQueries } from '../../../../constants/mediaQueries'
import { parseParams } from '../../../../utils/url'
import format from 'date-fns/format'

const StyledTitle = styled(Title)`
  margin-bottom: 5px;
  margin-right: 25px;
`

const Header = styled(DisplayFlex)`
  align-items: center;
  justify-content: space-between;
  @media ${mediaQueries.laptopS}{
    display: flex;
    margin-left: 0px;
    flex-flow: column nowrap;
    margin-bottom: 0px;
    align-items: flex-start;
    > div:not(:last-child){
      margin-bottom: 4px;
    }
  }
`

const Home = props => {
  const { list, filterActions, gatewayList, history } = props

  const params = parseParams(props.location.search)
  const datePrint = prop('date', params) ? format(new Date(prop('date', params)), 'MM.dd.yyyy') : format(new Date(), 'MM.dd.yyyy')

  const region = storageData('region').getValue()
  const regionId = prop('id', region)
  const regionName = prop('name', region)

  const district = storageData('district').getValue()
  const districtId = prop('id', district)
  const districtName = prop('name', district)

  const territory = storageData('territory').getValue()
  const territoryId = prop('id', territory)
  const territoryName = prop('name', territory)

  const provision = storageData('provision').getValue()
  const provisionName = prop('name', provision)

  const listData = prop('results', list)
  const listLoading = prop('loading', list)

  return (
    <div>
      <NoPrint>
        <Header>
          <StyledTitle>{provisionName} бўйича {window.renderDate} йил пахта ҳосилини қабул қилиш мониторинги</StyledTitle>
        </Header>
      </NoPrint>

      <Print
        printOnly={true}
      >
        <Header>
          <Title print={true}>{provisionName} бўйича {datePrint} кунига пахта ҳосилини қабул қилиш мониторинги</Title>
        </Header>
      </Print>

      <Box>
        {listLoading
          ? <Loader />
          : listData
            ? <HududlarProvisionTable listData={listData} />
            : <div>Проверьте соединение с интернетом</div>}
        <NoPrint>
          <Breadcrumb>
            <BreadcrumbItem to={ROUTES.HUDUDLAR_LIST_PATH}>Худудлар</BreadcrumbItem>
            <BreadcrumbItem to={sprintf(ROUTES.HUDUDLAR_REGION_URL, regionId)}>{regionName}</BreadcrumbItem>
            <BreadcrumbItem to={sprintf(ROUTES.HUDUDLAR_DISTRICT_URL, districtId)}>{districtName}</BreadcrumbItem>
            <BreadcrumbItem to={sprintf(ROUTES.HUDUDLAR_TERRITORY_URL, territoryId)}>{territoryName}</BreadcrumbItem>
            <BreadcrumbItem>{provisionName}</BreadcrumbItem>
          </Breadcrumb>
        </NoPrint>
        <TableFilter
          {...filterActions} gatewayList={gatewayList} history={history}
          excelParam={{
            fileName: `Ҳудудлар бўйича_provision_${datePrint}`,
            tableId: 'withExcel',
            excelTableName: `${provisionName} бўйича ${datePrint} кунига пахта ҳосилини қабул қилиш мониторинги`
          }}
        />
      </Box>
    </div>
  )
}

Home.propTypes = {
  list: PropTypes.object.isRequired,
  gatewayList: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  filterActions: PropTypes.object.isRequired
}

export default Home
