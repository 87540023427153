import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ChevronRight } from 'react-feather'
import { mediaQueries } from '../../constants/mediaQueries'

const BreadcrumbContainer = styled('div')`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 20px;
    @media ${mediaQueries.laptopS}{
      margin-bottom: 8px;
      margin-top: 8px;
      margin-left: 0px;
  }
`

const Chevron = styled(ChevronRight)`
  margin-right: 5px;
  margin-left: 5px;
`

const Item = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 5px;
  &:last-child:not(:first-child) {
    color: ${props => props.theme.input.labelColor}
  }
`

const Breadcrumb = props => {
  const children = React.Children.toArray(props.children)
  const childrenCount = React.Children.count(props.children)
  return (
    <BreadcrumbContainer>
      {children.map((child, key) => {
        const childCount = key + 1
        const childNode = React.cloneElement(child)
        return (
          <Item key={key}>
            {childNode}
            {childCount !== childrenCount &&
            <Chevron size={14} />}
          </Item>
        )
      })}
    </BreadcrumbContainer>
  )
}

Breadcrumb.propTypes = {
  children: PropTypes.node.isRequired
}

export default Breadcrumb
