import { prop } from 'ramda'
import React from 'react'
import mapIndexed from 'utils/mapIndexed'
import numberFormat from 'utils/numberFormat'
import { storageData } from 'utils/storage'
import {
  Table,
  TableContainer,
  TableHeader,
  TableBody,
  TableColValid,
  TableRow,
  TableCol,
  TableFooterRow
} from 'components/Table'
import sumBy from '../../../../utils/sumBy'
import PropTypes from 'prop-types'
import averageBy from 'utils/averageBy'
import { round } from 'utils/round'
import excelCellWidth from '../../../../utils/excelCellWidth'

const KorxonaOrganisationTable = props => {
  const { listData, goStation } = props
  const onRedirect = (id, name) => {
    goStation(id)
    storageData('station').setValue({ id, name })
  }

  const averageWeightNetToday = averageBy(listData, ['weightNetToday'], 1)
  const averageWeightCondToday = averageBy(listData, ['weightCondToday'], 1)
  const averageWeightNetSeason = averageBy(listData, ['weightNetSeason'], 1)
  const averageWeightCondSeason = averageBy(listData, ['weightCondSeason'], 1)

  const totalStationCount = sumBy(listData, ['stationCount'])
  const totalWeightNetToday = sumBy(listData, ['weightNetToday'])
  const totalWeightCondToday = sumBy(listData, ['weightCondToday'])
  const totalDiscountSumTodayRebate = sumBy(listData, ['discountSumTodayRebate'])
  const totalDiscountSumTodayCape = sumBy(listData, ['discountSumTodayCape'])

  const totalWeightNetSeason = sumBy(listData, ['weightNetSeason'])
  const totalWeightCondSeason = sumBy(listData, ['weightCondSeason'])
  const totalDiscountSumSeasonRebate = sumBy(listData, ['discountSumSeasonRebate'])
  const totalDiscountSumSeasonCape = sumBy(listData, ['discountSumSeasonCape'])

  const colSizeWidth = excelCellWidth(12)
  return (
    <TableContainer>
      <Table id="withExcel" data-cols-width={colSizeWidth}>
        <TableHeader tableArray={listData}>
          <TableRow tableArray={listData}>
            <TableCol align={'center'} excelTitle={true} rowSpan={2}>№</TableCol>
            <TableCol align={'center'} excelTitle={true} rowSpan={2}>Пахта тозалаш корхоналари</TableCol>
            <TableCol align={'center'} excelTitle={true} rowSpan={2}>Қабул маскани сони</TableCol>
            <TableCol align={'center'} excelTitle={true} colSpan={4}>Бир кунда, тн</TableCol>
            <TableCol align={'center'} excelTitle={true} colSpan={4}>Мавсум бошидан</TableCol>
            <TableCol align={'center'} excelTitle={true} rowSpan={2}>Узилиш (соат:дақиқа)</TableCol>
          </TableRow>
          <TableRow tableArray={listData}>
            <TableCol align={'center'} excelTitle={true}>физик вазнда</TableCol>
            <TableCol align={'center'} excelTitle={true}>конд. вазнда</TableCol>
            <TableCol align={'center'} excelTitle={true}>скидка</TableCol>
            <TableCol align={'center'} excelTitle={true}>накидка</TableCol>

            <TableCol align={'center'} excelTitle={true}>физик вазнда</TableCol>
            <TableCol align={'center'} excelTitle={true}>конд. вазнда</TableCol>
            <TableCol align={'center'} excelTitle={true}>скидка</TableCol>
            <TableCol align={'center'} excelTitle={true}>накидка</TableCol>
          </TableRow>
        </TableHeader>
        <TableBody>
          {mapIndexed((item, index) => {
            const key = index + 1
            const id = prop('id', item)
            const organization = prop('name', item)
            const stationCount = prop('stationCount', item)

            const weightNetToday = prop('weightNetToday', item)
            const weightCondToday = prop('weightCondToday', item)
            const discountSumTodayRebate = prop('discountSumTodayRebate', item)
            const discountSumTodayCape = prop('discountSumTodayCape', item)

            const weightNetSeason = prop('weightNetSeason', item)
            const weightCondSeason = prop('weightCondSeason', item)
            const discountSumSeasonRebate = prop('discountSumSeasonRebate', item)
            const discountSumSeasonCape = prop('discountSumSeasonCape', item)

            const lastProvision = prop('lastProvision', item)

            const validWeightNetToday = averageWeightNetToday <= round(weightNetToday, 1)
            const validWeightCondToday = averageWeightCondToday <= round(weightCondToday, 1)
            const validWeightNetSeason = averageWeightNetSeason <= round(weightNetSeason, 1)
            const validWeightCondSeason = averageWeightCondSeason <= round(weightCondSeason, 1)
            return (
              <TableRow key={id} onClick={() => onRedirect(id, organization)} tableArray={listData}>
                <TableCol align={'center'} width={'3.9%'}>{key}</TableCol>
                <TableCol align={'left'} bold={true} width={'13.2%'}>{organization}</TableCol>
                <TableCol align={'center'} width={'8.3%'}>{stationCount}</TableCol>

                <TableColValid valid={validWeightNetToday} align={'center'} selected={true} width={'8.2%'}>{numberFormat(weightNetToday)}</TableColValid>
                <TableColValid valid={validWeightCondToday} align={'center'} selected={true} width={'6.5%'}>{numberFormat(weightCondToday)}</TableColValid>
                <TableCol align={'center'} selected={true} width={'8.3%'}>{numberFormat(discountSumTodayRebate)}</TableCol>
                <TableCol align={'center'} selected={true} width={'6.5%'}>{numberFormat(discountSumTodayCape)}</TableCol>

                <TableColValid valid={validWeightNetSeason} align={'center'} width={'8.3%'}>{numberFormat(weightNetSeason)}</TableColValid>
                <TableColValid valid={validWeightCondSeason} align={'center'} width={'8.3%'}>{numberFormat(weightCondSeason)}</TableColValid>
                <TableCol align={'center'} width={'8.3%'}>{numberFormat(discountSumSeasonRebate)}</TableCol>
                <TableCol align={'center'} width={'8.3%'}>{numberFormat(discountSumSeasonCape)}</TableCol>

                <TableCol align={'center'} width={'12.4%'}>{lastProvision}</TableCol>
              </TableRow>
            )
          }, listData)}
          <TableFooterRow tableArray={listData}>
            <TableCol align={'left'} excelTitle={true} colSpan={2}>Жами:</TableCol>
            <TableCol align={'center'} excelTitle={true}>{totalStationCount}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalWeightNetToday)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalWeightCondToday)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalDiscountSumTodayRebate)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalDiscountSumTodayCape)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalWeightNetSeason)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalWeightCondSeason)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalDiscountSumSeasonRebate)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalDiscountSumSeasonCape)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>- </TableCol>
          </TableFooterRow>
        </TableBody>

      </Table>
    </TableContainer>
  )
}

KorxonaOrganisationTable.propTypes = {
  listData: PropTypes.array.isRequired,
  goStation: PropTypes.func.isRequired
}

export default KorxonaOrganisationTable
