import React from 'react'
import { prop } from 'ramda'
import * as ROUTES from 'constants/routes'
import { storageData } from 'utils/storage'
import Box from 'components/Box'
import Title from 'components/Title'
import { Breadcrumb, BreadcrumbItem } from 'components/Breadcrumb'
import KorxonaOrganisationTable from './KorxonaOrganisationTable'
import styled from 'styled-components'
import { DisplayFlex } from '../../../../components/Styled'
import TableFilter from './TableFilter'
import PropTypes from 'prop-types'
import Loader from 'components/Loader/Loader'
import { mediaQueries } from '../../../../constants/mediaQueries'
import { NoPrint, Print } from 'react-easy-print'
import { parseParams } from 'utils/url'
import format from 'date-fns/format'

const Header = styled(DisplayFlex)`
  align-items: center;
  justify-content: space-between;
  @media ${mediaQueries.laptopS}{
    display: flex;
    margin-left: 0px;
    flex-flow: column nowrap;
    margin-bottom: 0px;
    align-items: flex-start;
    > div:not(:last-child){
      margin-bottom: 4px;
    }
  }
`

const StyledTitle = styled(Title)`
  margin-bottom: 5px;
  margin-right: 25px;
`

const KorxonaOrganisation = props => {
  const { list, filterActions, goStation, gatewayList, history } = props

  const params = parseParams(props.location.search)
  const datePrint = prop('date', params) ? format(new Date(prop('date', params)), 'MM.dd.yyyy') : format(new Date(), 'MM.dd.yyyy')

  // const cluster = storageData('cluster').getValue()
  // const clusterName = prop('name', cluster)
  // const clusterId = prop('id', cluster)

  const org = storageData('org').getValue()
  const orgName = prop('name', org)
  const listData = prop('results', list)
  const listLoading = prop('loading', list)

  return (
    <div>
      <NoPrint>
        <Header>
          <StyledTitle>{orgName} бўйича {window.renderDate} йил пахта ҳосилини қабул қилиш мониторинги</StyledTitle>
        </Header>
      </NoPrint>

      <Print
        printOnly={true}
      >
        <Header>
          <StyledTitle print={true}>{orgName} бўйича {datePrint} кунига пахта ҳосилини қабул қилиш мониторинги</StyledTitle>
        </Header>
      </Print>
      <Box>
        {listLoading
          ? <Loader />
          : listData
            ? <KorxonaOrganisationTable listData={listData} goStation={goStation} />
            : <div>Проверьте соединение с интернетом</div>}
        <NoPrint>
          <Breadcrumb>
            <BreadcrumbItem to={ROUTES.KORXONA_LIST_PATH}>Корхоналар</BreadcrumbItem>
            <BreadcrumbItem>{orgName}</BreadcrumbItem>
          </Breadcrumb>
        </NoPrint>
        <TableFilter
          {...filterActions}
          gatewayList={gatewayList}
          history={history}
          excelParam={{
            fileName: `Корхоналар бўйича_organisation_${datePrint}`,
            tableId: 'withExcel',
            excelTableName: `${orgName} бўйича ${datePrint} кунига пахта ҳосилини қабул қилиш мониторинги`
          }}
        />
      </Box>
    </div>
  )
}

KorxonaOrganisation.propTypes = {
  list: PropTypes.object.isRequired,
  goStation: PropTypes.func.isRequired,
  filterActions: PropTypes.object.isRequired,
  gatewayList: PropTypes.object.isRequired,
  location: PropTypes.object,
  history: PropTypes.object.isRequired
}

export default KorxonaOrganisation
