import React from 'react'
import { DatePicker } from 'components/FormFields'
import Filter, { LeftFilters, RightFilters } from 'components/Filter'
import PropTypes from 'prop-types'
import { Printer } from 'react-feather'
import ButtonPrint from 'components/ButtonPrint'
import ModalDownload from 'components/Modals'

const getDate = date => {
  if (date) return new Date(date)
  return new Date()
}

const TableFilter = (props) => {
  const { onChangeFilter, initialValues, excelParam } = props

  // const any = { value: 'any', label: 'Барча корхоналар' }
  // const viloyat = { value: 'null', label: 'Вилоят корхоналар' }

  // const searchParams = path(['location', 'search'], history)
  // const searchObj = parseParams(searchParams)
  // const typeKorxona = prop('category', searchObj)
  // const defaultValue = (typeKorxona === 'any') ? any : (typeKorxona === 'null') ? viloyat : null

  // const gatewayArr = path(['data', 'results'], gatewayList) || [{}]
  // const gatewayLoad = prop('loading', gatewayList)
  // const gatewayOption = gatewayArr && map((item) => {
  //   const id = prop('id', item)
  //   const name = prop('name', item)
  //   return { value: id, label: name }
  // }, gatewayArr)

  return (
    <Filter>
      <LeftFilters>
        <DatePicker
          label={'Сана'}
          value={getDate(initialValues.date)}
          onChange={value => onChangeFilter({ date: value })}
        />
        {/* <Select */}
        {/*  label={'Бo\'лим'} */}
        {/*  value={initialValues.category} */}
        {/*  defaultValue={defaultValue} */}
        {/*  options={[ */}
        {/*    any, */}
        {/*    viloyat */}
        {/*  ]} */}
        {/*  onChange={value => onChangeFilter({ category: value })} */}
        {/* /> */}
        {/* <Select */}
        {/*  label={'Шлюз'} */}
        {/*  loading={gatewayLoad} */}
        {/*  value={+(initialValues.gateway)} */}
        {/*  options={gatewayLoad ? [{ label: 'Загрузка.....' }] : gatewayOption} */}
        {/*  onChange={value => onChangeFilter({ gateway: value })} */}
        {/*  isClearable={false} */}
        {/* /> */}
      </LeftFilters>
      <RightFilters>
        <ModalDownload excelParam={excelParam} />
        <ButtonPrint><Printer size={22} color={'#2E384C'} /></ButtonPrint>
      </RightFilters>
    </Filter>
  )
}

TableFilter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
  excelParam: PropTypes.object,
  initialValues: PropTypes.object.isRequired
  // gatewayList: PropTypes.object.isRequired
}

export default TableFilter
