import { withRouter } from 'react-router-dom'
import { compose, withHandlers } from 'recompose'
import { sprintf } from 'sprintf-js'
import { paramsToSearch, parseParams } from 'utils/url'

export default ({ key, redirectUrl, withCurrentParams }) => {
  return compose(
    withRouter,
    withHandlers({
      [key]: ({ history, location }) => (id, params) => {
        const currentParams = parseParams(location.search)
        const allParams = withCurrentParams ? { ...params, ...currentParams } : params
        if (id) {
          history.push({
            pathname: sprintf(redirectUrl, id),
            search: paramsToSearch(allParams)
          })
        } else {
          history.push({
            pathname: redirectUrl,
            search: paramsToSearch(allParams)
          })
        }
      }
    })
  )
}
