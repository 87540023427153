import { compose } from 'recompose'
import { path } from 'ramda'
import { withFetchList, withFilter } from 'components/HOCs'
import * as STATES from 'constants/states'
import { DEFAULT_PICK_PARAMS } from 'utils/isEquals'
import { getListParams } from 'utils/get'
import FermerProvision from 'modules/fermer/components/fermer-provision'
import { connect } from 'react-redux'
import { innListAction } from '../../modules/fermer/actions'

const tablePickParams = [...DEFAULT_PICK_PARAMS, 'date', 'gateway']
export default compose(
  connect(),
  withFetchList({
    stateName: STATES.ORGANISATION_LIST,
    action: innListAction,
    withInterval: true,
    pickParams: tablePickParams,
    mapper: (props, key) => {
      const defaultListParams = getListParams(props, key)
      return {
        ...defaultListParams,
        inn: path(['match', 'params', 'id'], props),
        page_size: 1000
      }
    }
  }),
  withFilter({ fields: ['date'] })
)(FermerProvision)
