import styled from 'styled-components'
import TableRow from './TableRow'

export default styled('tbody')`
  & ${TableRow} {
    &:nth-child(even) {
      background-color: ${props => props.theme.table.backgroundColor};
    }
  }
`
