import { groupBy, pipe, prop, toPairs, path } from 'ramda'
import React from 'react'
import format from 'date-fns/format'
import {
  Table,
  TableContainer,
  TableHeader,
  TableBody,
  TableRow,
  TableCol,
  TableFooterRow
} from 'components/Table'
import PropTypes from 'prop-types'
import mapIndexed from '../../../../utils/mapIndexed'
import styled from 'styled-components'
import hexToRgb from '../../../../utils/hexToRgb'
import { Download } from 'react-feather'

const StyledSection = styled(({ title, ...props }) => {
  return (
    <TableFooterRow {...props}>
      <TableCol colSpan={17}>
        {title}
      </TableCol>
    </TableFooterRow>
  )
})`
  text-align: center;
`

const LinkButton = styled('a')`
  text-decoration: none;
  cursor: pointer;
  color: #6d73c0;
  &:hover {
    color: ${hexToRgb('#6d73c0', 0.5)}
  }
`

const DownloadButton = styled('a')`
  text-decoration: none;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #6d73c0;
  div {
  padding-right: 7px;
  };
  svg {
    width: 19px;
    height: 19px;
  };
  &:hover {
    color: ${hexToRgb('#6d73c0', 0.5)}
  }
`

const TooltipCol = styled(TableCol)`
  position: relative;
  color: ${props => props.color === false ? 'red' : props.color === 'default' ? '#273D56' : '#50a959'};
  div {
    display: none;
    position: absolute;
    background: #fff;
    padding: 4px;
    right: ${props => props.right || '-215px'};
    bottom: 25px;
      cursor: text;
    border: 1px solid #E4E7EE;
    border-radius: 12px;
    color: #273D56;
    font-weight: 600;
    };
  :hover {
  font-weight: 400;
  cursor: pointer;
    div {
      display: block;
      position: absolute;
    }
  }
`

const AdminTable = props => {
  const { listData, logsHandler } = props
  let globalIndex = 1

  const arrGroup = pipe(
    groupBy(prop('org')),
    toPairs
  )(listData)

  return (
    <TableContainer>
      <Table>
        <TableHeader>
          <TableRow>
            <TableCol rowSpan={2} align={'center'}>№</TableCol>
            <TableCol rowSpan={2} align={'center'}>Қабул маскани</TableCol>
            <TableCol rowSpan={2} align={'center'}>Қабул Қилувчи логин:</TableCol>
            <TableCol rowSpan={2} align={'center'}>IP</TableCol>
            <TableCol rowSpan={2} align={'center'}>Версия</TableCol>
            <TableCol rowSpan={2} align={'center'}>Охирга кирилган</TableCol>
            <TableCol rowSpan={2} align={'center'}>Бэкап</TableCol>
            <TableCol rowSpan={2} align={'center'}>Шлюз</TableCol>
            <TableCol rowSpan={2} align={'center'}>Разница времени</TableCol>
            <TableCol rowSpan={2} align={'center'}>Последнее фото</TableCol>
            <TableCol rowSpan={2} align={'center'}>Килинган ишлаб</TableCol>
          </TableRow>
        </TableHeader>
        <TableBody>
          {mapIndexed((arr, index) => {
            const [sector, list] = arr
            const title = sector && sector !== 'null' ? sector : 'Секция не определена'
            return (
              <React.Fragment key={index}>
                <StyledSection title={title} />
                {mapIndexed(item => {
                  const key = globalIndex
                  const id = prop('id', item)
                  const acceptorLogin = prop('acceptorLogin', item)
                  const acceptorFullname = prop('acceptorFullname', item)
                  const backup = path(['backup', 'link'], item)
                  const compName = prop('compName', item)
                  const differenceServerTime = prop('differenceServerTime', item)
                  const compOs = prop('compOs', item)
                  const gateway = prop('gateway', item)
                  const ip = prop('ip', item)
                  const lastOnline = prop('lastOnline', item)
                  const lastPhoto = prop('lastPhoto', item) || null
                  const logsUrl = prop('logsUrl', item)
                  const station = prop('station', item)
                  const stationCode = prop('stationCode', item)
                  const status = prop('status', item)
                  const token = prop('token', item)
                  const version = prop('version', item)

                  globalIndex++
                  return (
                    <TableRow key={id}>
                      <TableCol align={'center'}>{key}</TableCol>
                      <TooltipCol align={'left'} bold={true} color={'default'}>{station}
                        <div>Қабул Қилувчи исми: {acceptorFullname}<br />Қабул маскани код: {stationCode}
                        </div>
                      </TooltipCol>
                      <TableCol align={'center'}>{acceptorLogin}</TableCol>
                      <TooltipCol align={'center'} color={'default'}>{ip}<div>Компьютер номи: {compName} <br /> Компьютер иловаси: {compOs}</div></TooltipCol>
                      <TableCol align={'center'}>{version}</TableCol>
                      <TooltipCol align={'center'} color={status} right={'-58px'}>
                        {status === false ? 'офлайн' : 'онлайн'}  <div>{format(new Date(lastOnline), 'yyyy-MM-dd, HH:mm:ss')}</div>
                      </TooltipCol>
                      <TableCol align={'center'}><DownloadButton href={backup}><div>Скачать</div><Download /></DownloadButton></TableCol>
                      <TableCol align={'center'}>{gateway}</TableCol>
                      <TooltipCol align={'center'} color={differenceServerTime < 15 && true}>
                        {differenceServerTime}
                      </TooltipCol>
                      <TableCol align={'center'}>{lastPhoto ? format(new Date(lastPhoto), 'yyyy-MM-dd, HH:mm:ss') : null}</TableCol>
                      <TableCol align={'center'}><LinkButton onClick={() => logsHandler(logsUrl, token)}>Просмотреть</LinkButton></TableCol>
                    </TableRow>
                  )
                }, list)}
              </React.Fragment>
            )
          }, arrGroup)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

AdminTable.propTypes = {
  listData: PropTypes.array.isRequired,
  logsHandler: PropTypes.func.isRequired
}

export default AdminTable
