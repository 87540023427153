import React from 'react'
import { prop } from 'ramda'
import * as ROUTES from 'constants/routes'
import { storageData } from 'utils/storage'
import Box from 'components/Box'
import Title from 'components/Title'
import { Breadcrumb, BreadcrumbItem } from 'components/Breadcrumb'
import FermerRegionTable from './FermerRegionTable'
import styled from 'styled-components'
import { DisplayFlex } from '../../../../components/Styled'
import TableFilter from './TableFilter'
import PropTypes from 'prop-types'
import Loader from 'components/Loader/Loader'
import { mediaQueries } from '../../../../constants/mediaQueries'
import { NoPrint, Print } from 'react-easy-print'
import format from 'date-fns/format'
import { parseParams } from '../../../../utils/url'

const Header = styled(DisplayFlex)`
  align-items: center;
  justify-content: space-between;
  @media ${mediaQueries.laptopS}{
    display: flex;
    margin-left: 0px;
    flex-flow: column nowrap;
    margin-bottom: 0px;
    align-items: flex-start;
    > div:not(:last-child){
      margin-bottom: 4px;
    }
  }
`

const FermerRegion = props => {
  const { list, filterActions, goDistrict, history } = props
  const region = storageData('region').getValue()
  const regionName = prop('name', region)
  const listData = prop('data', list)
  const listLoading = prop('loading', list)
  const params = parseParams(props.location.search)
  const datePrint = prop('date', params) ? format(new Date(prop('date', params)), 'MM.dd.yyyy') : format(new Date(), 'MM.dd.yyyy')

  return (
    <div>
      <NoPrint>
        <Header>
          <Title>{regionName} бўйича {window.renderDate} йил пахта ҳосилини қабул қилиш мониторинги</Title>
        </Header>
      </NoPrint>

      <Print
        printOnly={true}
      >
        <Header>
          <Title print={true}>{regionName} бўйича {datePrint} кунига пахта ҳосилини қабул қилиш мониторинги</Title>
        </Header>
      </Print>
      <Box>
        {listLoading
          ? <Loader />
          : listData
            ? <FermerRegionTable listData={listData} goDistrict={goDistrict} />
            : <div>Проверьте соединение с интернетом</div>}
        <NoPrint>
          <Breadcrumb>
            <BreadcrumbItem to={ROUTES.FERMER_LIST_PATH}>Корхоналар</BreadcrumbItem>
            <BreadcrumbItem>{regionName}</BreadcrumbItem>
          </Breadcrumb>
        </NoPrint>
        <TableFilter
          {...filterActions}
          history={history}
          excelParam={{
            fileName: `Фермерлар бўйича_region_${datePrint}`,
            tableId: 'withExcel',
            excelTableName: `${regionName} бўйича ${datePrint} кунига пахта ҳосилини қабул қилиш мониторинги`
          }}
        />
      </Box>
    </div>
  )
}

FermerRegion.propTypes = {
  list: PropTypes.object.isRequired,
  goDistrict: PropTypes.func.isRequired,
  location: PropTypes.object,
  history: PropTypes.object.isRequired,
  filterActions: PropTypes.object.isRequired
}

export default FermerRegion
