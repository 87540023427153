import { prop } from 'ramda'
import React from 'react'
import styled from 'styled-components'
import numberFormat from 'utils/numberFormat'
import {
  Table,
  TableContainer,
  TableHeader,
  TableBody,
  TableRow,
  TableCol,
  TableFooterRow
} from 'components/Table'
import sumBy from 'utils/sumBy'
import PropTypes from 'prop-types'
import { storageData } from 'utils/storage'
import mapIndexed from 'utils/mapIndexed'
import excelCellWidth from '../../../../utils/excelCellWidth'

const SmallTable = styled(Table)`
  @media (max-width: 1600px) {
    zoom: 0.9;
  }
  @media (max-width: 1400px) {
    zoom: 0.85;
  }
  @media (max-width: 1300px) {
    zoom: 0.75;
  }
  @media (max-width: 1024px) {
    zoom: 0.65;
  }
  @media (max-width: 900px) {
    zoom: 0.6;
  }
  @media (max-width: 400px) {
      font-size: 20px;
      zoom: 0.5;
  }
`
const FermerTable = props => {
  const { listData, goRegion } = props
  const onRedirect = (id, name) => {
    goRegion(id)
    storageData('region').setValue({ id, name })
  }

  const totalContractCount = sumBy(listData, ['tTotalContractCount'])
  const totalContractAmount = sumBy(listData, ['tTotalContractAmount'])

  const totalNotStartedCount = sumBy(listData, ['tNotStartedCount'])
  const totalNotStartedAmount = sumBy(listData, ['tNotStartedAmount'])

  const totalProvisionCount50 = sumBy(listData, ['tStartedPercentCount50'])
  const totalProvisionAmount50 = sumBy(listData, ['tStartedPercentAmount50'])
  const totalProvisionResidue50 = sumBy(listData, ['tStartedResidue50'])

  const totalProvisionCount70 = sumBy(listData, ['tStartedPercentCount70'])
  const totalProvisionAmount70 = sumBy(listData, ['tStartedPercentAmount70'])
  const totalProvisionResidue70 = sumBy(listData, ['tStartedResidue70'])

  const totalProvisionCount90 = sumBy(listData, ['tStartedPercentCount90'])
  const totalProvisionAmount90 = sumBy(listData, ['tStartedPercentAmount90'])
  const totalProvisionResidue90 = sumBy(listData, ['tStartedResidue90'])

  const totalProvisionCount100 = sumBy(listData, ['tStartedPercentCount100'])
  const totalProvisionAmount100 = sumBy(listData, ['tStartedPercentAmount100'])
  const totalProvisionResidue100 = sumBy(listData, ['tStartedResidue100'])

  const totalFinishCount = sumBy(listData, ['tFinishCount'])
  const totalFinishAmount = sumBy(listData, ['tFinishAmount'])

  const colSizeWidth = excelCellWidth(20)
  return (
    <TableContainer>
      <SmallTable id="withExcel" data-cols-width={colSizeWidth}>
        <TableHeader tableArray={listData}>
          <TableRow tableArray={listData}>
            <TableCol rowSpan={2} align={'center'} excelTitle={true}>№</TableCol>
            <TableCol rowSpan={2} align={'center'} excelTitle={true}>Ҳудудлар</TableCol>
            <TableCol colSpan={2} align={'center'} excelTitle={true}>Шартнома</TableCol>
            <TableCol colSpan={2} align={'center'} excelTitle={true}>Бошланмаган</TableCol>
            <TableCol colSpan={3} align={'center'} excelTitle={true}>50% гача, тн</TableCol>
            <TableCol colSpan={3} align={'center'} excelTitle={true}>70% гача, тн</TableCol>
            <TableCol colSpan={3} align={'center'} excelTitle={true}>90% гача, тн</TableCol>
            <TableCol colSpan={3} align={'center'} excelTitle={true}>100% гача, тн</TableCol>
            <TableCol colSpan={2} align={'center'} excelTitle={true}>Бажарган</TableCol>
          </TableRow>
          <TableRow tableArray={listData}>
            <TableCol align={'center'} excelTitle={true}>сони</TableCol>
            <TableCol align={'center'} excelTitle={true}>тн</TableCol>

            <TableCol align={'center'} excelTitle={true}>сони</TableCol>
            <TableCol align={'center'} excelTitle={true}>тн</TableCol>

            <TableCol align={'center'} excelTitle={true}>сони</TableCol>
            <TableCol align={'center'} excelTitle={true}>шартн.</TableCol>
            <TableCol align={'center'} excelTitle={true}>қолди</TableCol>

            <TableCol align={'center'} excelTitle={true}>сони</TableCol>
            <TableCol align={'center'} excelTitle={true}>шартн.</TableCol>
            <TableCol align={'center'} excelTitle={true}>қолди</TableCol>

            <TableCol align={'center'} excelTitle={true}>сони</TableCol>
            <TableCol align={'center'} excelTitle={true}>шартн.</TableCol>
            <TableCol align={'center'} excelTitle={true}>қолди</TableCol>

            <TableCol align={'center'} excelTitle={true}>сони</TableCol>
            <TableCol align={'center'} excelTitle={true}>шартн.</TableCol>
            <TableCol align={'center'} excelTitle={true}>қолди</TableCol>

            <TableCol align={'center'} excelTitle={true}>сони</TableCol>
            <TableCol align={'center'} excelTitle={true}>орт. тн</TableCol>
          </TableRow>
        </TableHeader>
        <TableBody>
          {mapIndexed((item, index) => {
            const key = index + 1
            const id = prop('id', item)
            const region = prop('name', item)
            const tTotalContractCount = prop('tTotalContractCount', item)
            const tTotalContractAmount = prop('tTotalContractAmount', item)

            const tNotStartedCount = prop('tNotStartedCount', item)
            const tNotStartedAmount = prop('tNotStartedAmount', item)

            const tStartedPercentAmount50 = prop('tStartedPercentAmount50', item)
            const tStartedPercentCount50 = prop('tStartedPercentCount50', item)
            const tStartedResidue50 = prop('tStartedResidue50', item)

            const tStartedPercentAmount70 = prop('tStartedPercentAmount70', item)
            const tStartedPercentCount70 = prop('tStartedPercentCount70', item)
            const tStartedResidue70 = prop('tStartedResidue70', item)

            const tStartedPercentAmount90 = prop('tStartedPercentAmount90', item)
            const tStartedPercentCount90 = prop('tStartedPercentCount90', item)
            const tStartedResidue90 = prop('tStartedResidue90', item)

            const tStartedPercentAmount100 = prop('tStartedPercentAmount100', item)
            const tStartedPercentCount100 = prop('tStartedPercentCount100', item)
            const tStartedResidue100 = prop('tStartedResidue100', item)

            const tFinishCount = prop('tFinishCount', item)
            const tFinishAmount = prop('tFinishAmount', item)
            return (
              <TableRow key={id} onClick={() => { onRedirect(id, region) }} tableArray={listData}>
                <TableCol align={'center'}>{key}</TableCol>
                <TableCol align={'left'} bold={true} width={'9%'}>{region}</TableCol>

                <TableCol align={'center'}>{tTotalContractCount}</TableCol>
                <TableCol align={'center'}>{numberFormat(tTotalContractAmount)}</TableCol>

                <TableCol align={'center'}>{numberFormat(tNotStartedCount)}</TableCol>
                <TableCol align={'center'}>{numberFormat(tNotStartedAmount)}</TableCol>

                <TableCol align={'center'}>{numberFormat(tStartedPercentCount50)}</TableCol>
                <TableCol align={'center'}>{numberFormat(tStartedPercentAmount50)}</TableCol>
                <TableCol align={'center'}>{numberFormat(tStartedResidue50)}</TableCol>

                <TableCol align={'center'}>{numberFormat(tStartedPercentCount70)}</TableCol>
                <TableCol align={'center'}>{numberFormat(tStartedPercentAmount70)}</TableCol>
                <TableCol align={'center'}>{numberFormat(tStartedResidue70)}</TableCol>

                <TableCol align={'center'}>{numberFormat(tStartedPercentCount90)}</TableCol>
                <TableCol align={'center'}>{numberFormat(tStartedPercentAmount90)}</TableCol>
                <TableCol align={'center'}>{numberFormat(tStartedResidue90)}</TableCol>

                <TableCol align={'center'}>{numberFormat(tStartedPercentCount100)}</TableCol>
                <TableCol align={'center'}>{numberFormat(tStartedPercentAmount100)}</TableCol>
                <TableCol align={'center'}>{numberFormat(tStartedResidue100)}</TableCol>

                <TableCol align={'center'}>{numberFormat(tFinishCount)}</TableCol>
                <TableCol align={'center'}>{numberFormat(tFinishAmount)}</TableCol>
              </TableRow>
            )
          }, (listData))}

          <TableFooterRow tableArray={listData}>
            <TableCol align={'left'} excelTitle={true} colSpan={2}>Жами:</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalContractCount)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalContractAmount)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalNotStartedCount)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalNotStartedAmount)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalProvisionCount50)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalProvisionAmount50)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalProvisionResidue50)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalProvisionCount70)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalProvisionAmount70)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalProvisionResidue70)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalProvisionCount90)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalProvisionAmount90)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalProvisionResidue90)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalProvisionCount100)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalProvisionAmount100)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalProvisionResidue100)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalFinishCount)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalFinishAmount)}</TableCol>
          </TableFooterRow>
        </TableBody>
      </SmallTable>
    </TableContainer>
  )
}

FermerTable.propTypes = {
  listData: PropTypes.array.isRequired,
  goRegion: PropTypes.func.isRequired
}

export default FermerTable
