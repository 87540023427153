import axios, { getPayloadFromError, getPayloadFromSuccess } from 'utils/axios'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import { prop } from 'ramda'
import moment from "moment";

export const activeListAction = ({ time = '00:00:00', date, ...params }) => {
  const datec = date ? (date + 'T' + time) : null

  const defaultParams = { date: datec || null, page_size: 700, ...params }

  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API.ADMIN_LIST, { params: defaultParams })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.ADMIN_LIST,
      payload
    })
  }
}

export const activeLogsAction = (newUrl, newParams, newToken, param) => {
  const search = prop('dSearch', param) || null
  const page = prop('dPage', param) || null
  const params = { page_size: 10, search, page, ...newParams }
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState, newToken })
      .get(newUrl, { params })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.ADMIN_LOGS,
      payload
    })
  }
}
