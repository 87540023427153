import { groupBy, pipe, prop, reverse, toPairs } from 'ramda'
import React from 'react'
import mapIndexed from 'utils/mapIndexed'
import numberFormat from 'utils/numberFormat'
import { storageData } from 'utils/storage'
import {
  Table,
  TableContainer,
  TableHeader,
  TableBody,
  TableRow,
  TableCol,
  TableColValid,
  TableFooterRow
} from 'components/Table'
import sumBy from '../../../../utils/sumBy'
import PropTypes from 'prop-types'
import averageBy from 'utils/averageBy'
import { round } from '../../../../utils/round'
import excelCellWidth from '../../../../utils/excelCellWidth'

const FermerTerritoryTable = props => {
  const {
    listData,
    goProvision
  } = props

  // Handlers
  const onRedirect = (id, name) => {
    goProvision(id)
    storageData('provision').setValue({ id, name })
  }

  // Const
  const averageTodayPercent = averageBy(listData, ['todayPercent'], 2)
  const averageSeasonPercent = averageBy(listData, ['percent'], 2)
  const averageWeightNetTodayTotal = averageBy(listData, ['weightNetToday'], 1)
  const averageWeightNetTheSeason = averageBy(listData, ['weightNetTheSeason'], 1)

  // ArrGroup
  const arrGroup =
      pipe(
        groupBy((value) => {
          const score = value.seasonPercent || 0
          return score < 50 ? '50'
            : score < 70 ? '70'
              : score < 90 ? '90'
                : score < 100 ? '100'
                  : score >= 100 ? 'бажарганлар' : 'A'
        }),
        toPairs,
        reverse
      )(listData)

  // Const
  const totalAmount = sumBy(listData, ['should']) || 0
  const totalCropArea = sumBy(listData, ['should']) || 0
  const totalTotalProvisionToday = sumBy(listData, ['weightNetToday'], false, 1) || 0
  const totalTodayPercent = (+totalTotalProvisionToday / +totalAmount) * 100
  const totalWeightNetTheSeason = sumBy(listData, ['weightNetTheSeason'], false, 1) || 0
  const totalSeasonPercent = (+totalWeightNetTheSeason / +totalAmount) * 100
  const totalResidue = sumBy(listData, ['residue']) || 0

  const colSizeWidth = excelCellWidth(10)

  // Render
  return (
    <TableContainer>
      <Table id="withExcel" data-cols-width={colSizeWidth}>
        <TableHeader tableArray={arrGroup}>
          <TableRow tableArray={arrGroup}>
            <TableCol align={'center'} excelTitle={true} rowSpan={2}>№</TableCol>
            <TableCol align={'center'} excelTitle={true} rowSpan={2}>Фермер хўжаликлари</TableCol>
            <TableCol align={'center'} excelTitle={true} colSpan={2}>шартнома</TableCol>
            <TableCol align={'center'} excelTitle={true} colSpan={2}>Бир кунда, тн</TableCol>
            <TableCol align={'center'} excelTitle={true} colSpan={3}>Мавсум бошидан</TableCol>
            <TableCol align={'center'} excelTitle={true} rowSpan={2}>Узилиш (соат:дақиқа)</TableCol>
          </TableRow>
          <TableRow tableArray={arrGroup}>
            <TableCol align={'center'} excelTitle={true}>га</TableCol>
            <TableCol align={'center'} excelTitle={true}>тн</TableCol>

            <TableCol align={'center'} excelTitle={true}>тн</TableCol>
            <TableCol align={'center'} excelTitle={true}>фоиз</TableCol>

            <TableCol align={'center'} excelTitle={true}>тн</TableCol>
            <TableCol align={'center'} excelTitle={true}>фоиз</TableCol>
            <TableCol align={'center'} excelTitle={true}>қолдиқ</TableCol>
          </TableRow>
        </TableHeader>
        <TableBody>
          {mapIndexed((arr, index) => {
            const sector = arr[0]
            const list = arr[1]
            const title = sector && sector !== 'null' ? sector : 'Секция не определена'

            // Total
            const totalGroupAmount = sumBy(list, ['should']) || 0
            const totalGroupArea = sumBy(list, ['should']) || 0

            const totalGroupProvisionToday = sumBy(list, ['weightNetToday']) || 0
            const totalGroupTodayPercent = (+totalGroupProvisionToday / +totalGroupAmount) * 100
            const totalGroupTodayPercentFormatted = round(totalGroupTodayPercent, 2)

            const totalGroupProvisionSeason = sumBy(list, ['weightNetTheSeason']) || 0
            const totalGroupSeasonPercent = (+totalGroupProvisionSeason / +totalGroupAmount) * 100
            const totalGroupSeasonPercentFormatted = round(totalGroupSeasonPercent, 2)
            const totalGroupResidue = sumBy(list, ['residue'])

            return (
              <React.Fragment key={index}>
                {mapIndexed((item, index) => {
                  const key = index + 1
                  const farmerInn = prop('inn', item)
                  const organization = prop('name', item)

                  const amount = prop('should', item)
                  const cropArea = prop('should', item)

                  const weightNetToday = prop('weightNetToday', item)
                  const todayPercent = prop('todayPercent', item)

                  const weightNetTheSeason = prop('weightNetTheSeason', item)
                  const percent = prop('percent', item)
                  const residue = prop('residue', item)

                  const lastProvision = prop('lastProvision', item)

                  // Valid
                  const validTodayPercent = averageTodayPercent <= round(todayPercent, 2)
                  const validStaticPercent = averageSeasonPercent <= round(percent, 2)
                  const validWeightNetToday = averageWeightNetTodayTotal <= round(weightNetToday, 1)
                  const validWeightNetTheSeason = averageWeightNetTheSeason <= round(weightNetTheSeason, 1)

                  return (
                    <TableRow key={index} onClick={() => onRedirect(farmerInn, organization)} tableArray={arrGroup}>
                      <TableCol align={'center'} width={'4%'}>{key}</TableCol>
                      <TableCol align={'left'} bold={true} width={'23%'}>{organization}</TableCol>

                      <TableCol align={'center'}>{numberFormat(cropArea)}</TableCol>
                      <TableCol align={'center'} width={'9%'}>{numberFormat(amount)}</TableCol>

                      <TableColValid align={'center'} valid={validWeightNetToday} selected={true} width={'9%'}>{numberFormat(weightNetToday, null, 1)}</TableColValid>
                      <TableColValid align={'center'} valid={validTodayPercent} selected={true} width={'6%'}>
                        {numberFormat(todayPercent, null, 2, true)}
                      </TableColValid>

                      <TableColValid align={'center'} valid={validWeightNetTheSeason} width={'9%'}>{numberFormat(weightNetTheSeason, null, 1)}</TableColValid>
                      <TableColValid align={'center'} width={'6%'} valid={validStaticPercent}>{numberFormat(percent, null, 2, true)}</TableColValid>
                      <TableCol align={'center'} width={'9%'}>{numberFormat(residue)}</TableCol>

                      <TableCol align={'center'} width={'20%'}>{lastProvision}</TableCol>
                    </TableRow>
                  )
                }, (list))}
                <TableFooterRow tableArray={arrGroup}>
                  <TableCol align={'left'} colSpan={2}>{title === 'бажарганлар' ? `${title} жами` : `${title}% гача жами`}:</TableCol>
                  <TableCol align={'center'}>{numberFormat(totalGroupArea)}</TableCol>
                  <TableCol align={'center'}>{numberFormat(totalGroupAmount)}</TableCol>

                  <TableCol align={'center'}>{numberFormat(totalGroupProvisionToday, null, 1)}</TableCol>
                  <TableCol align={'center'}>{numberFormat(totalGroupTodayPercentFormatted, null, 2, true)}</TableCol>

                  <TableCol align={'center'}>{numberFormat(totalGroupProvisionSeason, null, 1)}</TableCol>
                  <TableCol align={'center'}>{numberFormat(totalGroupSeasonPercentFormatted, null, 2, true)}</TableCol>
                  <TableCol align={'center'}>{numberFormat(totalGroupResidue)}</TableCol>

                  <TableCol align={'center'}> </TableCol>
                </TableFooterRow>
              </React.Fragment>
            )
          }, (arrGroup))}
          <TableFooterRow tableArray={arrGroup}>
            <TableCol align={'left'} excelTitle={true} colSpan={2}>Жами:</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalCropArea)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalAmount)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalTotalProvisionToday, null, 1)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalTodayPercent, null, 2, true)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalWeightNetTheSeason, null, 1, true)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalSeasonPercent, null, 2, true)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(totalResidue)}</TableCol>

            <TableCol align={'center'} excelTitle={true}> </TableCol>
          </TableFooterRow>
        </TableBody>

      </Table>
    </TableContainer>
  )
}

FermerTerritoryTable.propTypes = {
  listData: PropTypes.array.isRequired,
  goProvision: PropTypes.func.isRequired
}

export default FermerTerritoryTable
