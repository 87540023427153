import styled from 'styled-components'
import { mediaQueries } from 'constants/mediaQueries'

export default styled('div')`
  color: ${props => props.theme.palette.textColor};
  font-size: 14px;
  margin: 0 auto;
  padding: 0 1vw;
  @media ${mediaQueries.laptopS}{
    padding: 2px
    ;
  }
`
