import styled from 'styled-components'
import { mediaQueries } from '../../constants/mediaQueries'

export default styled('div')`
  background-color: white;
  border-radius: 5px;
  padding: 0.25vh 20px;
  @media ${mediaQueries.laptopS} {
    padding: 1vh 0px;
  }
`
