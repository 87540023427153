import React from 'react'
import Filter, { LeftFilters, RightFilters } from 'components/Filter'
import PropTypes from 'prop-types'
import { Search } from 'react-feather'
import IconInput from '../../../../components/FormFields/Input/IconInput'
import styled from 'styled-components'
import CheckBoxOnOff from '../../../../components/FormFields/Checkbox/OnCheckBox'
import toBoolean from '../../../../utils/toBoolean'

const StyledSearch = styled(IconInput)`
  height: 38px;
`

const TableFilter = (props) => {
  const { enterSearch, onChangeFilter, initialValues } = props
  const valueCheckVPN = toBoolean(initialValues.VPN) || false

  return (
    <Filter>
      <LeftFilters>
        <StyledSearch icon={Search} id="search" onKeyDown={enterSearch} />
      </LeftFilters>
      <RightFilters>
        <CheckBoxOnOff
          title={'VPN:'}
          value={valueCheckVPN}
          onChange={(value => onChangeFilter({ VPN: value }))}
        />
      </RightFilters>
    </Filter>
  )
}

TableFilter.propTypes = {
  enterSearch: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default TableFilter
