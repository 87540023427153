import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import numberFormat from 'utils/numberFormat'
import {
  Table,
  TableContainer,
  TableHeader,
  TableBody,
  TableRow,
  TableCol,
  TableFooterRow, TableSection
} from 'components/Table'
import { groupBy, path, pipe, prop, range, sortBy, toPairs } from 'ramda'
import mapIndexed from 'utils/mapIndexed'
import sumBy from 'utils/sumBy'
import { storageData } from 'utils/storage'
import TableColValid from 'components/Table/TableColValid'
import averageBy from 'utils/averageBy'
import { round } from '../../../../utils/round'
import excelCellWidth from '../../../../utils/excelCellWidth'
import percentTotal from '../../../../utils/percentTotal'

const StyledSection = styled(({ title, ...props }) => {
  return (
    <TableFooterRow {...props}>
      <TableCol colSpan={12} data-a-h={'center'}>
        {title}
      </TableCol>
    </TableFooterRow>
  )
})`
  text-align: center;
`

const HududlarDistrictTable = props => {
  const { listData, goTerritory } = props

  const arrGroup = pipe(
    sortBy(prop('sectorIndex')),
    groupBy(prop('sector')),
    toPairs
  )(listData)

  const arrForTableSize = [...arrGroup, ...range(0, arrGroup.length)]
  const averageTodayPercent = averageBy(listData, ['static', 'todayPercent'], 2)
  const averageStaticPercent = averageBy(listData, ['static', 'percent'], 2)
  const averageWeightNetTodayTotal = averageBy(listData, ['weightNetToday'], 1)
  const averageWeightNetTheSeason = averageBy(listData, ['weightNetTheSeason'], 1)

  const tableContent = mapIndexed((arr, index) => {
    const sector = arr[0]
    const list = arr[1]

    // Summation
    const sectionStaticShouldTotal = sumBy(list, ['static', 'should'])
    const sectionWeightNetTodayTotal = sumBy(list, ['weightNetToday'], false, 1)
    const sectionStaticTodayPercentTotal = percentTotal(sectionWeightNetTodayTotal, sectionStaticShouldTotal)
    const sectionWeightNetTheSeasonTotal = sumBy(list, ['weightNetTheSeason'], false, 1)
    const sectionStaticPercentTotal = percentTotal(sectionWeightNetTheSeasonTotal, sectionStaticShouldTotal)
    const sectionStaticResidueTotal = sumBy(list, ['static', 'residue'])
    const sectionWeightNetYesterdayTotal = sumBy(list, ['weightNetYesterday'])
    const sectionStaticDifferenceTotal = sumBy(list, ['static', 'difference'])
    const title = sector && sector !== 'null' ? sector : 'Секция не определена'
    return (
      <React.Fragment key={index}>
        <StyledSection title={title} tableArray={arrForTableSize} />
        {mapIndexed((item, index) => {
          const key = index + 1
          const id = prop('id', item)
          const region = prop('name', item)
          const staticShould = path(['static', 'should'], item)
          const weightNetToday = prop('weightNetToday', item)
          const staticTodayPercent = path(['static', 'todayPercent'], item)
          const weightNetTheSeason = prop('weightNetTheSeason', item)
          const staticPercent = path(['static', 'percent'], item)
          const staticResidue = path(['static', 'residue'], item)
          const weightNetYesterday = prop('weightNetYesterday', item)
          const staticDifference = path(['static', 'difference'], item)
          const lastProvision = prop('lastProvision', item)

          // Valid
          const validTodayPercent = averageTodayPercent <= round(staticTodayPercent, 2)
          const validStaticPercent = averageStaticPercent <= round(staticPercent, 2)
          const validWeightNetToday = averageWeightNetTodayTotal <= round(weightNetToday, 1)
          const validWeightNetTheSeason = averageWeightNetTheSeason <= round(weightNetTheSeason, 1)
          return (
            <TableRow key={id} onClick={() => onRedirect(id, region)} tableArray={arrForTableSize}>
              <TableCol align={'center'} width={'3.9%'}>{key}</TableCol>
              <TableCol align={'left'} bold={true} width={'12.4%'}>{region}</TableCol>
              <TableCol align={'center'} width={'9.3%'}>{numberFormat(staticShould)}</TableCol>

              <TableColValid align={'center'} valid={validWeightNetToday} width={'8.2%'} selected={true}>{numberFormat(weightNetToday, null, 1)}</TableColValid>
              <TableColValid valid={validTodayPercent} align={'center'} width={'6.5%'} selected={true}>{numberFormat(staticTodayPercent, null, 2, true)}</TableColValid>

              <TableColValid align={'center'} valid={validWeightNetTheSeason} width={'8.3%'}>{numberFormat(weightNetTheSeason, null, 1)}</TableColValid>
              <TableColValid valid={validStaticPercent} align={'center'} width={'6.5%'}>{numberFormat(staticPercent, null, 2, true)}</TableColValid>
              <TableCol align={'center'} width={'8.3%'}>{numberFormat(staticResidue)}</TableCol>

              <TableCol align={'center'} width={'8.3%'}>{numberFormat(weightNetYesterday)}</TableCol>
              <TableCol align={'center'} width={'8.3%'}>{numberFormat(weightNetToday)}</TableCol>
              <TableColValid number={0} valid={staticDifference} align={'center'} width={'8.3%'}>{numberFormat(staticDifference)}</TableColValid>

              <TableCol align={'center'} width={'11.4%'}>{lastProvision}</TableCol>
            </TableRow>
          )
        }, list)}
        <TableSection tableArray={arrForTableSize}>
          <TableCol colSpan={2} align={'left'}>Жами:</TableCol>
          <TableCol align={'center'}>{numberFormat(sectionStaticShouldTotal)}</TableCol>

          <TableCol align={'center'}>{numberFormat(sectionWeightNetTodayTotal, null, 1)}</TableCol>
          <TableCol align={'center'}>{numberFormat(sectionStaticTodayPercentTotal, null, 2, true)}</TableCol>

          <TableCol align={'center'}>{numberFormat(sectionWeightNetTheSeasonTotal, null, 1)}</TableCol>
          <TableCol align={'center'}>{numberFormat(sectionStaticPercentTotal, null, 2, true)}</TableCol>
          <TableCol align={'center'}>{numberFormat(sectionStaticResidueTotal)}</TableCol>

          <TableCol align={'center'}>{numberFormat(sectionWeightNetYesterdayTotal)}</TableCol>
          <TableCol align={'center'}>{numberFormat(sectionWeightNetTodayTotal)}</TableCol>
          <TableCol align={'center'}>{numberFormat(sectionStaticDifferenceTotal)}</TableCol>

          <TableCol align={'center'}> </TableCol>
        </TableSection>
      </React.Fragment>
    )
  }, arrGroup)

  // Summation
  const staticShouldTotal = sumBy(listData, ['static', 'should'])
  const weightNetTodayTotal = sumBy(listData, ['weightNetToday'], false, 1)
  const weightNetTheSeasonTotal = sumBy(listData, ['weightNetTheSeason'], false, 1)
  const staticResidueTotal = sumBy(listData, ['static', 'residue'])
  const weightNetYesterdayTotal = sumBy(listData, ['weightNetYesterday'])
  const staticDifferenceTotal = sumBy(listData, ['static', 'difference'])
  const staticTodayPercentTotal = percentTotal(weightNetTodayTotal, staticShouldTotal)
  const staticPercentTotal = percentTotal(weightNetTheSeasonTotal, staticShouldTotal)

  const onRedirect = (id, name) => {
    goTerritory(id)
    storageData('territory').setValue({ id, name })
  }
  const colSizeWidth = excelCellWidth(12)
  return (
    <TableContainer>
      <Table id="withExcel" data-cols-width={colSizeWidth}>
        <TableHeader tableArray={arrForTableSize}>
          <TableRow tableArray={arrForTableSize}>
            <TableCol rowSpan={2} align={'center'} excelTitle={true}>№</TableCol>
            <TableCol rowSpan={2} align={'center'} excelTitle={true}>Ҳудудлар</TableCol>
            <TableCol rowSpan={2} align={'center'} excelTitle={true}>Қарор бўйича, тн</TableCol>
            <TableCol colSpan={2} align={'center'} excelTitle={true}>Бир кунда</TableCol>
            <TableCol colSpan={3} align={'center'} excelTitle={true}>Мавсум бошидан</TableCol>
            <TableCol colSpan={3} align={'center'} excelTitle={true}>Шу соатгача</TableCol>
            <TableCol rowSpan={2} align={'center'} excelTitle={true}>Узилиш (кун:соат:дақиқа)</TableCol>
          </TableRow>
          <TableRow tableArray={arrForTableSize}>
            <TableCol align={'center'} excelTitle={true}>тн</TableCol>
            <TableCol align={'center'} excelTitle={true}>фоиз</TableCol>

            <TableCol align={'center'} excelTitle={true}>тн</TableCol>
            <TableCol align={'center'} excelTitle={true}>фоиз</TableCol>
            <TableCol align={'center'} excelTitle={true}>қолдиқ</TableCol>

            <TableCol align={'center'} excelTitle={true}>кеча</TableCol>
            <TableCol align={'center'} excelTitle={true}>бугун</TableCol>
            <TableCol align={'center'} excelTitle={true}>фарқи</TableCol>
          </TableRow>
        </TableHeader>
        <TableBody>
          {tableContent}
          <TableFooterRow tableArray={arrForTableSize}>
            <TableCol align={'left'} colSpan={2} excelTitle={true}>Жами:</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(staticShouldTotal)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(weightNetTodayTotal, null, 1)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(staticTodayPercentTotal, null, 2, true)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(weightNetTheSeasonTotal, null, 1)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(staticPercentTotal, null, 2, true)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(staticResidueTotal)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(weightNetYesterdayTotal)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(weightNetTodayTotal)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(staticDifferenceTotal)}</TableCol>

            <TableCol> </TableCol>
          </TableFooterRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

HududlarDistrictTable.propTypes = {
  listData: PropTypes.array.isRequired,
  goTerritory: PropTypes.func.isRequired
}

export default HududlarDistrictTable
