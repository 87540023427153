import React from 'react'
import styled from 'styled-components'
import hexToRgba from 'utils/hexToRgb'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'
import { Table, TableBody, TableCol, TableHeader, TableRow } from '../../../../components/Table'
import mapIndexed from '../../../../utils/mapIndexed'
import { RightFilters } from '../../../../components/Filter'
import IconInput from '../../../../components/FormFields/Input/IconInput'
import { Search } from 'react-feather'
import Loader from '../../../../components/Loader/Loader'
import Pagination from '../../../../components/Pagination'

const BGMusk = styled('div')`
  background-color: ${hexToRgba('#000000', '0.18')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({ isOpen }) => isOpen ? '1' : '0'};
  transition: all .2s ease-out;
  visibility: ${({ isOpen }) => isOpen ? 'visible' : 'hidden'};
  z-index: 90;
  margin: 0 !important;
`

const TableModal = styled(Table)`
    min-width: 100%;
`

const ModalContent = styled('div')`
  margin: 0 !important;
  background: white;
  border-radius: 5px;
  padding: 30px 25px;
  box-shadow: ${({ isOpen }) => isOpen ? '-5px 0 15px rgba(0, 0, 0, 0.05)' : 'none'};
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  right: 0;
  transition: all .2s ease-out;
  transform: translateX(-50%) translateY(${({ isOpen }) => isOpen ? '-50%' : '-150vh'});
  max-width: 800px;
  width: 90%;
  z-index: 1000;
      overflow-Y: auto;
      overflow-X: hidden;
      height: 90%;
`
const BreakCol = styled(TableCol)`
  word-break: break-word;
  white-space: normal;
`

const Title = styled('span')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  h2{
    color: #273d56;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
  }
`

const AdminModalLogs = ({ modalIsOpen, setModalIsOpen, list, enterSearch }) => {
  const listData = path(['data', 'results'], list) || []
  const loading = prop(['loading'], list)
  const table = (
    <TableModal>

      <TableHeader>
        <TableRow>
          <TableCol rowSpan={2} align={'center'}>№</TableCol>
          <TableCol rowSpan={2} align={'center'}>acceptor</TableCol>
          <TableCol rowSpan={2} align={'center'}>actionText</TableCol>
          <TableCol rowSpan={2} align={'center'}>ipAddress</TableCol>
        </TableRow>
      </TableHeader>
      <TableBody>
        {mapIndexed((o, index) => {
          const id = prop('id', o)
          const acceptor = prop('acceptor', o)
          const actionText = prop('actionText', o)
          const ipAddress = prop('ipAddress', o)
          return (
            <TableRow key={index}>
              <TableCol align={'center'}>{id}</TableCol>
              <TableCol align={'left'}>{acceptor}</TableCol>
              <BreakCol align={'center'}> {actionText} </BreakCol>
              <TableCol align={'center'}>{ipAddress}</TableCol>
            </TableRow>
          )
        }, listData)}
      </TableBody>
    </TableModal>
  )
  return (
    <>
      <BGMusk isOpen={modalIsOpen} onClick={() => { setModalIsOpen(false) }} />
      <ModalContent isOpen={modalIsOpen}>
        <Title>
          <h2>Килинган ишлаб</h2>
          <Pagination data={prop('data', list)} newKeys={{ page: 'dPage', pageSize: 'dPageSize' }} />
          <RightFilters>
            <IconInput icon={Search} id="search" onKeyDown={enterSearch} />
          </RightFilters>
        </Title>
        {loading
          ? <Loader />
          : listData
            ? table
            : <div>Проверьте соединение с интернетом</div>}
      </ModalContent>
    </>
  )
}

AdminModalLogs.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  setModalIsOpen: PropTypes.func.isRequired,
  enterSearch: PropTypes.func.isRequired,
  list: PropTypes.object
}

export default AdminModalLogs
