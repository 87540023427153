import format from 'date-fns/format'
import axios, { getPayloadFromError, getPayloadFromSuccess } from 'utils/axios'
import { sprintf } from 'sprintf-js'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import { prop } from 'ramda'

export const contractListAction = params => {
  const date = prop('date', params) || format(new Date(), 'yyyy-MM-dd')
  const defaultParams = { date, ...params }

  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API.CONTRACT_LIST, { params: defaultParams })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.CONTRACT_LIST,
      payload
    })
  }
}

export const getContractListAction = ({ id, ...params }) => {
  const date = prop('date', params) || format(new Date(), 'yyyy-MM-dd')
  const defaultParams = { date, ...params }

  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(sprintf(API.GET_CONTRACT_LIST, id), { params: defaultParams })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.CONTRACT_LIST,
      payload
    })
  }
}

export const innListAction = params => {
  const date = prop('date', params) || format(new Date(), 'yyyy-MM-dd')
  const defaultParams = { date, inn: params.inn }
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API.TERRITORY_PROVISION_LIST, { params: defaultParams })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.ORGANISATION_LIST,
      payload
    })
  }
}
