import styled, { css } from 'styled-components'
import { mediaQueries } from 'constants/mediaQueries'
import rowHeight from 'utils/rowHeight'

export default styled('tr')`
  @media ${mediaQueries.desktop}{
    & td {
      height: ${({ tableArray }) => rowHeight(mediaQueries.desktop, tableArray)};
      padding: 5px 8px;
    }
  }
  @media ${mediaQueries.minLaptopL}{
    & td {
      height: ${({ tableArray }) => rowHeight(mediaQueries.minLaptopL, tableArray)};
      padding: 5px 8px;
    }
  }
    @media ${mediaQueries.laptopL}{
    & td {
      height: ${({ tableArray }) => rowHeight(mediaQueries.laptopL, tableArray)};
      padding: 3px 8px;
    }
  }
  @media ${mediaQueries.laptopS}{
    & td {
      height: ${({ tableArray }) => rowHeight(mediaQueries.laptopS, tableArray)};
      padding: 3px 8px;
    }
  }
  @media (max-width: 900px){
    & td {
      height: ${({ tableArray }) => rowHeight('900px', tableArray)};
      padding: 5px 8px;
    }
  }
    @media (max-width: 400px){
    & td {
      height: ${({ tableArray }) => rowHeight('400px', tableArray)};
      padding: 0 8px;
    }
  }
  ${({ onClick }) => onClick && css`
    cursor: pointer;
    transition: ${props => props.theme.transition};
    &:hover {
      background-color: #e1e6ef !important;
      & td {
        background-color: #e1e6ef !important;
      }
    }
  `}
`
