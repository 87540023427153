import styled from 'styled-components'
import { Row } from '../Grid'

export default styled('div')`
  & ${Row} {
    padding-top: 7px;
    padding-bottom: 7px;
    &:first-child {
      padding-top: 14px;
    }
    &:last-child {
      padding-bottom: 14px;
    }
  }
`
