import { prop, path } from 'ramda'
import React from 'react'
import PropTypes from 'prop-types'
import numberFormat from 'utils/numberFormat'
import { storageData } from 'utils/storage'
import sumBy from 'utils/sumBy'
import {
  Table,
  TableContainer,
  TableHeader,
  TableBody,
  TableRow,
  TableCol,
  TableColValid,
  TableFooterRow
} from 'components/Table'
import mapIndexed from 'utils/mapIndexed'
import averageBy from 'utils/averageBy'
import { round } from 'utils/round'
import excelCellWidth from 'utils/excelCellWidth'
import percentTotal from 'utils/percentTotal'

const HomeTable = (props) => {
  const { listData, goRegion } = props

  const onRedirect = (id, name) => {
    goRegion(id)
    storageData('region').setValue({ id, name })
  }
  const averageTodayPercent = averageBy(listData, ['static', 'todayPercent'], 2)
  const averageStaticPercent = averageBy(listData, ['static', 'percent'], 2)
  const averageWeightNetTodayTotal = averageBy(listData, ['weightNetToday'], 1)
  const averageWeightNetTheSeason = averageBy(listData, ['weightNetTheSeason'], 1)

  // Summation
  const staticShouldTotal = sumBy(listData, ['static', 'should'])
  const weightNetTodayTotal = sumBy(listData, ['weightNetToday'], false, 1)
  const weightNetTheSeasonTotal = sumBy(listData, ['weightNetTheSeason'], false, 1)
  const staticResidueTotal = sumBy(listData, ['static', 'residue'])
  const weightNetYesterdayTotal = sumBy(listData, ['weightNetYesterday'])
  const staticDifferenceTotal = sumBy(listData, ['static', 'difference'])
  const staticTodayPercentTotal = percentTotal(weightNetTodayTotal, staticShouldTotal)
  const staticPercentTotal = percentTotal(weightNetTheSeasonTotal, staticShouldTotal)

  const tableContent = mapIndexed((item, index) => {
    const key = index + 1
    const id = prop('id', item)
    const region = prop('name', item)
    const staticShould = path(['static', 'should'], item)
    const weightNetToday = prop('weightNetToday', item)
    const staticTodayPercent = path(['static', 'todayPercent'], item)
    const weightNetTheSeason = prop('weightNetTheSeason', item)
    const staticPercent = path(['static', 'percent'], item)
    const staticResidue = path(['static', 'residue'], item)
    const weightNetYesterday = prop('weightNetYesterday', item)
    const staticDifference = path(['static', 'difference'], item)
    const lastProvision = prop('lastProvision', item)
    // Valid
    const validTodayPercent = averageTodayPercent <= round(staticTodayPercent, 2)
    const validStaticPercent = averageStaticPercent <= round(staticPercent, 2)
    const validWeightNetToday = averageWeightNetTodayTotal <= round(weightNetToday, 1)
    const validWeightNetTheSeason = averageWeightNetTheSeason <= round(weightNetTheSeason, 1)

    return (
      <TableRow key={id} onClick={() => onRedirect(id, region)} tableArray={listData}>
        <TableCol align={'center'} width={'3.9%'}>{key}</TableCol>
        <TableCol align={'left'} bold={true} width={'12.4%'}>{region}</TableCol>
        <TableCol align={'center'} width={'9.3%'}>{numberFormat(staticShould)}</TableCol>

        <TableColValid align={'center'} valid={validWeightNetToday} width={'8.2%'} selected={true}>{numberFormat(weightNetToday, null, 1)}</TableColValid>
        <TableColValid valid={validTodayPercent} align={'center'} width={'6.5%'} selected={true}>{numberFormat(staticTodayPercent, null, 2, true)}</TableColValid>

        <TableColValid align={'center'} valid={validWeightNetTheSeason} width={'8.3%'}>{numberFormat(weightNetTheSeason, null, 1)}</TableColValid>
        <TableColValid valid={validStaticPercent} align={'center'} width={'6.5%'}>{numberFormat(staticPercent, null, 2, true)}</TableColValid>
        <TableCol align={'center'} width={'8.3%'}>{numberFormat(staticResidue)}</TableCol>

        <TableCol align={'center'} width={'8.3%'}>{numberFormat(weightNetYesterday)}</TableCol>
        <TableCol align={'center'} width={'8.3%'}>{numberFormat(weightNetToday)}</TableCol>
        <TableColValid number={0} valid={staticDifference} align={'center'} width={'8.3%'}>{numberFormat(staticDifference)}</TableColValid>

        <TableCol align={'center'} width={'11.4%'}>{lastProvision}</TableCol>
      </TableRow>
    )
  }, listData)
  const colSizeWidth = excelCellWidth(12)
  return (
    <TableContainer>
      <Table id="withExcel" data-cols-width={colSizeWidth}>
        <TableHeader tableArray={listData}>
          <TableRow tableArray={listData}>
            <TableCol rowSpan={2} align={'center'} excelTitle={true}>№</TableCol>
            <TableCol rowSpan={2} align={'center'} excelTitle={true}>Ҳудудлар</TableCol>
            <TableCol rowSpan={2} align={'center'} excelTitle={true}>Қарор бўйича, тн</TableCol>
            <TableCol colSpan={2} align={'center'} excelTitle={true}>Бир кунда</TableCol>
            <TableCol colSpan={3} align={'center'} excelTitle={true}>Мавсум бошидан</TableCol>
            <TableCol colSpan={3} align={'center'} excelTitle={true}>Шу соатгача</TableCol>
            <TableCol rowSpan={2} align={'center'} excelTitle={true}>Узилиш (кун:соат:дақиқа)</TableCol>
          </TableRow>
          <TableRow tableArray={listData}>
            <TableCol align={'center'} excelTitle={true}>тн</TableCol>
            <TableCol align={'center'} excelTitle={true}>фоиз</TableCol>

            <TableCol align={'center'} excelTitle={true}>тн</TableCol>
            <TableCol align={'center'} excelTitle={true}>фоиз</TableCol>
            <TableCol align={'center'} excelTitle={true}>қолдиқ</TableCol>

            <TableCol align={'center'} excelTitle={true}>кеча</TableCol>
            <TableCol align={'center'} excelTitle={true}>бугун</TableCol>
            <TableCol align={'center'} excelTitle={true}>фарқи</TableCol>
          </TableRow>
        </TableHeader>
        <TableBody>
          {tableContent}
          <TableFooterRow tableArray={listData}>
            <TableCol colSpan={2} align={'left'} excelTitle={true}>Жами:</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(staticShouldTotal)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(weightNetTodayTotal, null, 1)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(staticTodayPercentTotal, null, 2, true)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(weightNetTheSeasonTotal, null, 1)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(staticPercentTotal, null, 2, true)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(staticResidueTotal)}</TableCol>

            <TableCol align={'center'} excelTitle={true}>{numberFormat(weightNetYesterdayTotal)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(weightNetTodayTotal)}</TableCol>
            <TableCol align={'center'} excelTitle={true}>{numberFormat(staticDifferenceTotal)}</TableCol>

            <TableCol align={'center'} excelTitle={true}> </TableCol>
          </TableFooterRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

HomeTable.propTypes = {
  listData: PropTypes.array.isRequired,
  goRegion: PropTypes.func.isRequired
}

export default HomeTable
