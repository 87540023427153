import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Logo from 'assets/logo.png'
import Button from 'components/Button'
import CheckBoxOnOff from 'components/FormFields/Checkbox/OnCheckBox'
import toBoolean from '../../../../utils/toBoolean'

const Wrapper = styled('div')`
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
`

const Form = styled('div')`
  max-width: 219px;
  width: 100%;
`

const LogoWrap = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto 35px;
  h1{
    display: inline-block;
    margin: 5px 0 0;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: .5px;
    max-width: 200px;
    color: #00528a;
    text-transform: uppercase;
  }
`

const LogoImage = styled('img')`
  display: block;
  pointer-events: none;
`

// const Field = styled('div')`
//   margin-bottom: 20px;
// `

const AddParametr = styled('div')`
  margin-bottom: 20px;
`

const SubmitButton = styled(Button)`
  background-color: ${props => props.theme.palette.purpleColor};
  color: white;
  font-weight: 500;
  display: block;
  height: 46px;
  margin-top: 30px;
  width: 100%;
`

const PKSeventeenEnterForm = props => {
  const {
    onSubmitHandler,
    // gatewayList,
    onChangeFilter, initialValues
  } = props
  const valueCheckVPN = toBoolean(initialValues.VPN) || false
  // const gatewayArr = path(['data', 'results'], gatewayList) || [{}]
  // const gatewayLoad = prop('loading', gatewayList)
  // const gatewayOption = gatewayArr && map((item) => {
  //   const id = prop('id', item)
  //   const name = prop('name', item)
  //   return { value: id, label: name }
  // }, gatewayArr)

  return (
    <Wrapper>
      <Form>
        <LogoWrap>
          <LogoImage src={Logo} />
          <h1>Агролайф Пахта Мониторинг</h1>
        </LogoWrap>
        {/* <Field> */}
        {/*  <Select */}
        {/*    label={'Шлюз'} */}
        {/*    loading={gatewayLoad} */}
        {/*    value={+(initialValues.gateway)} */}
        {/*    options={gatewayLoad ? [{ label: 'Загрузка.....' }] : gatewayOption} */}
        {/*    onChange={value => onChangeFilter({ gateway: value })} */}
        {/*    isClearable={false} */}
        {/*  /> */}
        {/* </Field> */}
        <AddParametr>
          <CheckBoxOnOff
            title={'VPN:'}
            value={valueCheckVPN}
            onChange={(value => onChangeFilter({ VPN: value }))}
          />
        </AddParametr>
        <SubmitButton onClick={onSubmitHandler}>Отправить</SubmitButton>
      </Form>
    </Wrapper>
  )
}

PKSeventeenEnterForm.propTypes = {
  // gatewayList: PropTypes.object.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default PKSeventeenEnterForm
