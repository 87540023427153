import styled from 'styled-components'
import { mediaQueries } from 'constants/mediaQueries'

export default styled('div')`
  font-size: ${({ print }) => print ? '20px' : '22px'};
  font-weight: ${({ print }) => print ? '600' : '500'};
  margin-left: 20px;
  margin-right: 25px;
  width: ${({ print }) => print ? '100%' : null};
  text-align: ${({ print }) => print ? 'center' : null};
  margin-bottom: ${({ print }) => print ? '10px' : '5px'};
  line-height: 27px;
    @media ${mediaQueries.laptopL}{
      font-size: 1.55vw;
    }
   @media ${mediaQueries.laptopS}{
      font-size: 14px;
      line-height: 18px;
      margin-left: 0px;
      margin-bottom: 0px;
    } 
    @media ${mediaQueries.tabletL}{
      line-height: 13px;
    }
`
