import React from 'react'
import { prop } from 'ramda'
import Box from 'components/Box'
import TableFilter from './TableFilter'
import AdminTable from './AdminTable'
import PropTypes from 'prop-types'
import Loader from 'components/Loader/Loader'
import AdminModalLogs from './AdminModalLogs'

const Admin = props => {
  const { list, filterActions, goRegion, enterSearch, enterTime, enterSearchModal, logsHandler, modalIsOpen, setModalIsOpen, listLogs } = props
  const listData = prop('results', list)
  const listLoading = prop('loading', list)

  return (
    <div>
      <Box>
        <TableFilter {...filterActions} enterSearch={enterSearch} enterTime={enterTime} listData={listData} />
        {listLoading
          ? <Loader />
          : listData
            ? <AdminTable listData={listData} goRegion={goRegion} logsHandler={logsHandler} />
            : <div>Проверьте соединение с интернетом</div>}
      </Box>
      <AdminModalLogs modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} list={listLogs} enterSearch={enterSearchModal} />
    </div>
  )
}

Admin.propTypes = {
  list: PropTypes.object.isRequired,
  filterActions: PropTypes.object.isRequired,
  goRegion: PropTypes.func.isRequired,
  enterSearch: PropTypes.func.isRequired,
  enterTime: PropTypes.func.isRequired,
  enterSearchModal: PropTypes.func.isRequired,
  logsHandler: PropTypes.func.isRequired,
  listLogs: PropTypes.object,
  history: PropTypes.object,
  modalIsOpen: PropTypes.bool,
  setModalIsOpen: PropTypes.func
}

export default Admin
