import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { prop } from 'ramda'
import { mediaQueries } from 'constants/mediaQueries'
import Box from 'components/Box'
import Title from 'components/Title'
import { DisplayFlex } from 'components/Styled'
import Filter from 'components/Filter'
import TableFilter from 'modules/home/components/hududlar/TableFilter'
import HomeTable from './HomeTable'
import HomeGraph from './HomeGraph'
import GraphFilter from 'modules/home/components/hududlar/GraphFilter'
import Loader from 'components/Loader/Loader'
import { NoPrint, Print } from 'react-easy-print'
import format from 'date-fns/format'

const Header = styled(DisplayFlex)`
  align-items: center;
  justify-content: space-between;
  @media ${mediaQueries.laptopS}{
    display: flex;
    margin-left: 0px;
    flex-flow: column nowrap;
    margin-bottom: 0px;
    align-items: flex-start;
    > div:not(:last-child){
      margin-bottom: 4px;
    }
  }
`

const Options = styled('div')`
  margin-top: 10px
`

const initialSwitcher = 'table'
const Home = props => {
  const { list, filterActions, goRegion, setDailyStats, dailyStats, history, gatewayList, params } = props
  const datePrint = prop('date', params) ? format(new Date(prop('date', params)), 'MM.dd.yyyy') : format(new Date(), 'MM.dd.yyyy')
  const listData = prop('results', list)
  const listLoading = prop('loading', list)
  const [switcher, setSwitcher] = React.useState(initialSwitcher)

  return (
    <div>
      <NoPrint>
        <Header>
          <Title>Республика бўйича {window.renderDate} йил пахта ҳосилини қабул қилиш мониторинги</Title>
        </Header>
      </NoPrint>
      <Print
        printOnly={true}
      >
        <Header>
          <Title print={true}>Республика бўйича {datePrint} кунига пахта ҳосилини қабул қилиш мониторинги</Title>
        </Header>
      </Print>

      {switcher === 'table' &&
        <Box>
          {listLoading
            ? <Loader />
            : listData
              ? <HomeTable listData={listData} goRegion={goRegion} />
              : <div>Проверьте соединение с интернетом</div>}
          <Options>
            <TableFilter
              history={history}
              {...filterActions}
              excelParam={{
                fileName: `Ҳудудлар бўйича_vloyatlar_${datePrint}`,
                tableId: 'withExcel',
                excelTableName: `Республика бўйича ${datePrint} кунига пахта ҳосилини қабул қилиш мониторинги`
              }}
              gatewayList={gatewayList}
              initialSwitcher={initialSwitcher}
              setSwitcher={setSwitcher}
            />
          </Options>
        </Box>}

      {switcher === 'graph' &&
      <Box>
        {listLoading
          ? <Loader />
          : listData
            ? (
              <HomeGraph
                listData={listData}
                dailyStats={dailyStats}
              />
            )
            : <div>Проверьте соединение с интернетом</div>}
        <Filter>
          <GraphFilter
            {...filterActions}
            dailyStats={dailyStats}
            setDailyStats={setDailyStats}
            switcher={switcher}
            setSwitcher={setSwitcher}
          />
        </Filter>
      </Box>}
    </div>
  )
}

Home.propTypes = {
  list: PropTypes.object.isRequired,
  gatewayList: PropTypes.object,
  filterActions: PropTypes.object.isRequired,
  goRegion: PropTypes.func.isRequired,
  setDailyStats: PropTypes.func.isRequired,
  dailyStats: PropTypes.bool.isRequired,
  history: PropTypes.object,
  params: PropTypes.object
}

export default Home
