import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import mapIndexed from '../../../../../utils/mapIndexed'
import { Table, TableBody, TableCol, TableHeader, TableRow } from '../../../../../components/Table'
import styled from 'styled-components'
import hexToRgb from '../../../../../utils/hexToRgb'

const TableModal = styled(Table)`
    min-width: 100%;
`

const LinkButton = styled('a')`
  text-decoration: none;
  cursor: pointer;
  color: #6d73c0;
  &:hover {
    color: ${hexToRgb('#6d73c0', 0.5)}
  }
`

const DoublePKTable = ({ list, pushHandler }) => {
  return (
    <TableModal>
      <TableHeader>
        <TableRow>
          <TableCol rowSpan={1} colSpan={5} align={'center'}>Дубль</TableCol>
          <TableCol rowSpan={1} colSpan={4} align={'center'}>Реальные данные</TableCol>
        </TableRow>
        <TableRow>
          <TableCol rowSpan={1} align={'center'}>barcode</TableCol>
          <TableCol rowSpan={1} align={'center'}>Брутто</TableCol>
          <TableCol rowSpan={1} align={'center'}>Тарра</TableCol>
          <TableCol rowSpan={1} align={'center'}>Кондиция</TableCol>
          <TableCol rowSpan={1} align={'center'}>Подтверждение</TableCol>

          <TableCol rowSpan={1} align={'center'}>barcode</TableCol>
          <TableCol rowSpan={1} align={'center'}>Брутто</TableCol>
          <TableCol rowSpan={1} align={'center'}>Тарра</TableCol>
          <TableCol rowSpan={1} align={'center'}>Кондиция</TableCol>
        </TableRow>
      </TableHeader>
      <TableBody>
        {mapIndexed((o, index) => {
          const id = prop('id', o)
          const acceptor = prop('acceptor', o)
          const actionText = prop('actionText', o)
          const ipAddress = prop('ipAddress', o)
          return (
            <TableRow key={index}>
              <TableCol align={'center'}>{id}</TableCol>
              <TableCol align={'left'}>{acceptor}</TableCol>
              <TableCol align={'center'}> {actionText} </TableCol>
              <TableCol align={'center'}>{ipAddress}</TableCol>
              <TableCol align={'center'}><LinkButton onClick={() => { pushHandler() }}>Просмотреть</LinkButton></TableCol>

              <TableCol align={'center'}>{id}</TableCol>
              <TableCol align={'left'}>{acceptor}</TableCol>
              <TableCol align={'center'}> {actionText} </TableCol>
              <TableCol align={'center'}>{ipAddress}</TableCol>
            </TableRow>
          )
        }, list)}
      </TableBody>
    </TableModal>
  )
}

DoublePKTable.propTypes = {
  list: PropTypes.object,
  pushHandler: PropTypes.func.isRequired
}

export default DoublePKTable
