import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DatePickerDP, { registerLocale } from 'react-datepicker'
import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import ruRu from 'date-fns/locale/ru'
import { Calendar, ChevronLeft, ChevronRight } from 'react-feather'
import InputLabel from '../InputLabel'
import inputBaseStyle from './inputBaseStyle'

registerLocale('ru', ruRu)
const Wrapper = styled('div')`
  z-index: 2;
  & .react-datepicker__day--outside-month {
    opacity: 0.35;
  }
`

const InputContainer = styled('div')`
  position: relative;
`

const StyledIcon = styled('span')`
  svg{
    color: #bec4d6;
    position: absolute;
    top: 50%;
    left: ${props => props.navButtons ? '50px' : '14px'};
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 1;
  }
`

const datePadding = props => props.navButtons
  ? '10px 48px 10px 74px'
  : '10px 12px 10px 38px'
const StyledDatePicker = styled(DatePickerDP)({
  ...inputBaseStyle,
  padding: datePadding,
  width: '220px',
  '@media only screen and (max-width: 1024px)': {
    fontSize: '16px'
  },
  boxShadow: 'none'
})

const NavDate = styled('button')`
  position: absolute;
  width: 36px;
  height: 96%;
  background: #E5E8EB;
  border: 1px solid #e4e7ee;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  cursor: pointer;
  outline: none;
  top: 0;
`
const NextDate = styled(NavDate)`
  right: 1px;
  border-left: 1px solid #D1D1D1;
  bottom: 1px;
  top: 1px;
  border-radius: 0 5px 5px 0;
`
const PrevDate = styled(NavDate)`
  left: 1px;
  bottom: 1px;
  top: 1px;
  border-right: 1px solid #D1D1D1;
  border-radius: 5px 0 0 5px;
`

const DatePicker = props => {
  const { label, onChange, value, navButtons = true, ...rest } = props
  const [selectedDate, setSelectedDate] = useState(value)

  const onNavDay = (nav) => {
    const amountValue = nav === 'prev' ? '-1' : '1'
    const formedDate = addDays(selectedDate, amountValue)
    setSelectedDate(formedDate)
    if (onChange) {
      onChange(format(formedDate, 'yyyy-MM-dd'))
    }
  }

  const onChangeDate = (date) => {
    setSelectedDate(date)
    if (onChange) {
      if (date) onChange(format(date, 'yyyy-MM-dd'))
      else onChange(null)
    }
  }

  return (
    <Wrapper>
      <InputLabel>{label}</InputLabel>
      <InputContainer>
        {navButtons ? <PrevDate onClick={() => onNavDay('prev')}><ChevronLeft color={'#898F97'} size={18} /></PrevDate> : null}
        <StyledIcon navButtons={navButtons}>
          <Calendar size={18} />
        </StyledIcon>
        <StyledDatePicker
          {...rest}
          navButtons={navButtons}
          selected={selectedDate}
          dateFormat={'dd MMM yyyy'}
          locale={'ru'}
          onChange={onChangeDate}
        />
        {navButtons ? <NextDate onClick={() => onNavDay('next')}><ChevronRight color={'#898F97'} size={18} /></NextDate> : null}
      </InputContainer>
    </Wrapper>
  )
}

DatePicker.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  navButtons: PropTypes.bool,
  value: PropTypes.instanceOf(Date)
}

DatePicker.defaultProps = {
  placeholderText: 'Выберите дату'
}

export default DatePicker
