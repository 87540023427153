import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Checkbox } from '../Input/Checkbox'

const WrapperCheck = styled('div')`
  display: flex;
  flex-direction: row;
  min-height: 20px;
  justify-content: space-between;
  align-items: center;
  span {
    margin-bottom: 2px;
    margin-right: 10px;
  }
  label {
   cursor: pointer;
  }
`

const StyleCheck = styled('div')`
  min-width: 80px;
  height: 26px;
  background: #6D73C0;
  margin: 20px 0px;
  position: relative;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px rgba(0,0,0,0.5), 0px 1px 0px rgba(255,255,255,0.2);
:after {
    content: 'OFF';
    color: #e4e7ee;
    position: absolute;
    right: 10px;
    z-index: 0;
    font: 12px/26px Arial, sans-serif;
    font-weight: bold;
    text-shadow: 1px 1px 0px rgba(255,255,255,.15);
  }
:before {
    content: 'ON';
    color: #6DC075;
    position: absolute;
    left: 10px;
    z-index: 0;
    font: 12px/26px Arial, sans-serif;
    font-weight: bold;
  }
  label {
    display: block;
    width: 34px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 1;
    background: #fcfff4;
    background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
    border-radius: 50px;
    transition: all 0.4s ease;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
  }
  input[type=checkbox] {
    visibility: hidden;
  :checked + label {
      left: 43px;
    }
  }
`

const CheckBoxOnOff = ({ title, value, onChange, type, name }) => {
  const [active, changeActive] = React.useState(value)
  const handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    onChange(value)
    changeActive(value)
  }
  if (type) {
    const Name = name || Math.random()
    return (
      <WrapperCheck>
        <Checkbox
          type="checkbox"
          id={Name || Math.random()}
          name={Name || Math.random()}
          value={active}
          onChange={handleInputChange}
        />
        <span><label htmlFor={Name}>{title}</label></span>
      </WrapperCheck>
    )
  }
  const Name = name || Math.random()
  return (
    <WrapperCheck>
      <span>{title}</span>
      <StyleCheck>
        <input
          type="checkbox"
          id={Name}
          name={Name}
          value={active}
          onChange={handleInputChange}
        />
        <label htmlFor={Name} />
      </StyleCheck>
    </WrapperCheck>
  )
}

CheckBoxOnOff.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.bool,
  type: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string
}

export default CheckBoxOnOff
