import React from 'react'
import { prop } from 'ramda'
import { sprintf } from 'sprintf-js'
import * as ROUTES from 'constants/routes'
import { storageData } from 'utils/storage'
import Box from 'components/Box'
import Title from 'components/Title'
import { Breadcrumb, BreadcrumbItem } from 'components/Breadcrumb'
import KorxonaStationTable from './KorxonaStationTable'
import TableFilter from './TableFilter'
import PropTypes from 'prop-types'
import Loader from 'components/Loader/Loader'
import { NoPrint, Print } from 'react-easy-print'
import { parseParams } from 'utils/url'
import format from 'date-fns/format'

const KorxonaStation = props => {
  const { list, filterActions, goContract, gatewayList, history } = props

  const params = parseParams(props.location.search)
  const datePrint = prop('date', params) ? format(new Date(prop('date', params)), 'MM.dd.yyyy') : format(new Date(), 'MM.dd.yyyy')

  // const cluster = storageData('cluster').getValue()
  // const clusterName = prop('name', cluster)
  // const clusterId = prop('id', cluster)

  const org = storageData('org').getValue()
  const orgId = prop('id', org)
  const orgName = prop('name', org)

  const station = storageData('station').getValue()
  const stationName = prop('name', station)

  const listData = prop('results', list)
  const listLoading = prop('loading', list)

  return (
    <div>
      <NoPrint>
        <Title>{stationName} бўйича {window.renderDate} йил пахта ҳосилини қабул қилиш мониторинги</Title>
      </NoPrint>

      <Print
        printOnly={true}
      >
        <Title print={true}>{stationName} бўйича {datePrint} кунига пахта ҳосилини қабул қилиш мониторинги</Title>
      </Print>

      <Box>
        {listLoading
          ? <Loader />
          : listData
            ? <KorxonaStationTable listData={listData} goContract={goContract} />
            : <div>Проверьте соединение с интернетом</div>}
        <NoPrint>
          <Breadcrumb>
            <BreadcrumbItem to={ROUTES.KORXONA_LIST_PATH}>Корхоналар</BreadcrumbItem>
            {/* <BreadcrumbItem to={sprintf(ROUTES.KORXONA_CLUSTER_URL, clusterId)}>{clusterName}</BreadcrumbItem> */}
            <BreadcrumbItem to={sprintf(ROUTES.KORXONA_ORGANISATION_URL, orgId)}>{orgName}</BreadcrumbItem>
            <BreadcrumbItem>{stationName}</BreadcrumbItem>
          </Breadcrumb>
        </NoPrint>
        <TableFilter
          {...filterActions}
          gatewayList={gatewayList}
          history={history}
          excelParam={{
            fileName: `Корхоналар бўйича_station_${datePrint}`,
            tableId: 'withExcel',
            excelTableName: `${stationName} бўйича ${datePrint} кунига пахта ҳосилини қабул қилиш мониторинги`
          }}
        />
      </Box>
    </div>
  )
}

KorxonaStation.propTypes = {
  list: PropTypes.object.isRequired,
  goContract: PropTypes.func.isRequired,
  gatewayList: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  filterActions: PropTypes.object.isRequired
}

export default KorxonaStation
