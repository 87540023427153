import React from 'react'
import styled from 'styled-components'
import {
  TableCol,
  TableRow
} from 'components/Table'
import PropTypes from 'prop-types'
import { Checkbox } from 'components/FormFields/Input/Checkbox'
import { compose, lifecycle, withState } from 'recompose'
import { prop } from 'ramda'

const FlexCheck = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`

const StyledTableCol = styled(TableCol)`
  padding: 0px;
`
const enhanse = compose(
  withState('active', 'changeActive', false),
  lifecycle({
    componentDidUpdate (prevProps) {
      const { allSelect, changeActive } = this.props
      if (prevProps.allSelect !== allSelect) {
        changeActive(allSelect)
      }
    }
  })
)

export const CheckBox = enhanse(props => {
  const { handleCheckChieldElement, renderItem, keyObj, active, changeActive } = props
  const labels = renderItem(props)
  const isDelete = prop('isDelete', props)

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    changeActive(value)
    handleCheckChieldElement(value, keyObj)
  }

  return (
    <TableRow style={isDelete ? { background: '#ff5232' } : null}>
      <StyledTableCol>
        <FlexCheck>
          <Checkbox
            onChange={(event) => { handleInputChange(event, keyObj) }}
            type="checkbox"
            value={active}
            checked={active}
            id={`element${keyObj}`}
          />
        </FlexCheck>
      </StyledTableCol>
      {labels}
    </TableRow>
  )
})

CheckBox.propTypes = {
  handleCheckChieldElement: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  renderItem: PropTypes.any,
  allSelect: PropTypes.any,
  keyObj: PropTypes.any.isRequired
}

export default CheckBox
