import PropTypes from 'prop-types'
import styled from 'styled-components'

const SwitchButton = styled('div')`
  background: ${props => props.isActive && props.theme.palette.primaryColor};
  color: ${props => props.isActive ? 'white' : props.theme.palette.purpleColor};
  cursor: pointer;
  padding: 10px 15px 6px 15px;
  pointer-events: ${props => props.isActive ? 'none' : 'auto'};
  border-radius: 6px;
  & svg {
    background: ${props => props.isActive ? '#594CA6 !important' : 'transparent !important'};
    color: ${props => props.isActive ? '#transparent' : '#000 !important'};
    width: 18px;
    height: 18px;
  }
  :first-child{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  :last-child{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`

SwitchButton.propTypes = {
  value: PropTypes.string.isRequired
}

export default SwitchButton
