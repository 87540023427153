import React from 'react'
import { prop } from 'ramda'
import Box from 'components/Box'
import TableFilter from './TableFilter'
import DoubleTable from './DoubleTable'
import PropTypes from 'prop-types'
import Loader from 'components/Loader/Loader'
import DoubleModalLogs from './DoubleModalLogs'

const Double = props => {
  const {
    list, filterActions, goRegion, enterSearch, enterSearchModal,
    logsHandler, modalIsOpen, setModalIsOpen, listLogs,
    checkPhoto, weightList, pushHandler, checkPhotoHandler, params,
    checkPKHandler, weightListHandler
  } = props
  const listData = prop('results', list)
  const listLoading = prop('loading', list)

  return (
    <div>
      <Box>
        <TableFilter
          {...filterActions}
          enterSearch={enterSearch}
          listData={listData}
          weightListHandler={weightListHandler}
        />
        {listLoading
          ? <Loader />
          : listData
            ? (
              <DoubleTable
                params={params}
                listData={listData}
                goRegion={goRegion}
                logsHandler={logsHandler}
                checkPhotoHandler={checkPhotoHandler}
                checkPKHandler={checkPKHandler}
                weightListHandler={weightListHandler}
              />
            )
            : <div>Проверьте соединение с интернетом</div>}
      </Box>
      <DoubleModalLogs
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        list={listLogs}
        enterSearch={enterSearchModal}
        pushHandler={pushHandler}
        checkPhoto={checkPhoto}
        weightList={weightList}
        params={params}
      />
    </div>
  )
}

Double.propTypes = {
  list: PropTypes.object.isRequired,
  filterActions: PropTypes.object.isRequired,
  goRegion: PropTypes.func.isRequired,
  enterSearch: PropTypes.func.isRequired,
  enterSearchModal: PropTypes.func.isRequired,
  weightListHandler: PropTypes.func.isRequired,
  logsHandler: PropTypes.func.isRequired,
  pushHandler: PropTypes.func.isRequired,
  checkPKHandler: PropTypes.func.isRequired,
  checkPhotoHandler: PropTypes.func.isRequired,
  listLogs: PropTypes.object,
  checkPhoto: PropTypes.object,
  weightList: PropTypes.object,
  history: PropTypes.object,
  params: PropTypes.object,
  modalIsOpen: PropTypes.bool,
  setModalIsOpen: PropTypes.func
}

export default Double
