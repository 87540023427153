import React from 'react'
import { prop } from 'ramda'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import getMenus from 'constants/getMenus'
import hexToRgb from 'utils/hexToRgb'
import { storageData } from '../../utils/storage'
import Perms from '../Perms/Perms'
import { appendParamsToUrl, parseParams } from 'utils/url'
import PropTypes from 'prop-types'

const MenuItems = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-align: center;
`

const MenuItem = styled(NavLink)`
  align-items: center;
  color: ${hexToRgb('#ffffff', '0.7')};
  display: inline-flex;
  font-size: 15px;
  height: 100%;
  border-bottom: 3px solid transparent;
  padding: 0 14px;
  transition: ${props => props.theme.transition};
  text-decoration: none;
  &:hover, &.active {
    border-bottom: 3px solid white;
    background-color: rgba(250, 251, 253, 0.08);
    color: white;
  }
`

const DesktopMenu = ({ location }) => {
  const isSuperuser = storageData('isSuperuser').getValue()
  const menus = getMenus(isSuperuser)
  const params = parseParams(location.search)
  const date = prop('date', params)

  return (
    <MenuItems>
      {menus.map((item, index) => {
        const url = prop('url', item)
        const title = prop('title', item)
        const subPerms = prop('perms', item)
        const subI = (
          <MenuItem
            key={index}
            to={date ? {
              pathname: url,
              search: appendParamsToUrl({ date: date }, prop('search', location))
            } : {
              pathname: url
            }}
            activeClassName={'active'}
          >
            {title}
          </MenuItem>
        )
        if (subPerms) {
          return (
            <Perms perms={subPerms} key={index}>
              {subI}
            </Perms>
          )
        } else {
          return subI
        }
      })}
    </MenuItems>
  )
}

DesktopMenu.propTypes = {
  location: PropTypes.object.isRequired
}

export default DesktopMenu
