import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose, withHandlers } from 'recompose'
import { connect, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import * as ROUTES from 'constants/routes'
import PrintProvider, { NoPrint, Print } from 'react-easy-print'
import { logoutAction } from 'modules/sign-in/actions'
import Container from './Container'
import AuthWrapper from '../AuthWrapper'
import Menu from '../Menu'
import { mediaQueries } from 'constants/mediaQueries'
import { path, prop } from 'ramda'
import * as STATES from 'constants/states'
import { storageData } from '../../utils/storage'
import checkPermissions from '../../utils/checkPermissions'
import Loader from '../Loader/Loader'

const enhance = compose(
  connect(null, { logoutAction }),
  withRouter,
  withHandlers({
    onLogout: props => () => {
      props.logoutAction()
        .then(() => {
          props.history.replace(ROUTES.SIGN_IN_PATH)
        })
    }
  }),
  AuthWrapper
)

const Content = styled('div')`
  padding: 2vh 0;
  @media ${mediaQueries.laptopS}{
    padding: 3.5vh 5px;
  }
`

const Layout = props => {
  const {
    children,
    onLogout,
    location,
    history
  } = props

  // Data
  const auth = useSelector(prop(STATES.AUTH))
  const authLoading = path(['loading'], auth)

  // Location
  const pathname = path(['pathname'], location)

  // Const
  const permissions = storageData('permissions').getValue() || []
  const isAdmin = storageData('isSuperuser').getValue()

  // useEffect()
  useEffect(() => {
    if (!isAdmin) {
      const isRegion = checkPermissions(['is_region'], permissions)
      const isOrg = !isRegion && checkPermissions(['is_org'], permissions)
      const isContract = !isRegion && checkPermissions(['is_contract'], permissions)
      const isMonitoring = !isRegion && checkPermissions(['is_monitoring'], permissions)

      if (pathname === ROUTES.HUDUDLAR_LIST_PATH) {
        if (isOrg) {
          history.push({
            pathname: ROUTES.KORXONA_LIST_PATH
          })
        } else if (isContract) {
          history.push({
            pathname: ROUTES.FERMER_LIST_PATH
          })
        } else if (isMonitoring) {
          history.push({
            pathname: ROUTES.ADMIN_LIST_PATH
          })
        }
      }
    }
  // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      <PrintProvider>
        <NoPrint><Menu onLogout={onLogout} location={location} /></NoPrint>
        <Print>
          <Container>
            <Content>
              {authLoading
                ? <Loader />
                : children}
            </Content>
          </Container>
        </Print>
      </PrintProvider>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired
}

export default enhance(Layout)
