import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DisplayFlex } from '../Styled'
import { mediaQueries } from 'constants/mediaQueries'

const Switch = styled(DisplayFlex)`
  background-color: transparent;
  border-radius: 5px;
  @media ${mediaQueries.laptopS}{
    margin: 0 10px 10px 0;
  }
  @media (max-width: 569px){
      margin: 0px
  }
  @media print {
    display: none !important
  }
`

const Switcher = props => {
  const { children, initialValue, onChange, justifyContent } = props
  const [value, setValue] = React.useState(initialValue)

  const onClick = value => {
    setValue(value)
    if (onChange) onChange(value)
  }

  return (
    <Switch justifyContent={justifyContent}>
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          key: index,
          isActive: child.props.value === value,
          onClick: () => onClick(child.props.value)
        })
      })}
    </Switch>
  )
}

Switcher.propTypes = {
  initialValue: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  justifyContent: PropTypes.string
}

export default Switcher
