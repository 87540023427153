import React from 'react'
import PropTypes from 'prop-types'
import { prop, path } from 'ramda'
import {
  FlexTable,
  FlexTableHeader,
  FlexTableBody,
  FlexTableRow,
  FlexTableCol
} from 'components/FlexTable'
import ProgressBar from 'components/ProgressBar'
import numberFormat from 'utils/numberFormat'
import averageBy from 'utils/averageBy'

const HomeGraph = props => {
  const { dailyStats, listData } = props

  const averageToday = averageBy(listData, ['static', 'todayPercent'])

  return (
    <FlexTable gutter={30}>
      <FlexTableHeader>
        <FlexTableRow>
          <FlexTableCol span={5}>Вилоят</FlexTableCol>
          <FlexTableCol span={15}>Прогресс</FlexTableCol>
          <FlexTableCol span={4}>План (т.)</FlexTableCol>
        </FlexTableRow>
      </FlexTableHeader>
      <FlexTableBody>
        {listData.map((item, index) => {
          const key = index + 1
          const region = prop('name', item)
          const staticShould = path(['static', 'should'], item)
          const weightNetToday = prop('weightNetToday', item)
          const staticTodayPercent = path(['static', 'todayPercent'], item)
          const weightNetTheSeason = prop('weightNetTheSeason', item)
          const staticPercent = path(['static', 'percent'], item)
          return (
            <FlexTableRow key={key}>
              <FlexTableCol span={5}>{region}</FlexTableCol>
              <FlexTableCol span={15}>
                <ProgressBar
                  progress={staticPercent}
                  progressTon={weightNetTheSeason}
                  progressToday={staticTodayPercent}
                  progressTodayTon={weightNetToday}
                  dailyStats={dailyStats}
                  dailyGoalAverage={averageToday}
                />
              </FlexTableCol>
              <FlexTableCol span={4}>{numberFormat(staticShould)}</FlexTableCol>
            </FlexTableRow>
          )
        })}
      </FlexTableBody>
    </FlexTable>
  )
}

HomeGraph.propTypes = {
  dailyStats: PropTypes.bool.isRequired,
  listData: PropTypes.array.isRequired
}

export default HomeGraph
