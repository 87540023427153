import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DisplayFlex } from '../Styled'
import { mediaQueries } from '../../constants/mediaQueries'

const FilterContainer = styled(DisplayFlex)`
  margin-top: 10px;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: space-between;
  & > div {
    margin: 0 0 10px 0;
  }
  @media ${mediaQueries.laptopS}{
    margin-bottom: 0px;
  }
  @media (max-width: 569px){
    &> div {
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
  }
`

const Filter = props => {
  return (
    <FilterContainer className={'filters'}>
      {props.children}
    </FilterContainer>
  )
}

Filter.propTypes = {
  children: PropTypes.node.isRequired
}

export default Filter
