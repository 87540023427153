import React from 'react'
import Filter, { LeftFilters, RightFilters } from 'components/Filter'
import { Send, Search } from 'react-feather'
import PropTypes from 'prop-types'
import IconInput from '../../../../components/FormFields/Input/IconInput'
import { prop } from 'ramda'
import styled from 'styled-components'
import Pagination from 'components/Pagination'
import Button from 'components/Button/Button'
import { DatePicker, Select } from '../../../../components/FormFields'
import InputBase from '../../../../components/FormFields/Input/InputBase'

const StyleSearch = styled(IconInput)`
  height: 38px;
`

const StyledInput = styled(InputBase)`
  height: 38px;
  width: 70px
`

const ResendButton = styled(Button)`
  margin-left: 10px;
  margin-right: 10px;
`

const TwoElement = styled('div')`
  height: 38px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  span {
   margin-right: 5px;
  }
`

const AllSelect = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 20px;
`

const PaginationStyle = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const getDate = date => {
  if (date) return new Date(date)
  return null
}

const TableFilter = (props) => {
  const {
    enterSearch, list, initialValues, onSubmitCheckedFieldHandler,
    // changeArrayField, arrayField, setAllSelect,
    onChangeFilter,
    onSubmitInputGroupHandler, onSubmitFilterInputGroupHandler,
    resendVisible
  } = props
  const Yes = { value: 'true', label: 'Ha' }
  const No = { value: 'false', label: 'Yo`q' }
  const pagination = prop('data', list)
  // const onChange = (value) => {
  //   const newArray = arrayField.map((item) => {
  //     return { ...item, value: value }
  //   })
  //   changeArrayField(newArray)
  //   setAllSelect(value)
  // }
  return (
    <Filter>
      <LeftFilters>
        <AllSelect>
          {/* <CheckBoxOnOff */}
          {/*  type={true} */}
          {/*  title={'Выбрать все / Снять выбор'} */}
          {/*  value={allSelect} */}
          {/*  onChange={onChange} */}
          {/* /> */}
        </AllSelect>
        <TwoElement>
          <span>Регион</span>
          <StyledInput id="region" onChange={event => onChangeFilter({ regionId: event.target.value })} />
        </TwoElement>
        <TwoElement>
          <span>Район</span>
          <StyledInput id="district" onChange={event => onChangeFilter({ districtId: event.target.value })} />
        </TwoElement>
        <TwoElement>
          <span>territory</span>
          <StyledInput id="territory" onChange={event => onChangeFilter({ territoryId: event.target.value })} />
        </TwoElement>
        <TwoElement>
          <span>contractor</span>
          <StyledInput id="contractor" onChange={event => onChangeFilter({ contractorId: event.target.value })} />
        </TwoElement>
        <Button onClick={onSubmitFilterInputGroupHandler}> Отфильтровать </Button>
        {resendVisible && <ResendButton onClick={onSubmitInputGroupHandler}> Перенаправить все <Send size={22} color={'#929eac'} /></ResendButton>}
      </LeftFilters>
      <RightFilters>
        <Button onClick={onSubmitCheckedFieldHandler}> Перенаправить выделенное <Send size={22} color={'#929eac'} /></Button>
        <Select
          label={'Пахта уз'}
          value={initialValues.ag_given}
          options={[
            Yes,
            No
          ]}
          onChange={value => onChangeFilter({ ag_given: value })}
          isClearable={false}
        />
        <DatePicker
          label={'Сана'}
          value={getDate(initialValues.date)}
          onChange={value => onChangeFilter({ date: value })}
        />
        <PaginationStyle>
          <Pagination data={pagination} />
        </PaginationStyle>
        <StyleSearch icon={Search} id="search" onKeyDown={enterSearch} />
      </RightFilters>
    </Filter>
  )
}

TableFilter.propTypes = {
  enterSearch: PropTypes.func.isRequired,
  onSubmitCheckedFieldHandler: PropTypes.func.isRequired,
  onSubmitInputGroupHandler: PropTypes.func.isRequired,
  onSubmitFilterInputGroupHandler: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  // changeArrayField: PropTypes.func.isRequired,
  list: PropTypes.object,
  resendVisible: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired
  // arrayField: PropTypes.array,
  // setAllSelect: PropTypes.func
}

export default TableFilter
