import toNumber from './toNumber'
import { round } from './round'

export default (value, suffix, precision = 1, float) => {
  const formatter = new Intl.NumberFormat('ru-RU')
  const numberValue = toNumber(value)
  const roundedValue = round(numberValue, precision)
  if ((value || value === 0) && suffix) return `${formatter.format(roundedValue)} ${suffix}`
  else if (value && float) {
    const fixedValue = roundedValue.toFixed(precision)
    return formatter.format(fixedValue)
  } else if (value) return formatter.format(roundedValue)
  else if (float) return 0.0.toFixed(precision)
  return 0
}
