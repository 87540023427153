import { compose, mapPropsStream, withHandlers, withState } from 'recompose'
import { withFilter } from 'components/HOCs'
import { distinctUntilChanged } from 'rxjs/operators'
import { find, path, prop, propEq } from 'ramda'
import PKSeventeen from 'modules/pKSeventeen/components/pKSeventeen'
import { isEqualSearch } from 'utils/isEquals'
import {
  PKSeventeenCheckedFieldAction, PKSeventeenWeightListAction,
  territoryProvisionListAction
} from '../../modules/pKSeventeen/actions'
import { appendParamsToUrl, parseParams } from '../../utils/url'
import { connect } from 'react-redux'
import { getawayListAction } from '../../modules/home/actions'
import toBoolean from '../../utils/toBoolean'
import snackbar from '../../components/Snackbar'

const tablePickParams = ['page', 'search', 'date', 'page_size', 'ag_given']
export default compose(
  connect((state, props) => {
    const params = parseParams(props.location.search)
    const gatewayList = prop('gatewayList', state)
    const pKSeventeenList = prop('pKSeventeenList', state)
    return {
      params,
      gatewayList,
      pKSeventeenList
    }
  }),
  withFilter({ fields: ['gateway', 'date', 'checked', 'VPN', 'ag_given'] }),
  withState('filterSelect', 'setFilterSelect', true),
  withState('resendVisible', 'setResendVisible', false),
  withState('selectedField', 'setSelectedField', []),
  mapPropsStream(props$ => {
    props$
      .pipe(
        distinctUntilChanged(
          isEqualSearch(tablePickParams),
          path(['history', 'location', 'search'])
        )
      )
      .subscribe(props => {
        props.filterSelect && props.dispatch(getawayListAction())
      })
    props$
      .pipe(
        distinctUntilChanged(
          isEqualSearch(tablePickParams),
          path(['history', 'location', 'search'])
        )
      )
      .subscribe(props => {
        const token = prop('token', props.params)
        const url = prop('url', props.params)
        props.filterSelect === false && props.dispatch(territoryProvisionListAction(props.params, url, token))
      })
    return props$
  }),
  withHandlers({
    onSubmitHandler: props => () => {
      const { params, dispatch, gatewayList, history, setFilterSelect } = props
      const arr = path(['data', 'results'], gatewayList) || []
      const gatewayId = +(prop('gateway', params)) || null
      const withVPN = toBoolean(prop('VPN', params)) || false
      const findElement = find(propEq('id', gatewayId))(arr)
      const gatewayToken = prop('token', findElement)
      const gatewayUrl = withVPN ? prop('ipAddress', findElement) : prop('ipExternal', findElement)
      history.push({ search: appendParamsToUrl({ url: gatewayUrl, token: gatewayToken }, path(['history', 'location', 'search'], props)) })
      return dispatch(territoryProvisionListAction(params, gatewayUrl, gatewayToken))
        .then(() => {
          setFilterSelect(false)
        })
    },
    onSubmitCheckedFieldHandler: props => (values) => {
      const { dispatch, params } = props
      const url = prop('url', params)
      const token = prop('token', params)
      return dispatch(PKSeventeenCheckedFieldAction(values, url, token))
        .then(() => {
          dispatch(territoryProvisionListAction(params, url, token))
        })
    },
    enterSearch: props => (event) => {
      const { history } = props
      if (event.key === 'Enter') {
        history.push({ search: appendParamsToUrl({ search: event.target.value }, path(['history', 'location', 'search'], props)) })
      }
    },
    onSubmitFilterInputGroupHandler: props => () => {
      const { dispatch, params, setResendVisible } = props
      const url = prop('url', params)
      const token = prop('token', params)
      const regionId = prop('regionId', params) || null
      const districtId = prop('districtId', params) || null
      const territoryId = prop('territoryId', params) || null
      const contractorId = prop('contractorId', params) || null
      const weightParam = { contractor_id: contractorId, territory_id: territoryId, district_id: districtId, region_id: regionId }
      return dispatch(territoryProvisionListAction(params, url, token, weightParam))
        .then(() => {
          setResendVisible(true)
        })
    },
    onSubmitInputGroupHandler: props => () => {
      const { dispatch, params } = props
      const url = prop('url', params)
      const token = prop('token', params)
      const regionId = prop('regionId', params) || null
      const districtId = prop('districtId', params) || null
      const territoryId = prop('territoryId', params) || null
      const contractorId = prop('contractorId', params) || null
      const weightParam = { contractor_id: contractorId, territory_id: territoryId, district_id: districtId, region_id: regionId }
      return dispatch(PKSeventeenWeightListAction(params, url, token))
        .then(() => {
          return dispatch(snackbar({ label: 'Успешно отправлено' }))
        })
        .then(() => {
          return dispatch(territoryProvisionListAction(params, url, token, weightParam))
        })
    },
    enterTime: props => (event) => {
      const { history } = props
      if (event.key === 'Enter') {
        history.push({ search: appendParamsToUrl({ time: event.target.value }, path(['history', 'location', 'search'], props)) })
      }
    }
  })
)(PKSeventeen)
