import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'

const StyledItem = styled('div')`
  font-size: 14px;
`

const StyledLink = styled(Link)`
  color: ${props => props.theme.palette.textColor};
  font-size: 14px;
  transition: ${props => props.theme.transition};
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.palette.primaryColor}
  }
`

const BreadcrumbItem = ({ to, location, children }) => {
  if (to) {
    return (
      <StyledLink to={{ ...location, pathname: to }}>{children}</StyledLink>
    )
  }
  return (
    <StyledItem>{children}</StyledItem>
  )
}

BreadcrumbItem.propTypes = {
  to: PropTypes.string,
  location: PropTypes.object,
  children: PropTypes.node
}

export default withRouter(BreadcrumbItem)
