import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TableContainer } from '../Table'
import TableContext from './TableContext'

const StyledFlexTable = styled(TableContainer)`
  min-width: 900px;
  @media (max-width: 1000px) {
    zoom: 0.8;
  }
  @media (max-width: 820px) {
    zoom: 0.6;
  }
  @media (max-width: 635px) {
    zoom: 0.4;
  }
  @media (max-width: 400px) {
    zoom: 0.3;
  }
`

const FlexTable = ({ children, gutter }) => {
  const contextValues = { gutter }
  return (
    <TableContext.Provider value={contextValues}>
      <TableContainer>
        <StyledFlexTable>
          {children}
        </StyledFlexTable>
      </TableContainer>
    </TableContext.Provider>
  )
}

FlexTable.propTypes = {
  children: PropTypes.node.isRequired,
  gutter: PropTypes.number
}

export default FlexTable
