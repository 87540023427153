import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import numberFormat from 'utils/numberFormat'

const barAnim = props => {
  const width = (100 - props.progress) + '%'
  return keyframes`
    from {
      width: 100%;
    }
    to {
      width: ${width};
    }
  `
}

const textAnim = props => {
  const color = (props.progress <= 25) ? props.theme.palette.textColor : '#fff'
  return keyframes`
    from{
    color: transparent;
  }
  to{
    color: ${color};
  }
  `
}

const Content = styled('span')`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 10px;
  position: absolute;
  top: 50%;
  transform: translate(-100%, -50%);
  z-index: 1;
  color: transparent;
  animation: ${props => textAnim(props)} 0s forwards;
`
const ProgressTon = styled('span')`
  margin-right: 5px;
`
const ProgressTodayTon = styled('span')`
  padding: 3px 6px;
  border-radius: 20px;
  color: transparent;
  background: ${({ dailyGoal }) => {
    return dailyGoal ? '#34cf80' : '#fc6852'
  }};
  animation: ${props => textAnim(props)} .3s ease-out forwards;
`

const Progress = styled('div')`
  align-items: center;
  background: #9f85de;
  background: linear-gradient(269.73deg, #9f85de -0.27%, #89b5db 99.31%);
  border-radius: 5px;
  display: flex;
  height: 30px;
  position: relative;
  overflow: hidden;
  &:after {
    animation: ${props => barAnim(props)} 1s ease-out forwards;
    background-color: #EFF1F4;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  };
  &:before {
    background: ${({ dailyGoal }) => {
    return dailyGoal ? '#34cf80' : '#fc6852'
  }};
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: ${({ progressToday, dailyStats }) => {
    return dailyStats ? progressToday + '%' : '0'
  }};
    right: ${({ progress }) => {
    return (100 - progress) + '%'
  }};
    transition: right .3s, width .3s, background .3s;
  }
  
  & ${Content} {
    ${props => (props.progress > 25) && css`
      left: ${({ progress, progressToday, dailyStats }) => `${dailyStats ? (progress - progressToday) : progress}%`};
      transition: left .2s;
    `}
    ${props => (props.progress <= 25) && css`
      color: ${props => props.theme.palette.textColor};
      left: ${({ progress, progressToday, dailyStats }) => `${dailyStats ? progress : progress}%`};
      transform: translate(0%, -50%);
      transition: left .3s;
    `}
  }
`

const ProgressBar = (props) => {
  const {
    progress,
    progressTon,
    progressToday,
    progressTodayTon,
    dailyStats,
    dailyGoalAverage
  } = props

  const progressValid = progress >= 100 ? 100 : progress

  const dailyGoal = progressToday >= dailyGoalAverage
  return (
    <Progress
      progress={progressValid}
      progressToday={progressToday}
      dailyGoal={dailyGoal}
      dailyStats={dailyStats}
    >
      <Content progress={progressValid}>
        <ProgressTon>{numberFormat(progressTon)}&nbsp;т.</ProgressTon>
        {dailyStats && (
          <ProgressTodayTon dailyGoal={dailyGoal}>
            +{numberFormat(progressTodayTon)}&nbsp;т.
          </ProgressTodayTon>
        )}
      </Content>
    </Progress>
  )
}

ProgressBar.propTypes = {
  progress: PropTypes.number,
  progressToday: PropTypes.number,
  progressTon: PropTypes.number,
  progressTodayTon: PropTypes.number,
  dailyStats: PropTypes.bool,
  dailyGoalAverage: PropTypes.number
}

ProgressBar.defaultProps = {
  progress: 0
}

export default ProgressBar
