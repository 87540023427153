import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import InputBase from './InputBase'
import { mediaQueries } from '../../../constants/mediaQueries'

const StyledWrapper = styled('div')`
  position: unset;
`

const InputWrapper = styled('div')`
  position: relative;
  & > svg {
    color: #8290A0;
    position: absolute;
    top: 50%;
    left: 15px;
    transition: all 300ms;
    transform: translateY(-50%);
  }
`

const StyledInput = styled(props => <InputBase {...props} />)`
  padding-left: 40px;
  :focus + svg {
    color: ${props => props.theme.palette.primaryColor};
  }
  @media ${mediaQueries.laptopS}{
    font-size: 16px;
  }
`

const IconInput = ({ icon: Icon, label, ...props }) => {
  return (
    <StyledWrapper error={props.error}>
      <InputWrapper>
        <StyledInput {...props} />
        <Icon size={18} />
      </InputWrapper>
    </StyledWrapper>
  )
}

IconInput.propTypes = {
  icon: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any
}

export default IconInput
