import styled from 'styled-components'

const getTableAttrs = () => ({
  cellSpacing: 0,
  cellPadding: 0
})

export default styled('table').attrs(getTableAttrs)`
  border-radius: 5px;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  & tr, & td {
    border: ${props => props.theme.table.border};
    font-weight: 600;
    border-collapse: collapse;
  }
  @media (max-width: 1400px) {
    zoom: 0.85;
  }
  @media (max-width: 1024px) {
    zoom: 0.75;
  }
  @media (max-width: 900px) {
    zoom: 0.6;
  }
  @media (max-width: 400px) {
      font-size: 20px;
      zoom: 0.5;
  }
`
