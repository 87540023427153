import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { sprintf } from 'sprintf-js'
import * as ROUTES from 'constants/routes'
import { storageData } from 'utils/storage'
import Box from 'components/Box'
import Title from 'components/Title'
import { Breadcrumb, BreadcrumbItem } from 'components/Breadcrumb'
import HududlarDistrictTable from './HududlarDistrictTable'
import TableFilter from 'modules/home/components/hududlar-disctrict/TableFilter'
import Loader from 'components/Loader/Loader'
import styled from 'styled-components'
import { DisplayFlex } from 'components/Styled'
import { mediaQueries } from 'constants/mediaQueries'
import Filter from 'components/Filter'
import GraphFilter from 'modules/home/components/hududlar-region/GraphFilter'
import HududlarDistrictGraph from 'modules/home/components/hududlar-disctrict/HududlarDistrictGraph'
import { NoPrint, Print } from 'react-easy-print'
import { parseParams } from '../../../../utils/url'
import format from 'date-fns/format'

const Header = styled(DisplayFlex)`
  align-items: center;
  justify-content: space-between;
  @media ${mediaQueries.laptopS}{
    display: flex;
    margin-left: 0px;
    flex-flow: column nowrap;
    margin-bottom: 0px;
    align-items: flex-start;
    > div:not(:last-child){
      margin-bottom: 4px;
    }
  }
`

const StyledTitle = styled(Title)`
  margin-bottom: 5px;
  margin-right: 25px;
`

const initialSwitcher = 'table'

const HududlarDistrict = props => {
  const { list, filterActions, goTerritory, dailyStats, setDailyStats, history, gatewayList } = props

  const [switcher, setSwitcher] = React.useState(initialSwitcher)

  const region = storageData('region').getValue()
  const regionId = prop('id', region)
  const regionName = prop('name', region)

  const params = parseParams(props.location.search)
  const datePrint = prop('date', params) ? format(new Date(prop('date', params)), 'MM.dd.yyyy') : format(new Date(), 'MM.dd.yyyy')

  const district = storageData('district').getValue()
  const districtName = prop('name', district)

  const listData = prop('results', list)
  const listLoading = prop('loading', list)

  return (
    <div>
      <NoPrint>
        <Header>
          <StyledTitle>{districtName} бўйича {window.renderDate} йил пахта ҳосилини қабул қилиш мониторинги</StyledTitle>
        </Header>
      </NoPrint>
      <Print
        printOnly={true}
      >
        <Header>
          <Title print={true}>{districtName} бўйича {datePrint} кунига пахта ҳосилини қабул қилиш мониторинги</Title>
        </Header>
      </Print>

      {switcher === 'table' &&
      <Box>
        {listLoading
          ? <Loader />
          : listData
            ? <HududlarDistrictTable listData={listData} goTerritory={goTerritory} />
            : <div>Проверьте соединение с интернетом</div>}
        <NoPrint>
          <Breadcrumb>
            <BreadcrumbItem to={ROUTES.HUDUDLAR_LIST_PATH}>Худудлар</BreadcrumbItem>
            <BreadcrumbItem to={sprintf(ROUTES.HUDUDLAR_REGION_URL, regionId)}>{regionName}</BreadcrumbItem>
            <BreadcrumbItem>{districtName}</BreadcrumbItem>
          </Breadcrumb>
        </NoPrint>
        <TableFilter
          excelParam={{
            fileName: `Ҳудудлар бўйича_district_${datePrint}`,
            tableId: 'withExcel',
            excelTableName: `${districtName} бўйича ${datePrint} кунига пахта ҳосилини қабул қилиш мониторинги`
          }}
          history={history}
          {...filterActions}
          gatewayList={gatewayList}
          initialSwitcher={initialSwitcher}
          setSwitcher={setSwitcher}
        />
      </Box>}

      {switcher === 'graph' &&
      <Box>
        {listLoading
          ? <Loader />
          : listData
            ? (
              <HududlarDistrictGraph
                listData={listData}
                dailyStats={dailyStats}
              />
            )
            : <div>Проверьте соединение с интернетом</div>}
        <Breadcrumb>
          <BreadcrumbItem to={ROUTES.HUDUDLAR_LIST_PATH}>Худудлар</BreadcrumbItem>
          <BreadcrumbItem to={sprintf(ROUTES.HUDUDLAR_REGION_URL, regionId)}>{regionName}</BreadcrumbItem>
          <BreadcrumbItem>{districtName}</BreadcrumbItem>
        </Breadcrumb>
        <Filter>
          <GraphFilter
            {...filterActions}
            dailyStats={dailyStats}
            setDailyStats={setDailyStats}
            switcher={switcher}
            setSwitcher={setSwitcher}
          />
        </Filter>
      </Box>}
    </div>
  )
}

HududlarDistrict.propTypes = {
  list: PropTypes.object.isRequired,
  goTerritory: PropTypes.func.isRequired,
  filterActions: PropTypes.object.isRequired,
  gatewayList: PropTypes.object,
  location: PropTypes.object,
  dailyStats: PropTypes.bool.isRequired,
  setDailyStats: PropTypes.func.isRequired,
  history: PropTypes.object
}

export default HududlarDistrict
