import { is, prop, reverse } from 'ramda'

export default function addChildTitleForTableExcel (doc, childParent, children) {
  let childNode = null
  let childNodeParent = null
  let childTextNode = null
  if (is(Array, children)) {
    // eslint-disable-next-line array-callback-return
    return reverse(children).map((element) => {
      const childText = prop('childText', element)
      const parent = prop('parent', element)
      childNodeParent = doc.createElement(parent)
      const tag = prop('tag', element)
      const attributes = prop('attributes', element)
      childNode = doc.createElement(tag)
      if (childText) {
        childTextNode = doc.createTextNode(childText)
        childNode.appendChild(childTextNode)
      }
      if (attributes) {
        for (var att in attributes) {
          childNode.setAttribute(att, attributes[att])
        }
      }
      childNodeParent.insertAdjacentElement('beforeend', childNode)
      childParent.insertAdjacentElement('afterbegin', childNodeParent)
    })
  }
  return null
}
