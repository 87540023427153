import { compose, mapPropsStream } from 'recompose'
import { withFetchList, withFilter, withRedirect } from 'components/HOCs'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import { korxonaListAction } from 'modules/korxona/actions'
import { DEFAULT_PICK_PARAMS, isEqualSearch } from 'utils/isEquals'
import { connect } from 'react-redux'
import { path, prop } from 'ramda'
import { distinctUntilChanged } from 'rxjs/operators'
import { getawayListAction } from '../../modules/home/actions'
import KorxonaCluster from 'modules/korxona/components/korxona-cluster'
import { getListParams } from 'utils/get'

const tablePickParams = [...DEFAULT_PICK_PARAMS, 'date', 'category', 'gateway']
export default compose(
  connect((state) => {
    const gatewayList = prop('gatewayList', state)
    return {
      gatewayList
    }
  }),
  withFetchList({
    stateName: STATES.ORGANISATION_LIST,
    action: korxonaListAction,
    withInterval: true,
    pickParams: tablePickParams,
    mapper: (props, key) => {
      const defaultListParams = getListParams(props, key)
      return {
        ...defaultListParams,
        id: path(['match', 'params', 'id'], props),
        type: 'region'
      }
    }
  }),
  withFilter({ fields: ['date', 'category', 'gateway'] }),
  withRedirect({
    key: 'goOrg',
    redirectUrl: ROUTES.KORXONA_ORGANISATION_URL,
    withCurrentParams: true
  }),
  mapPropsStream(props$ => {
    props$
      .pipe(
        distinctUntilChanged(
          isEqualSearch(tablePickParams),
          path(['history', 'location', 'search'])
        )
      )
      .subscribe(props => {
        props.dispatch(getawayListAction())
      })
    return props$
  })
)(KorxonaCluster)
