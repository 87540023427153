import React from 'react'
import { zonedTimeToUtc } from 'date-fns-tz'
import InputLabel from '../InputLabel/InputLabel'
import styled from 'styled-components'
import { Clock } from 'react-feather'

function checkTime (i) {
  if (i < 10) {
    i = '0' + i
  }
  return i
}

const Wrapper = styled('div')`
  z-index: 2;
  padding-right: 20px;
`

const InputContainer = styled('div')`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  h1 {
    margin: 0 0 0 10px;
    font-size: 16px;
    color: white;
    font-weight: 400;
  }
`

const StyledIcon = styled('span')`
  svg{
    color: #bec4d6;
    transform: translateY(12%);
    pointer-events: none;
    z-index: 1;
  }
`

export default class ClockTime extends React.Component {
  constructor (...args) {
    super(...args)
    this.state = {
      date: ''
    }
  }

  startTime () {
    const today = zonedTimeToUtc(new Date(), 'Asia/Tashkent')
    const h = today.getHours()
    const m = checkTime(today.getMinutes())
    const s = checkTime(today.getSeconds())

    this.setState({ date: h + ':' + m + ':' + s })
    this.timeout = setTimeout(() => this.startTime(), 500)
  }

  componentDidMount () {
    this.startTime()
  }

  componentWillUnmount () {
    if (!this.timeout) return
    clearTimeout(this.timeout)
  }

  render () {
    return (
      <Wrapper>
        <InputLabel />
        <InputContainer>
          <StyledIcon>
            <Clock size={18} />
          </StyledIcon>
          <h1>{this.state.date}</h1>
        </InputContainer>
      </Wrapper>
    )
  }
}
