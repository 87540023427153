import styled from 'styled-components'

export const Checkbox = styled('input')`
  position: relative;
  top: -0.375rem;
  margin: 0 1rem 0 0 ;
  cursor: pointer;

:before {
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  top: -2px;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border: 2px solid #f2f2f2;
  }

:checked {
  :before {
  transform: rotate(-45deg);
  height: .5rem;
  border-color: #009688;
  border-top-style: none;
  border-right-style: none;
    }
  }

:after {
  content: "";
  position: absolute;
  top: -0.125rem;
  left: 0;
  width: 1.2rem;
  height: 1.1rem;
  background: #fff;
  cursor: pointer;
  }
`
