import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'ramda/es/prop'
import { User, Lock } from 'react-feather'
import hexToRgb from 'utils/hexToRgb'
import Logo from 'assets/logo.png'
import Button from 'components/Button'
import { IconInput } from 'components/FormFields/Input'
import SmallLoader from 'components/Loader/SmallLoader'

const Wrapper = styled('div')`
  align-items: center;
  padding: 10px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
`

const Form = styled('form')`
  max-width: 325px;
  width: 100%;
`

const LogoWrap = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto 35px;
  h1{
    display: inline-block;
    margin: 5px 0 0;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: .5px;
    max-width: 200px;
    color: #00528a;
    text-transform: uppercase;
  }
`

const LogoImage = styled('img')`
  display: block;
  pointer-events: none;
`

const Field = styled('div')`
  margin-bottom: 20px;
`

const SubmitButton = styled(Button)`
  background-color: ${props => props.theme.palette.primaryColor};
  color: white;
  font-weight: 500;
  display: block;
  height: 46px;
  margin-top: 30px;
  width: 100%;
`

const Error = styled('div')`
  background-color: ${props => hexToRgb(props.theme.palette.redColor, '0.2')};
  border-radius: 5px;
  color: ${props => props.theme.palette.redColor};
  margin-bottom: 15px;
  padding: 5px 15px;
`

const initialState = { username: '', password: '' }
const reducer = (state, action) => ({ ...state, ...action })

const SignIn = props => {
  const { auth, onSubmit } = props

  const [values, dispatch] = useReducer(reducer, initialState)

  const onSubmitForm = event => {
    event.preventDefault()
    onSubmit(values)
  }

  const authLoading = prop('loading', auth)
  const authFailed = prop('failed', auth)
  return (
    <Wrapper>
      <Form onSubmit={onSubmitForm}>
        <LogoWrap>
          <LogoImage src={Logo} />
          <h1>Агролайф Пахта Мониторинг</h1>
        </LogoWrap>
        {authFailed &&
        <Error>Логин ёки парол хато терилган</Error>}
        <Field>
          <IconInput
            icon={User}
            type={'text'}
            placeholder={'Логин'}
            value={values.username}
            onChange={event => dispatch({ username: event.target.value })}
          />
        </Field>
        <Field>
          <IconInput
            icon={Lock}
            type={'password'}
            placeholder={'Пароль'}
            value={values.password}
            onChange={event => dispatch({ password: event.target.value })}
          />
        </Field>
        {authLoading ? (
          <SmallLoader />
        ) : (
          <SubmitButton disabled={authLoading}>Кириш</SubmitButton>
        )}
      </Form>
    </Wrapper>
  )
}

SignIn.propTypes = {
  auth: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default SignIn
