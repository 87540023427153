export const AUTH = 'AUTH'

export const CONTRACT_LIST = 'CONTRACT_LIST'
export const PROVISION_LIST = 'PROVISION_LIST'

export const ORGANISATION_LIST = 'ORGANISATION_LIST'

export const ADMIN_LIST = 'ADMIN_LIST'
export const ADMIN_LOGS = 'ADMIN_LOGS'
export const GATEWAY_LIST = 'GATEWAY_LIST'

export const CHECK_PROVISION_LIST = 'CHECK_PROVISION_LIST'
export const DOUBLE_LOGS = 'DOUBLE_LOGS'
export const CHECK_PHOTO = 'CHECK_PHOTO'
export const DOUBLE_WEIGHT_LIST = 'DOUBLE_WEIGHT_LIST'
export const PK_SEVENTEEN_CREATE = 'PK_SEVENTEEN_CREATE'

export const PK_SEVENTEEN_LIST = 'PK_SEVENTEEN_LIST'
export const PK_SEVENTEEN_ENTER_FORM = 'PK_SEVENTEEN_ENTER_FORM'
