import axios, { getPayloadFromError, getPayloadFromSuccess } from 'utils/axios'
import { filter, map, prop } from 'ramda'
import * as actionTypes from 'constants/actionTypes'
import * as API from 'constants/api'
import toBoolean from '../../../utils/toBoolean'

export const territoryProvisionListAction = (params, url, token, weightParam) => {
  const defaultParams = {
    page_size: params.page_size || 10,
    search: params.search,
    page: params.page,
    ag_given: toBoolean(params.ag_given),
    date: params.date || null,
    ...weightParam
  }
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState, newToken: token })
      .get(`${url}${API.PK_SEVENTEEN_LIST}`, { params: defaultParams })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.PK_SEVENTEEN_LIST,
      payload
    })
  }
}

export const PKSeventeenCheckedFieldAction = (values, url, token) => {
  const check = filter((o) => o.value === true, values)
  const requestData = {
    ids: map((obj) => obj.id, check)
  }
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState, newToken: token })
      .post(`${url}${API.PK_SEVENTEEN_CREATE}`, requestData)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.PK_SEVENTEEN_ENTER_FORM,
      payload
    })
  }
}

export const PKSeventeenWeightListAction = (param, url, newToken) => {
  const regionId = prop('regionId', param) || null
  const districtId = prop('districtId', param) || null
  const territoryId = prop('territoryId', param) || null
  const contractorId = prop('contractorId', param) || null
  const defaultParams = { contractor_id: contractorId, territory_id: territoryId, district_id: districtId, region_id: regionId }

  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState, newToken })
      .get(`${url}${API.PK_SEVENTEEN_CREATE}`, { params: defaultParams })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.PK_SEVENTEEN_CREATE,
      payload
    })
  }
}
