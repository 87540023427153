import { prop } from 'ramda'
import React from 'react'
import numberFormat from 'utils/numberFormat'
import { storageData } from 'utils/storage'
import {
  Table,
  TableContainer,
  TableHeader,
  TableBody,
  TableRow,
  TableCol,
  TableFooterRow
} from 'components/Table'
import sumBy from '../../../../utils/sumBy'
import PropTypes from 'prop-types'

const KorxonaClusterTable = props => {
  const { listData, goOrg } = props

  const onRedirect = (id, name) => {
    goOrg(id)
    storageData('org').setValue({ id, name })
  }
  const totalStationCount = sumBy(listData, ['stationCount'])

  const totalWeightNetToday = sumBy(listData, ['weightNetToday'])
  const totalWeightCondToday = sumBy(listData, ['weightCondToday'])
  const totalDiscountSumTodayRebate = sumBy(listData, ['discountSumTodayRebate'])
  const totalDiscountSumTodayCape = sumBy(listData, ['discountSumTodayCape'])

  const totalWeightNetSeason = sumBy(listData, ['weightNetSeason'])
  const totalWeightCondSeason = sumBy(listData, ['weightCondSeason'])
  const totalDiscountSumSeasonRebate = sumBy(listData, ['discountSumSeasonRebate'])
  const totalDiscountSumSeasonCape = sumBy(listData, ['discountSumSeasonCape'])

  return (
    <TableContainer>
      <Table>
        <TableHeader tableArray={listData}>
          <TableRow tableArray={listData}>
            <TableCol rowSpan={2} align={'center'}>№</TableCol>
            <TableCol rowSpan={2} align={'center'}>Ҳудудлар</TableCol>
            <TableCol rowSpan={2} align={'center'}>Қабул маскани номи</TableCol>
            <TableCol colSpan={4} align={'center'}>Бир кунда, тн</TableCol>
            <TableCol colSpan={4} align={'center'}>Мавсум бошида, тн</TableCol>
            <TableCol rowSpan={2} align={'center'}>Узилиш (кун:соат:дақиқа)</TableCol>
          </TableRow>
          <TableRow tableArray={listData}>
            <TableCol align={'center'}>физик вазнда</TableCol>
            <TableCol align={'center'}>конд. вазнда</TableCol>
            <TableCol align={'center'}>скидка</TableCol>
            <TableCol align={'center'}>накидка</TableCol>

            <TableCol align={'center'}>физик вазнда</TableCol>
            <TableCol align={'center'}>конд. вазнда</TableCol>
            <TableCol align={'center'}>скидка</TableCol>
            <TableCol align={'center'}>накидка</TableCol>
          </TableRow>
        </TableHeader>
        <TableBody>
          {listData.map((item, index) => {
            const key = index + 1
            const id = prop('id', item)
            const region = prop('name', item)
            const stationCount = prop('stationCount', item)

            const weightNetToday = prop('weightNetToday', item)
            const weightCondToday = prop('weightCondToday', item)
            const discountSumTodayRebate = prop('discountSumTodayRebate', item)
            const discountSumTodayCape = prop('discountSumTodayCape', item)

            const weightNetSeason = prop('weightNetSeason', item)
            const weightCondSeason = prop('weightCondSeason', item)
            const discountSumSeasonRebate = prop('discountSumSeasonRebate', item)
            const discountSumSeasonCape = prop('discountSumSeasonCape', item)

            const lastProvision = prop('lastProvision', item)
            return (
              <TableRow key={id} onClick={() => onRedirect(id, region)} tableArray={listData}>
                <TableCol align={'center'} width={'3.9%'}>{key}</TableCol>
                <TableCol align={'left'} bold={true} width={'13.2%'}>{region}</TableCol>
                <TableCol align={'center'} width={'8.3%'}>{numberFormat(stationCount)}</TableCol>

                <TableCol align={'center'} selected={true} width={'8.2%'}>{numberFormat(weightNetToday)}</TableCol>
                <TableCol align={'center'} selected={true} width={'6.5%'}>{numberFormat(weightCondToday)}</TableCol>
                <TableCol align={'center'} selected={true} width={'8.3%'}>{numberFormat(discountSumTodayRebate)}</TableCol>
                <TableCol align={'center'} selected={true} width={'6.5%'}>{numberFormat(discountSumTodayCape)}</TableCol>

                <TableCol align={'center'} width={'8.3%'}>{numberFormat(weightNetSeason)}</TableCol>
                <TableCol align={'center'} width={'8.3%'}>{numberFormat(weightCondSeason)}</TableCol>
                <TableCol align={'center'} width={'8.3%'}>{numberFormat(discountSumSeasonRebate)}</TableCol>
                <TableCol align={'center'} width={'8.3%'}>{numberFormat(discountSumSeasonCape)}</TableCol>

                <TableCol align={'center'} width={'12.4%'}>{lastProvision}</TableCol>
              </TableRow>
            )
          })}

          <TableFooterRow tableArray={listData}>
            <TableCol align={'left'} colSpan={2}>Жами:</TableCol>
            <TableCol align={'center'}>{numberFormat(totalStationCount)}</TableCol>

            <TableCol align={'center'}>{numberFormat(totalWeightNetToday)}</TableCol>
            <TableCol align={'center'}>{numberFormat(totalWeightCondToday)}</TableCol>
            <TableCol align={'center'}>{numberFormat(totalDiscountSumTodayRebate)}</TableCol>
            <TableCol align={'center'}>{numberFormat(totalDiscountSumTodayCape)}</TableCol>

            <TableCol align={'center'}>{numberFormat(totalWeightNetSeason)}</TableCol>
            <TableCol align={'center'}>{numberFormat(totalWeightCondSeason)}</TableCol>
            <TableCol align={'center'}>{numberFormat(totalDiscountSumSeasonRebate)}</TableCol>
            <TableCol align={'center'}>{numberFormat(totalDiscountSumSeasonCape)}</TableCol>

            <TableCol align={'center'}> </TableCol>
          </TableFooterRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

KorxonaClusterTable.propTypes = {
  listData: PropTypes.array.isRequired,
  goOrg: PropTypes.func.isRequired
}

export default KorxonaClusterTable
