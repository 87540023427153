export const ROOT_PATH = '/'
export const SIGN_IN_PATH = '/sign-in/'

const HUDUDLAR = 'hududlar'
export const HUDUDLAR_LIST_PATH = `/${HUDUDLAR}/`
export const HUDUDLAR_REGION_PATH = `/${HUDUDLAR}/region/:id/`
export const HUDUDLAR_REGION_URL = `/${HUDUDLAR}/region/%s/`
export const HUDUDLAR_DISTRICT_PATH = `/${HUDUDLAR}/region/district/:id/`
export const HUDUDLAR_DISTRICT_URL = `/${HUDUDLAR}/region/district/%s/`
export const HUDUDLAR_TERRITORY_URL = `/${HUDUDLAR}/region/district/territory/%s/`
export const HUDUDLAR_TERRITORY_PATH = `/${HUDUDLAR}/region/district/territory/:id/`
export const HUDUDLAR_PROVISION_URL = `/${HUDUDLAR}/region/district/territory/provision/%s/`
export const HUDUDLAR_PROVISION_PATH = `/${HUDUDLAR}/region/district/territory/provision/:id/`

const KORXONA = 'korxona'
export const KORXONA_LIST_PATH = `/${KORXONA}/`
export const KORXONA_CLUSTER_PATH = `/${KORXONA}/cluster/:id/`
export const KORXONA_CLUSTER_URL = `/${KORXONA}/cluster/%s/`
export const KORXONA_ORGANISATION_PATH = `/${KORXONA}/cluster/org/:id/`
export const KORXONA_ORGANISATION_URL = `/${KORXONA}/cluster/org/%s/`
export const KORXONA_STATION_PATH = `/${KORXONA}/cluster/org/station/:id/`
export const KORXONA_STATION_URL = `/${KORXONA}/cluster/org/station/%s/`
export const KORXONA_TERRITORY_PATH = `/${KORXONA}/cluster/org/station/territory/:id/`
export const KORXONA_TERRITORY_URL = `/${KORXONA}/cluster/org/station/territory/%s/`

export const KORXONA_CONTRACT_PATH = `/${KORXONA}/org/station/contract/:id/`
export const KORXONA_CONTRACT_URL = `/${KORXONA}/org/station/contract/%s/`
export const KORXONA_INN_PATH = `/${KORXONA}/org/station/contract/inn/:id/`
export const KORXONA_INN_URL = `/${KORXONA}/org/station/contract/inn/%s/`

const FERMER = 'fermer'
export const FERMER_LIST_PATH = `/${FERMER}/`
export const FERMER_REGION_PATH = `/${FERMER}/region/:id/`
export const FERMER_REGION_URL = `/${FERMER}/region/%s/`
export const FERMER_DISTRICT_PATH = `/${FERMER}/region/district/:id/`
export const FERMER_DISTRICT_URL = `/${FERMER}/region/district/%s/`
export const FERMER_TERRITORY_URL = `/${FERMER}/region/district/territory/%s/`
export const FERMER_TERRITORY_PATH = `/${FERMER}/region/district/territory/:id/`
export const FERMER_PROVISION_URL = `/${FERMER}/region/district/territory/provision/%s/`
export const FERMER_PROVISION_PATH = `/${FERMER}/region/district/territory/provision/:id/`

const ADMIN = 'admin'
export const ADMIN_LIST_PATH = `/${ADMIN}/`
export const ADMIN_REGION_PATH = `/${ADMIN}/org/:id/`

const DOUBLE = 'double'
export const DOUBLE_LIST_PATH = `/${DOUBLE}/`
export const DOUBLE_REGION_PATH = `/${DOUBLE}/org/:id/`

const PK_SEVENTEEN = 'pk-seventeen'
export const PK_SEVENTEEN_LIST_PATH = `/${PK_SEVENTEEN}/`
export const PK_SEVENTEEN_REGION_PATH = `/${PK_SEVENTEEN}/org/:id/`
