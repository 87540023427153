import styled from 'styled-components'

export default styled('button')`
  align-items: center;
  background-color: #fafbfd;
  border: 1px solid rgba(46, 56, 76, 0.37);
  border-radius: 5px;
  color: ${props => props.theme.input.labelColor};
  cursor: pointer;
  font-size: 14px;
  height: 38px;
  outline: none;
  padding: 0 10px;
  transition: ${props => props.theme.transition};
  &:hover {
    background-color: ${props => props.theme.input.borderColor};
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`
