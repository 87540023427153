import { curry, path, pathOr, compose, filter, pick, pipe, prop } from 'ramda'
import { parseParams } from './url'
import toSnakeCase from './toSnakeCase'

export const getParamsFormHistory = compose(
  parseParams,
  path(['location', 'search'])
)

export const getDataFromState = curry((name, state) => ({
  loading: path([name, 'loading'], state),
  failed: path([name, 'failed'], state),
  data: path([name, 'data'], state),
  results: pathOr([], [name, 'data', 'results'], state)
}))

export const getInitValuesFromHistory = curry((fields, history) =>
  compose(
    filter(Boolean),
    pick(fields),
    parseParams,
    path(['location', 'search'])
  )(history)
)

export const getListParams = (props, keys) =>
  pipe(
    prop('history'),
    getParamsFormHistory,
    pick(keys),
    toSnakeCase
  )(props)
