import styled from 'styled-components'
import TableRow from './TableRow'
import TableCol from './TableCol'
import { mediaQueries } from 'constants/mediaQueries'
import rowHeight from '../../utils/rowHeight'

export default styled(TableRow)`
  background-color: ${({ theme }) => theme.table.backgroundColor} !important;
  font-weight: 600;
  & ${TableCol} {
    border: ${props => props.theme.table.border};
    font-weight: 600;
  }
  page-break-before: avoid;

  @media ${mediaQueries.desktop}{
    & td {
      height: ${({ tableArray }) => rowHeight(mediaQueries.desktop, tableArray)};
      padding: 5px 8px;
    }
  }
  @media ${mediaQueries.minLaptopL}{
    & td {
      height: ${({ tableArray }) => rowHeight(mediaQueries.minLaptopL, tableArray)};
      padding: 5px 8px;
    }
  }
    @media ${mediaQueries.laptopL}{
    & td {
      height: ${({ tableArray }) => rowHeight(mediaQueries.laptopL, tableArray)};
      padding: 3px 8px;
    }
  }
  @media ${mediaQueries.laptopS}{
    & td {
      height: ${({ tableArray }) => rowHeight(mediaQueries.laptopS, tableArray)};
      padding: 3px 8px;
    }
  }
  @media (max-width: 900px){
    & td {
      height: ${({ tableArray }) => rowHeight('900px', tableArray)};
      padding: 5px 8px;
    }
  }
    @media (max-width: 400px){
    & td {
      height: ${({ tableArray }) => rowHeight('400px', tableArray)};
      padding: 0 8px;
    }
  }
`
