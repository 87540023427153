import { map, pipe, sum, path } from 'ramda'
import numberFormat from 'utils/numberFormat'
import { round } from './round'
import toNumber from './toNumber'

export default (array, keyPath, formatted, precision) => {
  const sumBy = pipe(
    map((item) => {
      const value = path(keyPath, item)
      if (precision) {
        return round(toNumber(value), precision)
      }
      return value
    }),
    sum
  )(array)
  return formatted ? numberFormat(sumBy, precision) : sumBy
}
