import React from 'react'
import { CheckBox } from './CheckBox'
import { lifecycle, compose } from 'recompose'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

const enhanse = compose(
  lifecycle({
    componentDidMount () {
      const { options, changeArrayField } = this.props
      const arrayField = [...options]
      changeArrayField(arrayField)
    },
    componentWillUnmount () {
      const { changeArrayField, setAllSelect } = this.props
      const arrayField = []
      setAllSelect(false)
      changeArrayField(arrayField)
    }
  })
)
const CheckBoxArray = enhanse((props) => {
  const { arrayField, changeArrayField, allSelect } = props
  const handleCheckChieldElement = (value, keyObj) => {
    const newArray = arrayField.map((item, idx) => {
      return idx !== keyObj ? item : { ...item, value: value }
    })
    changeArrayField(newArray)
  }
  return (
    <React.Fragment>
      {
        arrayField.map((element, key) => {
          const value = prop('value', element) || false
          return (
            <CheckBox
              handleCheckChieldElement={handleCheckChieldElement}
              key={key}
              value={value}
              keyObj={key}
              renderItem={props.renderItem}
              allSelect={allSelect}
              {...element}
            />
          )
        })
      }
    </React.Fragment>
  )
})

CheckBox.propTypes = {
  arrayField: PropTypes.array,
  changeArrayField: PropTypes.func
}

export default CheckBoxArray
