import React from 'react'
import PropTypes from 'prop-types'
import { DatePicker, Select } from 'components/FormFields'
import Filter, { LeftFilters, RightFilters } from 'components/Filter'
import { path, prop } from 'ramda'
import { AlignLeft, Grid, Printer } from 'react-feather'
import ButtonPrint from 'components/ButtonPrint'
import ModalDownload from 'components/Modals'
import { parseParams } from 'utils/url'
import ModalResend from 'components/Modals/ModalResend/ModalResend'
import { storageData } from '../../../../utils/storage'
import { SwitchButton, Switcher } from 'components/Switcher'

const getDate = date => {
  if (date) return new Date(date)
  return new Date()
}

const TableFilter = ({ onChangeFilter, initialValues, history, initialSwitcher, setSwitcher, excelParam }) => {
  const weightNet = { value: 'weight_net', label: 'Физик' }
  const weightCond = { value: 'weight_cond', label: 'Кондиция' }

  // const gatewayArr = path(['data', 'results'], gatewayList) || [{}]
  // const gatewayLoad = prop('loading', gatewayList)
  // const gatewayOption = gatewayArr && map((item) => {
  //   const id = prop('id', item)
  //   const name = prop('name', item)
  //   return { value: id, label: name }
  // }, gatewayArr)

  const searchParams = path(['location', 'search'], history)
  const searchObj = parseParams(searchParams)
  const typeWeightParam = prop('typeWeight', searchObj)
  const isResend = prop('gateway', searchObj) || false
  const isSuperuser = storageData('isSuperuser').getValue() || false

  const defaultValue = (typeWeightParam === 'weight_net')
    ? weightNet
    : (typeWeightParam === 'weight_cond')
      ? weightCond
      : weightNet

  return (
    <Filter>
      <LeftFilters>
        <Switcher
          initialValue={initialSwitcher}
          onChange={value => setSwitcher(value)}
          justifyContent={'center'}
        >
          <SwitchButton value={'table'}><Grid /></SwitchButton>
          <SwitchButton value={'graph'}><AlignLeft /></SwitchButton>
        </Switcher>
        <DatePicker
          label={'Сана'}
          value={getDate(initialValues.date)}
          onChange={value => onChangeFilter({ date: value })}
        />
        <Select
          label={'Ўлчов тури'}
          value={getDate(initialValues.date)}
          defaultValue={defaultValue}
          options={[
            { value: 'weight_net', label: 'Физик' },
            { value: 'weight_cond', label: 'Кондиция' }
          ]}
          onChange={value => onChangeFilter({ typeWeight: value })}
          isClearable={false}
        />
        {/* <Select */}
        {/*  label={'Шлюз'} */}
        {/*  loading={gatewayLoad} */}
        {/*  value={+(initialValues.gateway)} */}
        {/*  options={gatewayLoad ? [{ label: 'Загрузка.....' }] : gatewayOption} */}
        {/*  onChange={value => onChangeFilter({ gateway: value })} */}
        {/*  isClearable={false} */}
        {/* /> */}
      </LeftFilters>
      <RightFilters>
        {isResend && isSuperuser && <ModalResend />}
        <ModalDownload excelParam={excelParam} />
        <ButtonPrint><Printer size={22} color={'#2E384C'} /></ButtonPrint>
      </RightFilters>
    </Filter>
  )
}

TableFilter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  initialSwitcher: PropTypes.string,
  setSwitcher: PropTypes.func,
  // gatewayList: PropTypes.object.isRequired,
  history: PropTypes.object,
  excelParam: PropTypes.object
}

export default TableFilter
