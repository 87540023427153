export const DOMAIN_URL = 'https://newapi.agrolife.uz'
export const API_URL = `${DOMAIN_URL}/api/v1/`

const MAIN = 'main'
export const SIGN_IN = `/${MAIN}/api-token-auth/`
export const PROVISION_LIST = `/${MAIN}/provision/`
export const CONTRACT_LIST = `/${MAIN}/farmer_stat/`

export const GET_CONTRACT_LIST = `/${MAIN}/contract/%s/get_contract/`

export const ORGANISATION_LIST = `/${MAIN}/org/`
export const KORXONA_REGION_LIST = `/${MAIN}/org_stat/`
export const TERRITORY_LIST = `/${MAIN}/org/%s/get_station/`
export const TERRITORY_PROVISION_LIST = `/${MAIN}/provision_list/`
export const GATEWAY_LIST = `/${MAIN}/gateway/`

export const ADMIN_LIST = `/${MAIN}/ping/`

export const PK_SEVENTEEN_LIST = '/api/v1/main/ui_provision_list/'
export const PK_SEVENTEEN_CREATE = '/api/v1/main/resend_provision/'

export const CHECK_PROVISION_LIST = `/${MAIN}/check_provision/`
export const CHECK_PHOTO_LIST = '/api/v1/main/check_photo/'
export const DOUBLE_WEIGHT_LIST = '/api/v1/main/recalc_check/'
