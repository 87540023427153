import React from 'react'
import { prop } from 'ramda'
import * as ROUTES from 'constants/routes'
import { storageData } from 'utils/storage'
import Box from 'components/Box'
import Title from 'components/Title'
import { Breadcrumb, BreadcrumbItem } from 'components/Breadcrumb'
import FermerDistrictTable from './FermerDistrictTable'
import styled from 'styled-components'
import { DisplayFlex } from '../../../../components/Styled'
import TableFilter from './TableFilter'
import PropTypes from 'prop-types'
import Loader from 'components/Loader/Loader'
import { sprintf } from 'sprintf-js'
import { mediaQueries } from '../../../../constants/mediaQueries'
import { NoPrint, Print } from 'react-easy-print'
import { parseParams } from 'utils/url'
import format from 'date-fns/format'

const Header = styled(DisplayFlex)`
  align-items: center;
  justify-content: space-between;
  @media ${mediaQueries.laptopS}{
    display: flex;
    margin-left: 0px;
    flex-flow: column nowrap;
    margin-bottom: 0px;
    align-items: flex-start;
    > div:not(:last-child){
      margin-bottom: 4px;
    }
  }
`

const FermerDistrict = props => {
  const { list, filterActions, goTerritory, gatewayList, history } = props

  const params = parseParams(props.location.search)
  const datePrint = prop('date', params) ? format(new Date(prop('date', params)), 'MM.dd.yyyy') : format(new Date(), 'MM.dd.yyyy')

  const region = storageData('region').getValue()
  const district = storageData('district').getValue()

  const regionName = prop('name', region)
  const regionId = prop('id', region)

  const districtName = prop('name', district)

  const listData = prop('data', list)
  const listLoading = prop('loading', list)

  return (
    <div>
      <NoPrint>
        <Header>
          <Title>{districtName} бўйича {window.renderDate} йил пахта ҳосилини қабул қилиш мониторинги</Title>
        </Header>
      </NoPrint>

      <Print
        printOnly={true}
      >
        <Header>
          <Title print={true}>{districtName} бўйича {datePrint} кунига пахта ҳосилини қабул қилиш мониторинги</Title>
        </Header>
      </Print>

      <Box>
        {listLoading
          ? <Loader />
          : listData
            ? <FermerDistrictTable listData={listData} goTerritory={goTerritory} />
            : <div>Проверьте соединение с интернетом</div>}
        <NoPrint>
          <Breadcrumb>
            <BreadcrumbItem to={ROUTES.FERMER_LIST_PATH}>Фермерлар</BreadcrumbItem>
            <BreadcrumbItem to={sprintf(ROUTES.FERMER_REGION_URL, regionId)}>{regionName}</BreadcrumbItem>
            <BreadcrumbItem>{districtName}</BreadcrumbItem>
          </Breadcrumb>
        </NoPrint>
        <TableFilter
          {...filterActions}
          gatewayList={gatewayList}
          history={history}
          excelParam={{
            fileName: `Фермерлар бўйича_district_${datePrint}`,
            tableId: 'withExcel',
            excelTableName: `${districtName} бўйича ${datePrint} кунига пахта ҳосилини қабул қилиш мониторинги`
          }}
        />
      </Box>
    </div>
  )
}

FermerDistrict.propTypes = {
  list: PropTypes.object.isRequired,
  goTerritory: PropTypes.func.isRequired,
  gatewayList: PropTypes.object.isRequired,
  location: PropTypes.object,
  history: PropTypes.object.isRequired,
  filterActions: PropTypes.object.isRequired
}

export default FermerDistrict
