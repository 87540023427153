import * as ROUTES from 'constants/routes'
import Layout, { BlankLayout } from 'components/Layouts'
import NotFound from 'modules/not-found'
import { SignInContainer } from 'containers/sign-in'
import {
  HududlarContainer,
  HududlarRegionContainer,
  HududlarDistrictContainer,
  HududlarTerritoryContainer,
  HududlarProvisionContainer
} from 'containers/hududlar'
import {
  KorxonaContainer,
  KorxonaClusterContainer,
  KorxonaOrganisationContainer,
  KorxonaStationContainer,
  KorxonaInnContainer,
  KorxonaContractContainer
} from 'containers/korxona'
import {
  FermerContainer,
  FermerDistrictContainer,
  FermerRegionContainer,
  FermerTerritoryContainer,
  FermerProvisionContainer
} from 'containers/fermer'
import { AdminContainer } from 'containers/admin'
import { DoubleContainer } from 'containers/double'
import { PKSeventeenContainer } from '../containers/PKSeventeen'

export default (isSuperuser) => {
  const defaultRoutes = [
    // SIGN IN
    {
      path: ROUTES.SIGN_IN_PATH,
      layout: BlankLayout,
      component: SignInContainer
    },
    // HUDUDLAR
    {
      path: ROUTES.HUDUDLAR_LIST_PATH,
      layout: Layout,
      component: HududlarContainer,
      routes: [
        {
          path: ROUTES.HUDUDLAR_REGION_PATH,
          layout: Layout,
          component: HududlarRegionContainer
        },
        {
          path: ROUTES.HUDUDLAR_DISTRICT_PATH,
          layout: Layout,
          component: HududlarDistrictContainer
        },
        {
          path: ROUTES.HUDUDLAR_TERRITORY_PATH,
          layout: Layout,
          component: HududlarTerritoryContainer
        },
        {
          path: ROUTES.HUDUDLAR_PROVISION_PATH,
          layout: Layout,
          component: HududlarProvisionContainer
        }
      ]
    },
    // KORXONA
    {
      path: ROUTES.KORXONA_LIST_PATH,
      layout: Layout,
      component: KorxonaContainer,
      routes: [
        {
          path: ROUTES.KORXONA_CLUSTER_PATH,
          layout: Layout,
          component: KorxonaClusterContainer
        },
        {
          path: ROUTES.KORXONA_ORGANISATION_PATH,
          layout: Layout,
          component: KorxonaOrganisationContainer
        },
        {
          path: ROUTES.KORXONA_STATION_PATH,
          layout: Layout,
          component: KorxonaStationContainer
        },
        {
          path: ROUTES.KORXONA_CONTRACT_PATH,
          layout: Layout,
          component: KorxonaContractContainer
        },
        {
          path: ROUTES.KORXONA_INN_PATH,
          layout: Layout,
          component: KorxonaInnContainer
        }
      ]
    },
    {
      path: ROUTES.FERMER_LIST_PATH,
      layout: Layout,
      component: FermerContainer,
      routes: [
        {
          path: ROUTES.FERMER_REGION_PATH,
          layout: Layout,
          component: FermerRegionContainer
        },
        {
          path: ROUTES.FERMER_DISTRICT_PATH,
          layout: Layout,
          component: FermerDistrictContainer
        },
        {
          path: ROUTES.FERMER_TERRITORY_PATH,
          layout: Layout,
          component: FermerTerritoryContainer
        },
        {
          path: ROUTES.FERMER_PROVISION_PATH,
          layout: Layout,
          component: FermerProvisionContainer
        }
      ]
    },
    // NOT FOUND
    {
      path: '*',
      layout: Layout,
      component: NotFound
    }
  ]
  const adminRoutes = [
    {
      path: ROUTES.ADMIN_LIST_PATH,
      layout: Layout,
      component: AdminContainer
    },
    {
      path: ROUTES.DOUBLE_LIST_PATH,
      layout: Layout,
      component: DoubleContainer
    },
    {
      path: ROUTES.PK_SEVENTEEN_LIST_PATH,
      layout: Layout,
      component: PKSeventeenContainer
    }
  ]
  return isSuperuser ? [...adminRoutes, ...defaultRoutes] : [...adminRoutes, ...defaultRoutes]
}
