import React from 'react'
import { Download, Send } from 'react-feather/dist/index'
import Button from 'components/Button/Button'
import styled from 'styled-components'
import hexToRgba from 'utils/hexToRgb'
import CheckBoxOnOff from '../../FormFields/Checkbox/OnCheckBox'
import { compose, withHandlers, withState } from 'recompose'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { find, path, prop, propEq } from 'ramda'
import { appendParamsToUrl, parseParams } from '../../../utils/url'
import toBoolean from '../../../utils/toBoolean'
import { ResendAction } from './ResendAction'
import snackbar from '../../Snackbar'
import Loader from '../../Loader/Loader'

const BGMusk = styled('div')`
  background-color: ${hexToRgba('#000000', '0.18')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({ isOpen }) => isOpen ? '1' : '0'};
  transition: all .2s ease-out;
  visibility: ${({ isOpen }) => isOpen ? 'visible' : 'hidden'};
  z-index: 90;
  margin: 0 !important;
`
const ModalContent = styled('div')`
  margin: 0 !important;
  background: white;
  border-radius: 5px;
  padding: 30px 25px;
  box-shadow: ${({ isOpen }) => isOpen ? '-5px 0 15px rgba(0, 0, 0, 0.05)' : 'none'};
  display: ${({ isOpen }) => isOpen ? 'flex' : 'none'};
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  right: 0;
  transition: all .2s ease-out;
  transform: translateX(-50%) translateY(-50%);
  max-width: 375px;
  width: 90%;
  z-index: 1000;
`
const Title = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 20px;
  h2{
    color: #273d56;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
  }
  p {
    line-height: 1.4;
    color: #273D56;
    font-weight: 400;
    font-size: 14px;
    
  }
  a {
    border: none;
    color: #6D73C0;
    font-weight: 600;
    font-size: 15px;
    text-decoration: none;
  }
`

const DownloadButton = styled(Button)`
  display: flex;
  justify-content: center;
  margin-top: 18px;
`

const enhance = compose(
  withRouter,
  connect((state) => {
    const gatewayList = prop('gatewayList', state)
    const PKSeventeenCreate = prop('PKSeventeenCreate', state)
    return {
      gatewayList,
      PKSeventeenCreate
    }
  }),
  withState('modalIsOpen', 'setModalIsOpen', false),
  withHandlers({
    resendAction: props => (url, newToken) => {
      const { dispatch, location, match, setModalIsOpen } = props
      const id = path(['params', 'id'], match)
      const params = parseParams(location.search)
      const matchUrl = prop('path', match)
      return dispatch(ResendAction(url, newToken, params, id, matchUrl))
        .then(() => {
          return dispatch(snackbar({ label:'Данные успешно отправлены' }))
        })
        .then(() => {
          setModalIsOpen(false)
        })
        .catch((e) => {
          return dispatch(snackbar({ label:'Ошибка !', timeout: 10000 }))
        })
    }
  })
)

const ModalResend = enhance((props) => {
  const { history, resendAction, location, gatewayList, modalIsOpen, setModalIsOpen, PKSeventeenCreate } = props
  const loading = prop('loading', PKSeventeenCreate)
  const params = parseParams(location.search)
  const onChange = (value) => {
    history.push({ search: appendParamsToUrl({ VPN: value }, path(['history', 'location', 'search'], props)) })
  }
  const value = toBoolean(params.VPN) || false
  const gatewayId = +(prop('gateway', params)) || null
  const arr = path(['data', 'results'], gatewayList) || []
  const findElement = find(propEq('id', gatewayId))(arr)
  const token = prop('token', findElement)
  const url = value ? prop('ipAddress', findElement) : prop('ipExternal', findElement)
  const onModalOpen = () => setModalIsOpen(true)
  const onModalClose = () => setModalIsOpen(false)

  React.useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style = null
    }
  }, [modalIsOpen])

  return (
    <>
      <Button onClick={onModalOpen}>
        <Send size={22} color={'#929eac'} /> Перенаправить
      </Button>
      <BGMusk isOpen={modalIsOpen} onClick={onModalClose} />
      <ModalContent isOpen={modalIsOpen}>
        <Title>
          <h2>Перенаправление</h2>
          <p>"Iltimos kutib turing har bir ma'lumotni tm polyasi yangilanmoqda.
            <a href="http://paxta.uz/">Paxta.uz</a> sayti har 5 daqiqada 1000ta ma'lumotni oladi"
          </p>
        </Title>
        <CheckBoxOnOff
          title={'VPN:'}
          value={value}
          onChange={(value => onChange(value))}
        />
        {loading ? <Loader /> : (
          <DownloadButton onClick={() => { resendAction(url, token) }}>
            <Download size={16} style={{ marginRight: '7px' }} /> Перенаправить
          </DownloadButton>
        )}
      </ModalContent>
    </>
  )
})

export default ModalResend
