import React from 'react'

const NotFound = props => {
  return (
    <div>
      Page not found 404
    </div>
  )
}

NotFound.propTypes = {}

export default NotFound
