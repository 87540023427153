import axios from 'axios'
import { prop, compose, path, curry, equals } from 'ramda'
import { API_URL } from 'constants/api'
import { AUTH } from 'constants/actionTypes'
import { parseParams } from 'utils/url'
import { storageData } from './storage'
import responseToCamelCase from './responseToCamelCase'

export const getPayloadFromSuccess = prop('data')

export const getPayloadFromError = compose(
  data => Promise.reject(data),
  path(['response', 'data'])
)

const UNAUTHORIZED = 401
const axiosConfig = apiHost => {
  apiHost && storageData('server').setValue(apiHost)
  const getServer = storageData('server').getValue()
  const isApiHost = apiHost || getServer
  const apiHostReplaced = isApiHost && isApiHost.replace(/\s/g, '')
  const finalApiHost =
    apiHostReplaced &&
    apiHostReplaced.slice(0, 4) === 'http'
      ? apiHostReplaced
      : `${'https://' + apiHostReplaced + '.ritm.uz'}`
  const api = `${finalApiHost}/api/v1/`
  const formatedApi = api.toLowerCase()

  return {
    baseURL: isApiHost ? formatedApi : API_URL,
    transformResponse: [responseToCamelCase]
  }
}

const errorInterceptors = curry((dispatch, error) => {
  const status = path(['response', 'status'], error)

  if (equals(UNAUTHORIZED, status)) {
    dispatch({ type: `${AUTH}_CLEAR` })
  }

  return Promise.reject(error)
})

export default ({ dispatch, getState, withToken = true, newToken }) => {
  const state = getState()
  const search = window.location.search
  const queryParams = parseParams(search)
  const apiHost = prop('apiHost', queryParams)

  const token = path(['auth', 'data', 'token'], state) || storageData('token').getValue()
  const headers = {
    common: {
      Authorization: newToken
        ? `Token ${newToken}`
        : (token && withToken)
          ? `Token ${token}`
          : ''
    }
  }
  const instance = axios.create({ ...axiosConfig(apiHost), headers })

  instance.interceptors.response.use(
    response => response,
    errorInterceptors(dispatch)
  )

  return instance
}
