import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose, withHandlers } from 'recompose'
import { path, prop } from 'ramda'
import styled from 'styled-components'
import { ChevronLeft, ChevronRight } from 'react-feather'
import Button from '../Button'
import { appendParamsToUrl, parseParams } from '../../utils/url'
import toNumber from '../../utils/toNumber'
import { Select } from '../FormFields'

const Nav = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
`

const Navigation = styled('div')`
    margin-right: 13px;
`

const NavButtons = styled('div')`
    display: grid;
    grid-gap: 15px;
    grid-auto-flow: column;
`

const PageSize = styled('div')`
    width: 95px;
    margin-right: 13px;
    ;
    > div {
      min-width: 95px !important;
      width: 95px !important;
    }
`

const NavButton = styled(Button)`
  border-radius: 300px;
  svg {
    width: 24px;
    height: 24px;
  };
  padding: 0 3px;
  height: 30px;
  width: 30px;
`

const enhance = compose(
  withRouter,
  withHandlers({

  })
)

const Pagination = enhance((props) => {
  const { data, newKeys = {}, location, history } = props
  const params = parseParams(location.search)
  const value = +(prop('page_size', params)) || 10
  const getKey = (key) => {
    const newItemKey = prop(key, newKeys)
    if (newItemKey) {
      return newItemKey
    }
    return key
  }

  const onChange = (value) => {
    history.push({ search: appendParamsToUrl({ [getKey('page_size')]: value || 10 }, path(['history', 'location', 'search'], props)) })
  }

  const [next, setNext] = React.useState(false)
  const itemsCount = prop(getKey('count'), data)
  const ONE = 1
  const defaultPageRange = 10
  const currentPage = toNumber(prop(getKey('page'), params) || ONE)
  const pageRange = toNumber(prop(getKey('pageSize'), params) || defaultPageRange)
  const pageCount = Math.ceil(itemsCount / pageRange)

  const prevPage = () => {
    const prevPageNumber = currentPage - ONE
    setNext(false)
    if (currentPage <= ONE) {
      return null
    }
    return history.push({ search: appendParamsToUrl({ [getKey('page')]: prevPageNumber }, path(['location', 'search'], history)) })
  }

  const nextPage = () => {
    const nextPageNumber = currentPage + ONE
    if (pageCount < nextPageNumber) {
      setNext(true)
      return null
    }
    return history.push({ search: appendParamsToUrl({ [getKey('page')]: nextPageNumber }, path(['location', 'search'], history)) })
  }

  // For UI
  const startPage = (pageRange * (currentPage - ONE)) + ONE
  const startEnd = itemsCount < (pageRange * currentPage) ? itemsCount : pageRange * currentPage

  return (
    <Nav>
      <PageSize>
        <Select
          value={value}
          defaultValue={value}
          options={[
            { value: 10, label: '10' },
            { value: 25, label: '25' },
            { value: 50, label: '50' },
            { value: 100, label: '100' }
          ]}
          onChange={onChange}
        />
      </PageSize>
      <Navigation>{startPage} - {startEnd} из {itemsCount}</Navigation>
      <NavButtons>
        <NavButton
          disabled={currentPage <= 1}
          disableTouchRipple={true}
          onClick={() => { prevPage() }}
        >
          <ChevronLeft />
        </NavButton>

        <NavButton
          disabled={next}
          disableTouchRipple={true}
          onClick={() => { nextPage() }}
        >
          <ChevronRight />
        </NavButton>
      </NavButtons>
    </Nav>
  )
})

export default Pagination
