import styled from 'styled-components'
import { Row } from '../Grid'

export default styled('div')`
  background-color: #F2F4F8;
  border-radius: 5px;
  & ${Row} {
    color: #929eac;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
  }
`
