import React from 'react'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'
import mapIndexed from '../../../../../utils/mapIndexed'
import { Table, TableBody, TableCol, TableHeader, TableRow } from '../../../../../components/Table'
import styled from 'styled-components'
import hexToRgb from '../../../../../utils/hexToRgb'

const TableModal = styled(Table)`
    min-width: 100%;
`

const LinkButton = styled('a')`
  text-decoration: none;
  cursor: pointer;
  color: #6d73c0;
  &:hover {
    color: ${hexToRgb('#6d73c0', 0.5)}
  }
`

const CheckPhotoTable = ({ data }) => {
  const listData = path(['data', 'results'], data) || []
  return (
    <TableModal>
      <TableHeader>
        <TableRow>
          <TableCol rowSpan={1} align={'center'}>№</TableCol>
          <TableCol rowSpan={1} align={'center'}>Фото</TableCol>
          <TableCol rowSpan={1} align={'center'}>Station</TableCol>
          <TableCol rowSpan={1} align={'center'}>Кондиция</TableCol>
        </TableRow>
      </TableHeader>
      <TableBody>
        {mapIndexed((o, index) => {
          const station = prop('stationName', o)
          const ipAddress = prop('ipAddress', o)
          return (
            <TableRow key={index}>
              <TableCol align={'center'}>{index}</TableCol>
              <TableCol align={'left'}><LinkButton onClick={() => {}}>Просмотреть</LinkButton></TableCol>
              <TableCol align={'center'}> {station} </TableCol>
              <TableCol align={'center'}>{ipAddress}</TableCol>
            </TableRow>
          )
        }, listData)}
      </TableBody>
    </TableModal>
  )
}

CheckPhotoTable.propTypes = {
  data: PropTypes.object
}

export default CheckPhotoTable
