import { combineReducers } from 'redux'
import * as actionTypes from '../constants/actionTypes'
import * as STATES from '../constants/states'
import createThunkReducer from '../utils/createThunkReducer'
import { snackbarReducer } from 'react-redux-snackbar'

export default combineReducers({
  [STATES.AUTH]: createThunkReducer(actionTypes.AUTH),
  [STATES.CONTRACT_LIST]: createThunkReducer(actionTypes.CONTRACT_LIST),
  [STATES.PROVISION_LIST]: createThunkReducer(actionTypes.PROVISION_LIST),
  [STATES.ORGANISATION_LIST]: createThunkReducer(actionTypes.ORGANISATION_LIST),
  [STATES.ADMIN_LIST]: createThunkReducer(actionTypes.ADMIN_LIST),
  [STATES.ADMIN_LOGS]: createThunkReducer(actionTypes.ADMIN_LOGS),
  [STATES.GATEWAY_LIST]: createThunkReducer(actionTypes.GATEWAY_LIST),
  [STATES.CHECK_PROVISION_LIST]: createThunkReducer(actionTypes.CHECK_PROVISION_LIST),
  [STATES.CHECK_PHOTO]: createThunkReducer(actionTypes.CHECK_PHOTO),
  [STATES.DOUBLE_WEIGHT_LIST]: createThunkReducer(actionTypes.DOUBLE_WEIGHT_LIST),
  [STATES.PK_SEVENTEEN_CREATE]: createThunkReducer(actionTypes.PK_SEVENTEEN_CREATE),
  [STATES.PK_SEVENTEEN_LIST]: createThunkReducer(actionTypes.PK_SEVENTEEN_LIST),
  snackbar: snackbarReducer
})
