import React from 'react'
import { prop, path } from 'ramda'
import Box from 'components/Box'
import PropTypes from 'prop-types'
import Loader from 'components/Loader/Loader'
import PKSeventeenEnterForm from './PKSeventeenEnterForm'
import TableFilter from './TableFilter'
import PKSeventeenTable from './PKSeventeenTable'

const PKSeventeen = props => {
  const {
    pKSeventeenList, filterSelect, filterActions, enterSearch, history, gatewayList, onSubmitHandler,
    onSubmitCheckedFieldHandler, params, onSubmitInputGroupHandler, onSubmitFilterInputGroupHandler,
    resendVisible
  } = props
  const list = pKSeventeenList
  const listData = path(['data', 'results'], list)
  const listLoading = prop('loading', list)
  const [arrayField, changeArrayField] = React.useState([])
  const [allSelect, setAllSelect] = React.useState(false)

  return (
    <div>
      {filterSelect ? <PKSeventeenEnterForm {...filterActions} onSubmitHandler={onSubmitHandler} gatewayList={gatewayList} params={params} />
        : (
          <Box>
            <TableFilter
              {...filterActions}
              onSubmitCheckedFieldHandler={() => { onSubmitCheckedFieldHandler(arrayField) }}
              enterSearch={enterSearch}
              gatewayList={gatewayList}
              history={history}
              changeArrayField={changeArrayField}
              arrayField={arrayField}
              list={list}
              onSubmitInputGroupHandler={onSubmitInputGroupHandler}
              onSubmitFilterInputGroupHandler={onSubmitFilterInputGroupHandler}
              resendVisible={resendVisible}
              setAllSelect={setAllSelect}
              allSelect={allSelect}
            />
            {listLoading
              ? <Loader />
              : listData
                ? (
                  <PKSeventeenTable
                    listData={listData}
                    arrayField={arrayField}
                    changeArrayField={changeArrayField}
                    allSelect={allSelect}
                    setAllSelect={setAllSelect}
                  />
                )
                : <div>Проверьте соединение с интернетом</div>}
          </Box>
        )}
    </div>
  )
}

PKSeventeen.propTypes = {
  pKSeventeenList: PropTypes.object.isRequired,
  filterActions: PropTypes.object.isRequired,
  enterSearch: PropTypes.func.isRequired,
  onSubmitCheckedFieldHandler: PropTypes.func.isRequired,
  onSubmitInputGroupHandler: PropTypes.func.isRequired,
  onSubmitFilterInputGroupHandler: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  setSelectedField: PropTypes.func.isRequired,
  gatewayList: PropTypes.object.isRequired,
  history: PropTypes.object,
  params: PropTypes.object,
  filterSelect: PropTypes.bool,
  resendVisible: PropTypes.bool
}

export default PKSeventeen
