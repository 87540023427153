import axios, { getPayloadFromError, getPayloadFromSuccess } from '../../../utils/axios'
import * as API from '../../../constants/api'
import * as actionTypes from '../../../constants/actionTypes'
import * as ROUTES from '../../../constants/routes'

export const ResendAction = (url, newToken, params, id, matchUrl) => {
  const regionId = matchUrl === ROUTES.HUDUDLAR_REGION_PATH ? id : null
  const gatewayId = matchUrl === ROUTES.KORXONA_ORGANISATION_PATH ? id : null
  const stationId = matchUrl === ROUTES.KORXONA_STATION_PATH ? id : null
  const districtId = matchUrl === ROUTES.HUDUDLAR_DISTRICT_PATH ? id : null
  const territoryId = matchUrl === ROUTES.HUDUDLAR_TERRITORY_PATH ? id : null
  const contractorId = (matchUrl === ROUTES.HUDUDLAR_PROVISION_PATH) ? id : (matchUrl === ROUTES.KORXONA_TERRITORY_PATH) ? id : null
  const defaultParams = {
    contractor_id: contractorId,
    territory_id: territoryId,
    district_id: districtId,
    region_id: regionId,
    gateway_id: gatewayId,
    station_id: stationId
  }

  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState, newToken })
      .get(`${url}${API.PK_SEVENTEEN_CREATE}`, { params: defaultParams })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.PK_SEVENTEEN_CREATE,
      payload
    })
  }
}
