import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { prop } from 'ramda'
import MenuIcon from 'react-feather/dist/icons/menu'
import CloseIcon from 'react-feather/dist/icons/x'
import LogoutIcon from 'react-feather/dist/icons/log-out'
import { Link, NavLink } from 'react-router-dom'
import getMenus from 'constants/getMenus'
import hexToRgb from 'utils/hexToRgb'
import { storageData } from '../../utils/storage'
import { mediaQueries } from 'constants/mediaQueries'
import menuLogo from '../../assets/menuLogo.png'
import Perms from '../Perms/Perms'
import { appendParamsToUrl, parseParams } from 'utils/url'

const transition = 'all 200ms ease-out'

const Wrapper = styled('div')`
  align-self: center;
  position: relative;
  margin-left: auto;
`

const IconTrigger = styled('div')`
  cursor: pointer;
  padding: 10px;
  @media ${mediaQueries.tabletS} {
    margin-right: 0px;
  }
  margin-right: -8px;
`

const Hamburger = styled(MenuIcon)`
  color: white;
  display: block;
`

const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 0px 35px;
`

const CloseMenu = styled(CloseIcon)`
  margin-top: 13px;
  margin-right: -20px;
  cursor: pointer;
`

const MenuContent = styled('div')`
  background: #594CA6;
  box-shadow: ${({ isOpen }) => isOpen ? '-5px 0 15px rgba(0, 0, 0, 0.05)' : 'none'};
  display: flex;
  flex-direction: column;
  padding-top: 26px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  transition: ${transition};
  transform: translateX(${({ isOpen }) => isOpen ? '0' : '300px'});
  overflow-y: auto;
  max-width: 300px;
  width: 100%;
  z-index: 100;
`

const ElonMusk = styled('div')`
  background-color: ${hexToRgb('#000000', '0.18')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({ isOpen }) => isOpen ? '1' : '0'};
  transition: ${transition};
  visibility: ${({ isOpen }) => isOpen ? 'visible' : 'hidden'};
  z-index: 90;
`

const MenuList = styled('div')`
  flex-grow: 1;
  padding: 26px 0px;
`

const MenuItem = styled(NavLink)`
  background-color: #594CA6;
  color: white;
  display: block;
  margin-left: 10px;
  font-size: 15px;
  line-height: 20px;
  padding: 12px 35px;
  border-bottom: 3px solid transparent;
  text-decoration: none;
  transition: ${props => props.theme.transition};
  &:not(:last-child){
    margin-bottom: 5px;
  }
  &.active, &:hover {
    border-radius: 5px 0 0 5px;
    color: #594CA6;
    background-color: #ffffff;
  }
  &:hover {
    opacity: 0.7;
  }
`

const Logout = styled('div')`
  align-items: center;
  border-top: 1px solid #eeeff1;
  cursor: pointer;
  display: flex;
  color: white;
  padding: 26px 35px;
  & svg {
    margin-right: 5px;
  }
`

const Logo = styled(Link)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: white;
  font-weight: 500;
  font-size: 20px;
  text-decoration: none;
  margin-right: 30px;
  img:first-child{
    height: 40px;
    max-height: 40px;;
    margin-right: 8px;
  }
  h2{
    display: inline-block;
    font-size: 13px;
    line-height: 16px;
    max-width: 150px;
    font-weight: 500;
    text-transform: uppercase;
  }
`

const MobileMenu = ({ onLogout, location }) => {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false)
  const isSuperuser = storageData('isSuperuser').getValue()
  const menus = getMenus(isSuperuser)
  const params = parseParams(location.search)
  const date = prop('date', params)

  const onMenuOpen = () => setMenuIsOpen(true)
  const onMenuClose = () => setMenuIsOpen(false)

  React.useEffect(() => {
    if (menuIsOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style = null
    }
  }, [menuIsOpen])

  return (
    <Wrapper>
      <IconTrigger onClick={onMenuOpen}>
        <Hamburger />
      </IconTrigger>

      <ElonMusk isOpen={menuIsOpen} onClick={onMenuClose} />
      <MenuContent isOpen={menuIsOpen}>
        <Header>
          <Logo to={'/'}>
            <img src={menuLogo} alt={'Buxoro Agro Klaster'} />
            <h2>Агролайф Пахта Мониторинг</h2>
          </Logo>
          <div>
            <CloseMenu onClick={onMenuClose} color={'#fff'} />
          </div>
        </Header>
        <MenuList>
          {menus.map((item, index) => {
            const url = prop('url', item)
            const title = prop('title', item)
            const subPerms = prop('perms', item)
            const subI = (
              <MenuItem
                key={index}
                to={date ? {
                  pathname: url,
                  search: appendParamsToUrl({ date: date }, prop('search', location))
                } : {
                  pathname: url
                }}
                activeClassName={'active'}
              >
                {title}
              </MenuItem>
            )
            if (subPerms) {
              return (
                <Perms perms={subPerms} key={index}>
                  {subI}
                </Perms>
              )
            } else {
              return subI
            }
          })}
        </MenuList>
        <Logout onClick={onLogout}>
          <LogoutIcon size={18} /> Чиқиш
        </Logout>
      </MenuContent>
    </Wrapper>
  )
}

MobileMenu.propTypes = {
  onLogout: PropTypes.func,
  location: PropTypes.object.isRequired
}

export default MobileMenu
