import React from 'react'
import styled from 'styled-components'
import { prop } from 'ramda'
import Box from 'components/Box'
import Title from 'components/Title'
import { DisplayFlex } from 'components/Styled'
import TableFilter from './TableFilter'
import KorxonaTable from './KorxonaTable'
import PropTypes from 'prop-types'
import Loader from 'components/Loader/Loader'
import { mediaQueries } from 'constants/mediaQueries'
import { NoPrint, Print } from 'react-easy-print'
import { parseParams } from '../../../../utils/url'
import format from 'date-fns/format'

const Header = styled(DisplayFlex)`
  align-items: center;
  justify-content: space-between;
  @media ${mediaQueries.laptopS}{
    display: flex;
    margin-left: 0px;
    flex-flow: column nowrap;
    margin-bottom: 0px;
    align-items: flex-start;
    > div:not(:last-child){
      margin-bottom: 4px;
    }
  }
`

const StyledTitle = styled(Title)`
  margin-bottom: 5px;
  margin-right: 25px;
`

const Korxona = props => {
  const { list, filterActions, goCluster, gatewayList, history } = props

  const params = parseParams(props.location.search)
  const datePrint = prop('date', params) ? format(new Date(prop('date', params)), 'MM.dd.yyyy') : format(new Date(), 'MM.dd.yyyy')

  const listData = prop('results', list)
  const listLoading = prop('loading', list)

  return (
    <div>
      <NoPrint>
        <Header>
          <StyledTitle>Республика бўйича {window.renderDate} йил пахта ҳосилини қабул қилиш мониторинги</StyledTitle>
        </Header>
      </NoPrint>

      <Print
        printOnly={true}
      >
        <Header>
          <Title print={true}>Республика бўйича {datePrint} кунига пахта ҳосилини қабул қилиш мониторинги</Title>
        </Header>
      </Print>

      <Box>
        {listLoading
          ? <Loader />
          : listData
            ? <KorxonaTable listData={listData} goCluster={goCluster} />
            : <div>Проверьте соединение с интернетом</div>}
        <TableFilter
          {...filterActions}
          gatewayList={gatewayList}
          history={history}
          excelParam={{
            fileName: `Корхоналар бўйича_vloyatlar_${datePrint}`,
            tableId: 'withExcel',
            excelTableName: `Республика бўйича ${datePrint} кунига пахта ҳосилини қабул қилиш мониторинги`
          }}
        />
      </Box>
    </div>
  )
}

Korxona.propTypes = {
  list: PropTypes.object.isRequired,
  filterActions: PropTypes.object.isRequired,
  gatewayList: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  goCluster: PropTypes.func.isRequired
}

export default Korxona
